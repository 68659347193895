/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'chart-up': {
    width: 22,
    height: 22,
    viewBox: '0 0 22 22',
    data: '<path pid="0" d="M11 8l6 6.857H5z" _fill="#0DEF33" fill-rule="evenodd"/>'
  }
})
