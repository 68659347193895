/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'ServiceAlbums': {
    width: 25,
    height: 25,
    viewBox: '0 0 25 25',
    data: '<defs><path pid="0" d="M3.833 5.467h1.1v14.666h-1.1V5.467zm17.234 0h1.1v14.666h-1.1V5.467zM22.9 6.933H24v11.734h-1.1V6.933zm-20.9 0h1.1v11.734H2V6.933zM5.667 21.6V4h14.666v17.6H5.667zM9.15 15c-1.314 0-2.383.987-2.383 2.2s1.069 2.2 2.383 2.2c1.295 0 2.35-.96 2.38-2.149 0-.004.003-.009.003-.014v-6.324l6.234-.984v3.487a2.48 2.48 0 00-1.65-.616c-1.314 0-2.384.987-2.384 2.2s1.07 2.2 2.384 2.2c1.314 0 2.383-.987 2.383-2.2V7.3a.365.365 0 00-.424-.362l-6.967 1.1a.366.366 0 00-.309.362v7.216A2.48 2.48 0 009.15 15z" id="svgicon_ServiceAlbums_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon_ServiceAlbums_b" _fill="#fff"><use xlink:href="#svgicon_ServiceAlbums_a"/></mask><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_ServiceAlbums_a"/><g mask="url(#svgicon_ServiceAlbums_b)" _fill="#FFF"><path pid="1" d="M0 0h25v25H0z"/></g></g>'
  }
})
