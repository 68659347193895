/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'close': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" id="svgicon_close_a" d="M21 4.8L19.2 3 12 10.2 4.8 3 3 4.8l7.2 7.2L3 19.2 4.8 21l7.2-7.2 7.2 7.2 1.8-1.8-7.2-7.2z"/></defs><use xlink:href="#svgicon_close_a"/>'
  }
})
