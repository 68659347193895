/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'ServiceCamera': {
    width: 25,
    height: 25,
    viewBox: '0 0 25 25',
    data: '<defs><path pid="0" d="M13 9.5c2.426 0 4.4 1.974 4.4 4.4s-1.974 4.4-4.4 4.4a4.405 4.405 0 01-4.4-4.4c0-2.426 1.974-4.4 4.4-4.4zm9.24-1.833c.97 0 1.76.789 1.76 1.76v10.768c0 .977-.795 1.772-1.772 1.772H3.772A1.774 1.774 0 012 20.195V9.426c0-.97.79-1.76 1.76-1.76h.44V5.834h2.2v1.834h.485L8.352 4h9.296l1.467 3.667h3.125zm-16.573 0v-1.1h-.734v1.1h.734zM13 20.5c3.64 0 6.6-2.96 6.6-6.6 0-3.64-2.96-6.6-6.6-6.6-3.64 0-6.6 2.96-6.6 6.6 0 3.64 2.96 6.6 6.6 6.6zm8.067-8.433c.809 0 1.466-.658 1.466-1.467s-.657-1.467-1.466-1.467c-.81 0-1.467.658-1.467 1.467s.658 1.467 1.467 1.467z" id="svgicon_ServiceCamera_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon_ServiceCamera_b" _fill="#fff"><use xlink:href="#svgicon_ServiceCamera_a"/></mask><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_ServiceCamera_a"/><g mask="url(#svgicon_ServiceCamera_b)" _fill="#FFF"><path pid="1" d="M0 0h25v25H0z"/></g></g>'
  }
})
