/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'document-o': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" id="svgicon_document-o_a" d="M15.068 1H3.183v22h17.234V6.348L15.068 1zm.215 1.252l3.882 3.881h-3.882V2.252zM3.917 22.267V1.733H14.55v5.134h5.133v15.4H3.917zM6.85 7.233h3.3a.367.367 0 100-.733h-3.3a.367.367 0 100 .733zm6.233 7.334H6.85a.367.367 0 100 .733h6.233a.367.367 0 100-.733zM16.75 12h-2.567a.367.367 0 100 .733h2.567a.367.367 0 100-.733zm-9.9-1.833h1.833a.367.367 0 100-.734H6.85a.367.367 0 100 .734zm5.5 0h2.567a.367.367 0 100-.734H12.35a.367.367 0 100 .734zm-2.2-.367c0 .095.04.19.106.26.07.066.165.107.26.107.096 0 .191-.04.261-.107a.386.386 0 00.106-.26.384.384 0 00-.106-.26.384.384 0 00-.52 0 .384.384 0 00-.107.26zM8.683 12a.367.367 0 100 .733h3.667a.367.367 0 100-.733H8.683zm-1.833.733c.095 0 .19-.04.26-.106a.386.386 0 00.107-.26.384.384 0 00-.107-.26.384.384 0 00-.52 0 .377.377 0 00-.107.26c0 .095.04.19.107.26.07.066.165.106.26.106zm9.9 4.4h-2.567a.367.367 0 100 .734h2.567a.367.367 0 100-.734zm-4.4 0H8.683a.367.367 0 100 .734h3.667a.367.367 0 100-.734zm-5.76.107a.371.371 0 000 .52c.07.066.165.107.26.107.099 0 .19-.04.26-.107a.386.386 0 00.107-.26c0-.1-.04-.191-.107-.257a.38.38 0 00-.52-.003zm9.9-7.7a.384.384 0 00-.107.26c0 .095.04.19.107.26.07.066.165.107.26.107s.19-.04.26-.107a.37.37 0 000-.52.384.384 0 00-.52 0zm-1.313 5.654a.386.386 0 00.106-.26.384.384 0 00-.106-.261.384.384 0 00-.52 0 .384.384 0 00-.107.26.364.364 0 00.366.367c.1 0 .191-.04.261-.106zm1.313-.521a.384.384 0 00-.107.26c0 .096.04.191.107.26.07.067.161.107.26.107s.19-.04.26-.106a.386.386 0 00.107-.26.384.384 0 00-.107-.261.384.384 0 00-.52 0z"/></defs><use xlink:href="#svgicon_document-o_a"/>'
  }
})
