/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'wav': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" id="svgicon_wav_a" d="M10.944 9.36c0-.147.145-.293.29-.293.144.073.288.22.288.44l-.722 5.866c0 .147-.144.294-.289.294h-.072a.396.396 0 01-.361-.22L8.994 12.66l-1.083 2.787a.396.396 0 01-.361.22c-.144 0-.289-.147-.289-.294L6.54 9.507c-.072-.22.072-.44.289-.44.216-.074.433.073.433.293l.578 4.473.939-2.346c.072-.294.505-.294.65 0l.939 2.346.577-4.473zm2.89-.073c.144-.294.505-.294.65 0l2.166 5.866c.072.147 0 .367-.217.44h-.144a.396.396 0 01-.361-.22l-.434-1.246H12.75l-.433 1.246c-.073.22-.217.294-.434.22-.216-.073-.289-.22-.216-.44l2.166-5.866zm-.795 4.18h2.239l-1.156-3.007-1.083 3.007zm3.755-4.18l1.806 4.986 1.806-4.986c.072-.22.216-.294.433-.22.144.073.289.293.361.44l-2.167 5.866a.396.396 0 01-.36.22.396.396 0 01-.362-.22l-2.167-5.866c-.072-.147 0-.367.217-.44.145-.074.361 0 .433.22zm-4.044 10.78c.144 0 .289.146.289.366s-.145.367-.361.367H4.01c-.217 0-.361-.147-.361-.367V6.353l.072-.073c.072 0 .072-.073.072-.073L8.85 1.073C8.85 1 8.922 1 8.922 1H19.25c.217 0 .361.147.361.367v5.866c0 .22-.144.367-.361.367s-.361-.147-.361-.367v-5.5H9.5V6.5c0 .22-.144.367-.361.367H4.444v13.2h8.306zM8.778 2.247L4.95 6.133h3.828V2.247zM19.9 16.4c.072 0 .217 0 .289-.073a.56.56 0 01.144.293v4.767c0 .44-.433.733-1.083.733-.65 0-1.083-.293-1.083-.733s.433-.734 1.083-.734c.144 0 .217.074.361.074v-3.594l-2.889.587v4.547s-.072.073-.072.146c-.072.294-.433.514-1.011.514-.65 0-1.083-.294-1.083-.66 0-.367.433-.66 1.083-.66.144 0 .217.073.361.073V17.5c0-.147.144-.367.289-.367L19.9 16.4z"/></defs><use xlink:href="#svgicon_wav_a"/>'
  }
})
