import Vue from 'vue'
import { Component, Mixins } from 'vue-property-decorator'
import { Howl } from 'howler'
import { PlayerStore, EVENT_LOAD_TRACK, EVENT_AUDIO_TOGGLE } from '~/store/player'
import { ModalStore, IModalParams, ModalType } from '~/store/modal'
import { UserStore } from '@/store/user'
import { BeatStore } from '~/store/beat'
import { analyticsPlay } from '~/services/analytics'
import { BASE_URL } from '~/const/base-url'
import { Dropdowns } from '~/mixins/dropdowns'
import { BeatAlreadyInCart } from '@/mixins/beat-already-in-cart'
import { BeatImageOrProducerLogo } from '~/mixins/beat-image-or-producer-logo'

@Component
export class AbstractPlayer extends Mixins(Dropdowns, BeatAlreadyInCart, BeatImageOrProducerLogo) {
	// modals stuff
	ModalType = ModalType

	currentPosition = 0
	eventSent = false
	audioLoading = true
	lastHowlSeekPos = 0
	currentAudioSecondsPlayed = 0
	isDragging = false
	$howl: Howl

	@PlayerStore.State('isAudioPaused') isAudioPaused: boolean
	@PlayerStore.State('playerIsShowing') playerIsShowing: boolean
	@PlayerStore.Getter('beatPlaying') beatPlaying: IBeat
	@ModalStore.Mutation('SET_ACTIVE_MODAL') SET_ACTIVE_MODAL: (payload: IModalParams) => void
	@PlayerStore.Action('playNext') playNext: () => void
	@PlayerStore.Action('playPrevious') playPrevious: () => void
	@PlayerStore.Action('increasePopularityOfPlayedBeat') increasePopularityOfPlayedBeat: (beatId: IBeat['id']) => void
	@UserStore.Action('toggleLike') toggleLike: () => void
	@BeatStore.Action('demoDownloadBeat') demoDownloadBeat: () => void

	get beat() {
		// needed for mixins
		return this.beatPlaying
	}

	get trackTotalDuration() {
		return this.beatPlaying ? this.beatPlaying.length : 0
	}

	get playerBarStyling() {
		return [{ width: 100 / (this.trackTotalDuration / this.currentPosition) + '%' }]
	}

	step() {
		// current time of track
		const howlSeek = this.$howl.seek()
		const seek = typeof howlSeek === 'number' ? howlSeek : 0
		// if a second passed since last update, increase the total of seconds played of current track
		if (seek - this.lastHowlSeekPos > 1) this.currentAudioSecondsPlayed += 1
		// make api call once we pass the 30 seconds played && event wasn't yet called
		if (this.currentAudioSecondsPlayed > 20 && !this.eventSent) {
			// set event sent to true to prevent from calling it multiple times
			this.eventSent = true
			analyticsPlay(this.beat)
			this.increasePopularityOfPlayedBeat(this.beat.id)
		}
		// update data
		this.lastHowlSeekPos = Math.floor(seek)
		this.currentPosition = seek
		this.$store.commit('player/SET_CURRENT_POSITION', seek)
		// If the sound is still playing, continue stepping.
		// if (!this.isAudioPaused)
		requestAnimationFrame(this.step.bind(this))
	}

	TOGGLE_PLAY_STATUS() {
		this.$store.commit('player/TOGGLE_PLAY_STATUS')
	}

	startDrag() {
		this.isDragging = true
	}

	stopDrag(event: TouchEvent) {
		this.isDragging = false
		// on mouseup we set the position
		this.setNewCurrentPosition(event, false)
		if (this.isAudioPaused) this.TOGGLE_PLAY_STATUS()
	}

	doDrag(event: TouchEvent) {
		// resets the position constantly based on where the user mouse or touch is
		if (this.isDragging) this.setNewCurrentPosition(event, false)
	}

	setNewCurrentPosition(e: any, isTouch: boolean) {
		//
		if (isTouch && (e.targetTouches.length > 1 || e.changedTouches.length > 1 || e.touches.length > 1)) return
		let tag = e.target
		// if the click is not on 'slider', grab div with class 'slider'
		if (e.target.className === 'player__timeBar') tag = e.target.firstElementChild
		else if (e.target.className === 'player__currentPosition') tag = e.target.parentElement

		// find position of click
		const pos = tag.getBoundingClientRect()
		let seekPos
		// on touch screen
		if (isTouch) seekPos = (e.pageX - pos.left) / pos.width
		// on mouse click
		else seekPos = (e.clientX - pos.left) / pos.width

		// set new current time
		const newPosition = Math.floor(this.trackTotalDuration * seekPos)
		this.$howl.seek(newPosition)
	}

	resetPlayerData() {
		this.eventSent = false
		this.currentAudioSecondsPlayed = 0
		this.currentPosition = 0
		// if we were already playling audio unload howl
		if (this.$howl) this.$howl.unload()
	}

	created() {
		Vue.playerBus.$on(EVENT_LOAD_TRACK, ({ beatPlaying, autoplay }: ILoadTrackParams) => {
			this.audioLoading = true
			// reset event sent every time we load a new track
			this.resetPlayerData()
			const self = this
			// create new howl object
			Vue.prototype.$howl = new Howl({
				src: [`${BASE_URL}/s3/beat_play/${beatPlaying.id}/`],
				format: ['mp3'],
				html5: true,
				autoplay: autoplay,
				onplay() {
					self.audioLoading = false
					// @ts-ignore
					self.beatPlaying.length = this._duration
					// Start upating the progress of the track.
					requestAnimationFrame(self.step.bind(self))
				},
				onend() {
					self.playNext()
				}
			})
			// play the sound
			// this.$howl.play()
		})
		Vue.playerBus.$on(EVENT_AUDIO_TOGGLE, (isAudioPaused: boolean) => {
			if (isAudioPaused === true) this.$howl.pause()
			else this.$howl.play()
		})

		window.onkeydown = (e: any) => {
			if (e.keyCode === 32 && e.target === document.body) {
				// space bar
				e.preventDefault()
				this.TOGGLE_PLAY_STATUS()
			} else if (e.keyCode === 37 && e.target === document.body) {
				// left arrow
				this.$howl.seek(this.currentPosition - 10)
			} else if (e.keyCode === 39 && e.target === document.body) {
				// right arrow
				this.$howl.seek(this.currentPosition + 10)
			}
		}
	}

	destroyed() {
		this.TOGGLE_PLAY_STATUS()
		this.resetPlayerData()
	}
}
