/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'paper-plane': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M3.013 20.435a.39.39 0 01.552.552l-.898.899a.39.39 0 01-.553 0 .39.39 0 010-.553zm4.283.76a.393.393 0 01.39.391.394.394 0 01-.39.391.394.394 0 01-.39-.39.393.393 0 01.39-.391zm2.63-2.791a.39.39 0 01.553.552l-1.932 1.932a.39.39 0 01-.552 0 .39.39 0 010-.553zm6.666-.149a.39.39 0 01.553.553l-1.937 1.937a.39.39 0 01-.552 0 .39.39 0 010-.553zM7.87 15.577a.39.39 0 01.553.553l-3.647 3.646a.39.39 0 01-.552 0 .39.39 0 010-.552zM21.41 2.054a.39.39 0 01.59.337v15.351a.39.39 0 01-.505.374l-5.422-1.66-2.883 2.818a.39.39 0 01-.615-.091l-2.507-4.563-5.682-1.739a.39.39 0 01-.085-.71l6.972-4.122a.39.39 0 11.398.672l-6.21 3.672 4.758 1.456 9.567-9.926L14.65 6.96a.39.39 0 01-.398-.673zm-8.102 13.557v2.456l1.914-1.87-1.914-.586zm5.082-9.114l-7.575 7.86 1.712 3.115v-2.389a.39.39 0 01.07-.222l5.793-8.364zm2.829-2.712l-7.676 11.08 7.676 2.35V3.784zM12.798 7.21a.394.394 0 01.39.391.393.393 0 01-.39.39.392.392 0 01-.276-.666.394.394 0 01.276-.115z" id="svgicon_paper-plane_a"/></defs><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_paper-plane_a"/>'
  }
})
