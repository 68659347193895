import { namespace } from 'vuex-class'
import { VuexActionData } from '~/interfaces/interface-utils'
import set from 'lodash.set'
import { updateCssVariable } from '~/utils/updateCssVariable'
import { fetchMailingListPage } from '@elitepage/api/mailing-list-page'

export const MailingListPageStore = namespace('mailinglistpage')

interface MailingListPageState {
    mailingListPage: IMailingListPage
}

export interface IMailingListPagePatch {
    key: keyof IMailingListPage
    value: any
}

export default {
    namespaced: true,
    state: {
        mailingListPage: null
    } as MailingListPageState,
    mutations: {
        SET_MAILING_LIST_PAGE: (state: MailingListPageState, payload: IMailingListPage) => (state.mailingListPage = payload),
        UPDATE_MAILING_LIST_PAGE: (state: MailingListPageState, payload: IMailingListPagePatch) => set(state.mailingListPage, payload.key, payload.value)
    },
    actions: {
        async loadMailingListPage({ commit }: VuexActionData<MailingListPageState>, producerSlug: IProducer['slug']) {
            const mailingListPage = await fetchMailingListPage(producerSlug)
            // update css variables from js variables
            Object.keys(mailingListPage).forEach(it => {
                const key = it as keyof IMailingListPage
                updateCssVariable(key, mailingListPage[key])
            })
            commit('SET_MAILING_LIST_PAGE', mailingListPage)
        }
    }
}
