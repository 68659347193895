/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'facebook': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M17.446 1.005L14.594 1c-3.206 0-5.277 2.125-5.277 5.414v2.497H6.45a.449.449 0 00-.45.448v3.617c0 .248.201.449.449.449h2.868v9.126c0 .248.2.449.449.449h3.742a.449.449 0 00.449-.449v-9.126h3.354c.248 0 .448-.2.448-.449l.002-3.617a.45.45 0 00-.45-.448h-3.354V6.794c0-1.017.242-1.533 1.567-1.533h1.922a.449.449 0 00.448-.45V1.454c0-.247-.2-.448-.448-.448z" id="svgicon_facebook_a"/></defs><use _fill="#FFF" xlink:href="#svgicon_facebook_a"/>'
  }
})
