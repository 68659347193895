import Vue from 'vue'
import VeeValidate from 'vee-validate'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { email, regex, numeric, required, min, confirmed } from 'vee-validate/dist/rules'

// use these rules
extend('required', required)
extend('email', email)
extend('numeric', numeric)
extend('regex', regex)
extend('min', min)
extend('confirmed', confirmed)

// Register it globally
// main.js or any entry file.
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
