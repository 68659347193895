import { get } from '~/services/http-requests'

const _BS_PRODUCERS = 'producers'
// for browse page, to only show active producers, that have uploaded beats
const _BS_PRODUCERS_W_BEATS = 'producers_with_beats'

/*
 **** GET ****
 */
async function fetchSingleProducer(producerSlug: IProducer['slug']): Promise<IProducer> {
    const [first] = await get({ baseProperty: _BS_PRODUCERS, fullUrl: `${_BS_PRODUCERS}.json?slug=${producerSlug}` })
    return first
}

function fetchProducersWithSearch(value: string): Promise<IBeat> {
    return get({
        baseProperty: _BS_PRODUCERS,
        fullUrl: `${_BS_PRODUCERS}.json?display_name__icontains=${value}&fields=id,display_name,slug,image_logo_url`
    })
}

async function fetchTopProducers(): Promise<IProducer[]> {
    const data = await get({ baseProperty: _BS_PRODUCERS_W_BEATS, fullUrl: `${_BS_PRODUCERS_W_BEATS}.json?fields=id,display_name,slug,image_logo_url` })
    data.sort(() => 0.5 - Math.random())
    return data.slice(0, 20)
}

async function fetchProducerLicenseOptions(producerSlug: IProducer['slug']): Promise<ILicenseOptionBeat[]> {
    const [first] = await get({ baseProperty: _BS_PRODUCERS, fullUrl: `${_BS_PRODUCERS}.json?slug=${producerSlug}&fields=license_options` })
    return first.license_options
}

export { fetchSingleProducer, fetchProducersWithSearch, fetchTopProducers, fetchProducerLicenseOptions }
