/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'ServiceAlbum': {
    width: 25,
    height: 25,
    viewBox: '0 0 25 25',
    data: '<defs><path pid="0" d="M2 3h3.667v19.067H2V3zm4.4 0H24v19.067H6.4V3zm14.3 4.033v-.366a.366.366 0 00-.424-.362l-6.967 1.1a.366.366 0 00-.309.362v7.216a2.48 2.48 0 00-1.65-.616c-1.314 0-2.383.986-2.383 2.2 0 1.213 1.069 2.2 2.383 2.2 1.295 0 2.35-.96 2.38-2.149 0-.005.003-.01.003-.014V10.28l6.234-.984v3.487a2.48 2.48 0 00-1.65-.616c-1.314 0-2.384.986-2.384 2.2 0 1.213 1.07 2.2 2.384 2.2 1.314 0 2.383-.987 2.383-2.2V7.033z" id="svgicon_ServiceAlbum_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon_ServiceAlbum_b" _fill="#fff"><use xlink:href="#svgicon_ServiceAlbum_a"/></mask><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_ServiceAlbum_a"/><g mask="url(#svgicon_ServiceAlbum_b)" _fill="#FFF"><path pid="1" d="M0 0h25v25H0z"/></g></g>'
  }
})
