/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'microphone-o': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M5.53 17.458l1.183 1.183c.074.074.148.074.295.074.148 0 .222 0 .296-.074 2.438-2.439 8.35-6.946 9.9-8.054 1.035-.148 2.07-.591 2.882-1.404a4.873 4.873 0 001.404-3.399 4.873 4.873 0 00-1.404-3.399c-1.847-1.847-4.95-1.847-6.797 0-.887.887-1.404 1.995-1.404 3.251-1.108 1.478-5.542 6.946-8.054 9.458a.357.357 0 000 .517l1.182 1.182-1.182 1.183c-1.108 1.108-1.108 3.029 0 4.211.517.591 1.478.813 2.439.813.96 0 1.92-.296 2.512-.813l6.65-6.65c.665-.739 2.216-.886 3.177 0 .739.813.96 2.29 0 3.178l-2.956 2.955a.357.357 0 000 .517c.148.148.37.148.517 0l2.956-2.955c1.256-1.256 1.034-3.104 0-4.212-1.256-1.182-3.251-.96-4.212 0l-6.65 6.65c-.812.813-3.103.813-3.916 0-.812-.887-.812-2.364 0-3.177l1.183-1.035zM16.615 1.794c1.108 0 2.069.444 2.881 1.182a4.08 4.08 0 011.183 2.882c0 .96-.37 1.847-.961 2.586l-5.69-5.69c.74-.59 1.626-.96 2.587-.96zM13.51 3.272l5.69 5.69c-.739.59-1.626.96-2.586.96-1.109 0-2.07-.443-2.882-1.182a4.08 4.08 0 01-1.182-2.882c0-.96.296-1.847.96-2.586zm-1.625 3.547c.222.886.665 1.773 1.33 2.438.739.739 1.7 1.182 2.734 1.33-2.143 1.625-6.65 5.098-8.94 7.315l-1.257-1.256-1.182-1.183c2.217-2.29 5.763-6.65 7.315-8.644zm-1.182 5.32c.074 0 .221 0 .295-.074l.74-.74a.357.357 0 000-.516.357.357 0 00-.518 0l-.739.738a.357.357 0 000 .518c0 .074.074.074.222.074z" id="svgicon_microphone-o_a"/></defs><use xlink:href="#svgicon_microphone-o_a"/>'
  }
})
