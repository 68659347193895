/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'deal': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M1 14.453L14.453 1H23v8.151L9.547 23 1 14.453zm17.252-6.33c1.345 0 2.374-1.03 2.374-2.375s-1.029-2.374-2.374-2.374c-1.346 0-2.374 1.029-2.374 2.374 0 1.346 1.028 2.374 2.374 2.374z" id="svgicon_deal_a"/></defs><use xlink:href="#svgicon_deal_a"/>'
  }
})
