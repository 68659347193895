/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'pause-full': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M4.5 1H9a.5.5 0 01.5.5v21a.5.5 0 01-.5.5H4.5a.5.5 0 01-.5-.5v-21a.5.5 0 01.5-.5zm11 0H20a.5.5 0 01.5.5v21a.5.5 0 01-.5.5h-4.5a.5.5 0 01-.5-.5v-21a.5.5 0 01.5-.5z" _fill="#FFF" fill-rule="nonzero"/>'
  }
})
