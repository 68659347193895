/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'soundcloud': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M19.726 11.217A3.278 3.278 0 0123 14.492a3.278 3.278 0 01-3.275 3.275c-.076 0-7.628 0-7.695-.008a.52.52 0 01.068-1.036l7.627.004a2.238 2.238 0 002.236-2.235 2.238 2.238 0 00-3.1-2.063.52.52 0 01-.719-.433 4.317 4.317 0 00-4.324-3.957c-.533 0-1.077.102-1.574.294a.52.52 0 11-.375-.969A5.452 5.452 0 0113.819 7c1.354 0 2.647.505 3.642 1.422a5.372 5.372 0 011.624 2.857c.21-.041.424-.062.64-.062zm-18.206.83a.52.52 0 01.52.52v4.16a.52.52 0 01-1.04 0v-4.16a.52.52 0 01.52-.52zm2.661-.823a.52.52 0 01.52.52v4.924a.52.52 0 01-1.04 0v-4.924a.52.52 0 01.52-.52zM6.907 9.02a.52.52 0 01.52.52v7.69a.52.52 0 01-1.04 0V9.54a.52.52 0 01.52-.52zm2.792.088a.52.52 0 01.52.52v7.61a.52.52 0 01-1.04 0v-7.61a.52.52 0 01.52-.52z" id="svgicon_soundcloud_a"/></defs><use xlink:href="#svgicon_soundcloud_a"/>'
  }
})
