import Navbar from '@elitepage/layout/Navbar.vue'
import SidebarEdit from '@elitepage/layout/SidebarEdit.vue'
import SidebarMailingListPage from '@elitepage/layout/SidebarMailingListPage.vue'
import SidebarProductPage from '@elitepage/layout/SidebarProductPage.vue'
import Footer from '@elitepage/layout/Footer.vue'
import { castParam, LocaleComponent } from '~/services/router-helper'

export default [
    // {
    //     path: '*',
    //     redirect: '/'
    // },
    {
        path: '/',
        name: 'NotFound',
        component: () => import(/* webpackChunkName: "NotFound" */ '@elitepage/views/NotFound.vue')
    },
    {
        path: '/:producerSlug',
        components: {
            default: () => import('@elitepage/views/ProducerContainer.vue')
        },
        props: { default: true },
        children: [
            // Auth
            {
                path: 'login',
                name: 'Login',
                components: {
                    default: () => import(/* webpackChunkName: "Login" */ '~/views/auth/Login.vue')
                },
                props: { default: true }
            },
            {
                path: 'signup',
                name: 'Signup',
                components: {
                    default: () => import(/* webpackChunkName: "Signup" */ '~/views/auth/Signup.vue')
                },
                props: { default: true }
            },
            {
                path: 'forgot-password',
                name: 'ForgotPassword',
                components: {
                    default: () => import(/* webpackChunkName: "ForgotPassword" */ '~/views/auth/ForgotPassword.vue')
                },
                props: { default: true }
            },
            {
                path: 'cart',
                name: 'Cart',
                components: {
                    navbar: () => import('~/components/CartNavbar.vue'),
                    default: () => import(/* webpackChunkName: "Cart" */ '@elitepage/views/Cart.vue')
                },
                props: { default: true }
            },
            {
                path: 'cart/finish-paypal/:orderId',
                name: 'EliteFinishPaypal',
                components: { default: () => import(/* webpackChunkName: "Cart" */ '@elitepage/views/EliteFinishPaypal.vue') },
                props: { default: castParam }
            },
            {
                path: 'order/:email/:orderId',
                name: 'OrderReview',
                components: {
                    navbar: Navbar,
                    footer: Footer,
                    sidebar: SidebarEdit,
                    default: () => import(/* webpackChunkName: "OrderReview" */ '~/views/OrderReview.vue')
                },
                props: { default: castParam }
            },
            {
                path: 'purchases',
                name: 'Purchases',
                components: {
                    navbar: Navbar,
                    footer: Footer,
                    default: () => import(/* webpackChunkName: "Purchases" */ '~/views/Purchases.vue')
                },
                meta: { requiresAuth: true },
                props: { default: true }
            },
            // Elite page stuff
            {
                path: '/',
                name: 'Home',
                components: {
                    navbar: Navbar,
                    footer: Footer,
                    sidebar: SidebarEdit,
                    default: () => import('@elitepage/views/Home.vue')
                },
                props: { default: true }
            },
            // TODO page used for when clients clicks on "producer link" inside Beat page in "/shared"
            {
                path: '/',
                name: 'Producer',
                redirect: () => ({ name: 'Home' })
            },
            {
                path: '/',
                name: 'ArtistType',
                redirect: (to: any) => ({ name: 'Beats', query: { artistType: to.params.slug } })
            },
            {
                path: 'beats',
                components: {
                    navbar: Navbar,
                    footer: Footer,
                    sidebar: SidebarEdit,
                    default: LocaleComponent
                },
                children: [
                    {
                        path: '/',
                        name: 'Beats',
                        components: { default: () => import('@elitepage/views/Beats.vue') },
                        props: { default: true }
                    },
                    {
                        path: ':id',
                        name: 'Beat',
                        components: { default: () => import('@elitepage/views/PBeat.vue') },
                        props: { default: castParam }
                    }
                ]
            },
            {
                path: 'vault',
                name: 'BeatsPrivateVault',
                components: {
                    navbar: Navbar,
                    footer: Footer,
                    sidebar: SidebarEdit,
                    default: () => import('@elitepage/views/BeatsPrivateVault.vue')
                },
                props: { default: true }
            },
            {
                path: 'about',
                name: 'About',
                components: {
                    navbar: Navbar,
                    footer: Footer,
                    sidebar: SidebarEdit,
                    default: () => import('@elitepage/views/About.vue')
                },
                props: { default: true }
            },
            {
                path: 'sound-kits',
                components: {
                    navbar: Navbar,
                    footer: Footer,
                    sidebar: SidebarEdit,
                    default: LocaleComponent
                },
                children: [
                    {
                        path: '',
                        name: 'SoundKits',
                        components: { default: () => import('@elitepage/views/SoundKits.vue') },
                        props: { default: true }
                    },
                    {
                        path: ':id',
                        name: 'SoundKit',
                        components: { default: () => import('@elitepage/views/SoundKit.vue') },
                        props: { default: castParam }
                    }
                ]
            },
            {
                path: 'services',
                components: {
                    navbar: Navbar,
                    footer: Footer,
                    sidebar: SidebarEdit,
                    default: LocaleComponent
                },
                children: [
                    {
                        path: '/',
                        name: 'Services',
                        components: { default: () => import('@elitepage/views/Services.vue') },
                        props: { default: true }
                    },
                    {
                        path: ':id',
                        name: 'Service',
                        components: { default: () => import('@elitepage/views/Service.vue') },
                        props: { default: castParam }
                    }
                ]
            },
            {
                path: 'mailing-list',
                name: 'MailingList',
                components: {
                    sidebar: SidebarMailingListPage,
                    default: () => import('@elitepage/views/MailingList.vue')
                },
                props: { default: true }
            },
            {
                path: 'mailing-list-succesful-signup',
                name: 'MailingListThankYou',
                components: {
                    sidebar: SidebarMailingListPage,
                    default: () => import('@elitepage/views/MailingListThankYou.vue')
                },
                props: { default: true }
            },
            {
                path: 'product',
                children: [
                    {
                        path: '/',
                        name: 'ProductPage',
                        components: { default: () => import('@elitepage/views/Product.vue') },
                        props: { default: true }
                    },
                    {
                        path: 'checkout',
                        name: 'ProductCheckout',
                        components: { default: () => import('@elitepage/views/ProductCheckout.vue') },
                        props: { default: castParam }
                    },
                    {
                        path: 'checkout/finish-paypal/:orderId',
                        name: 'ProductCheckoutFinishPaypal',
                        components: { default: () => import('@elitepage/views/ProductCheckoutFinishPaypal.vue') },
                        props: { default: castParam }
                    },
                    {
                        path: 'checkout-successful/:email/:orderId',
                        name: 'ProductCheckoutSuccessful',
                        components: { default: () => import('@elitepage/views/ProductCheckoutSuccessful.vue') },
                        props: { default: castParam }
                    }
                ],
                components: {
                    sidebar: SidebarProductPage,
                    default: LocaleComponent
                }
            },
            {
                path: '/privacy-policy',
                name: 'PrivacyPolicy',
                components: {
                    default: () => import(/* webpackChunkName: "PrivacyPolicy" */ '~/views/PrivacyPolicy.vue'),
                    navbar: Navbar,
                    footer: Footer
                }
            },
            {
                path: '/terms-of-service',
                name: 'TermsOfService',
                components: {
                    default: () => import(/* webpackChunkName: "TermsOfService" */ '~/views/TermsOfService.vue'),
                    navbar: Navbar,
                    footer: Footer
                }
            }
        ]
    }
]
