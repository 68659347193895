/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'ServiceMixing2': {
    width: 25,
    height: 25,
    viewBox: '0 0 25 25',
    data: '<defs><path pid="0" d="M23.633 2H2.367A.367.367 0 002 2.367v21.266c0 .203.164.367.367.367h21.266a.367.367 0 00.367-.367V2.367A.367.367 0 0023.633 2zM6.4 21.433a2.57 2.57 0 01-2.567-2.566c0-1.29.958-2.359 2.2-2.538V4.933a.367.367 0 11.734 0V16.33a2.568 2.568 0 012.2 2.538A2.57 2.57 0 016.4 21.433zm6.967-6.262v5.896a.367.367 0 11-.734 0V15.17a2.568 2.568 0 01-2.2-2.538c0-1.29.958-2.358 2.2-2.537V4.933a.367.367 0 11.734 0v5.163a2.568 2.568 0 012.2 2.537c0 1.29-.958 2.359-2.2 2.538zm6.6-5.5v11.396a.367.367 0 11-.734 0V9.67a2.568 2.568 0 01-2.2-2.538A2.57 2.57 0 0119.6 4.567a2.57 2.57 0 012.567 2.566c0 1.29-.958 2.359-2.2 2.538z" id="svgicon_ServiceMixing2_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon_ServiceMixing2_b" _fill="#fff"><use xlink:href="#svgicon_ServiceMixing2_a"/></mask><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_ServiceMixing2_a"/><g mask="url(#svgicon_ServiceMixing2_b)" _fill="#FFF"><path pid="1" d="M0 0h25v25H0z"/></g></g>'
  }
})
