/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'share': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M16.145 16.636a3.78 3.78 0 00-.51.637l-6.443-3.911a3.703 3.703 0 000-2.755l6.445-3.881a3.725 3.725 0 003.144 1.732 3.733 3.733 0 003.73-3.729A3.733 3.733 0 0018.78 1a3.733 3.733 0 00-3.729 3.73c0 .468.09.914.25 1.327L8.846 9.944A3.727 3.727 0 005.73 8.255 3.733 3.733 0 002 11.985a3.733 3.733 0 003.73 3.729c1.302 0 2.45-.673 3.117-1.69l6.453 3.917A3.724 3.724 0 0018.78 23c.955 0 1.91-.364 2.637-1.09a3.705 3.705 0 001.092-2.637c0-.996-.388-1.933-1.092-2.637a3.732 3.732 0 00-5.273 0zm2.636-14.89a2.987 2.987 0 012.984 2.983 2.987 2.987 0 01-2.984 2.983 2.987 2.987 0 01-2.983-2.983 2.987 2.987 0 012.983-2.983zM5.73 14.968a2.987 2.987 0 01-2.984-2.983A2.987 2.987 0 015.73 9a2.987 2.987 0 012.983 2.984 2.987 2.987 0 01-2.983 2.983zm15.16 6.414a2.986 2.986 0 01-4.218 0 2.964 2.964 0 01-.874-2.11c0-.796.31-1.545.874-2.109a2.973 2.973 0 012.11-.872c.764 0 1.527.29 2.109.872.563.564.874 1.313.874 2.11 0 .796-.31 1.546-.874 2.11z" id="svgicon_share_a"/></defs><use xlink:href="#svgicon_share_a"/>'
  }
})
