import Vue from 'vue'
Vue.filter('twoDecimals', (value: number) => {
    if (isNaN(value)) return ''
    return value.toFixed(2)
})
// creates the time
Vue.filter('time', (value: any) => {
    if (isNaN(value)) return '00:00'
    const length = Math.floor(parseInt(value, 10))
    let minute: string | number = Math.floor(value / 60)
    if (minute < 10) minute = '0' + minute
    let second: string | number = length % 60
    if (second < 10) second = '0' + second
    return minute + ':' + second
})

// When the bound element is inserted into the DOM => Focus the element
Vue.directive('focus', { inserted: (el: any) => el.focus() })

// Toggle dropdowns when click outside element
Vue.directive('click-outside', {
    // @ts-ignore
    bind: (el, binding, vNode) => {
        // Provided expression must evaluate to a function.
        if (typeof binding.value !== 'function') {
            // @ts-ignore
            const compName = vNode.context.name
            let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`
            if (compName) {
                warn += `Found in component '${compName}'`
            }
            console.warn(warn)
        }
        // Define Handler and cache it on the element
        const bubble = binding.modifiers.bubble
        // @ts-ignore
        const handler = e => {
            if (bubble || (!el.contains(e.target) && el !== e.target)) binding.value(e)
        }
        // @ts-ignore
        el.__vueClickOutside__ = handler

        // add Event Listeners
        document.addEventListener('click', handler)
        document.addEventListener('touchstart', handler)
    },
    unbind: (el: any) => {
        // Remove Event Listeners
        // @ts-ignore
        document.removeEventListener('click', el.__vueClickOutside__)
        // @ts-ignore
        document.removeEventListener('touchstart', el.__vueClickOutside__)
        // @ts-ignore
        el.__vueClickOutside__ = null
    }
})
