/* ============
 * vuex-persistedstate
 * ============
 *
 * Persist Vuex state with localStorage. https://github.com/robinvdvleuten/vuex-persistedstate
 *
 */

// @ts-ignore
import * as Cookies from 'js-cookie'
import VuexPersistedState from 'vuex-persistedstate'
import { ENV_IS_PRODUCTION } from '~/const/environment'

// @ts-ignore
export const vuexToken = new VuexPersistedState({
    key: 'token_id2',
    paths: ['auth.token_id'],
    storage: {
        getItem: key => Cookies.get(key),
        setItem: (key, value) =>
            Cookies.set(key, value, {
                domain: ENV_IS_PRODUCTION ? '.thecharts.com' : window.location.hostname,
                expires: 375
            }),
        removeItem: key => Cookies.remove(key)
    }
})

// @ts-ignore
export const vuexCart = new VuexPersistedState({
    key: 'cart',
    paths: ['cart.cart']
})

// @ts-ignore
export const vuexUser = new VuexPersistedState({
    key: 'user',
    paths: ['user.authenticatedUser', 'user.likedBeats', 'user.boughtBeats', 'user.followedProducers', 'user.followedPlaylists']
})
