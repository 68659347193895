/* ============
 * vue-tooltip
 * ============
 *
 * Adds tooltip on elements. https://hekigan.github.io/vue-directive-tooltip/
 *
 */

import Vue from 'vue'
// @ts-ignore
import VTooltip from 'v-tooltip'

Vue.use(VTooltip, {
    defaultClass: 'tooltip',
    defaultOffset: 5
})
