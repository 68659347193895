







import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component
export default class TheBackToTop extends Vue {
    visible = false
    visibleoffset = 600

    backToTop() {
        // @ts-ignore
        window.smoothscroll()
    }
    handleScroll() {
        this.visible = window.pageYOffset > this.visibleoffset
    }

    mounted() {
        // @ts-ignore
        window.smoothscroll = () => {
            // let currentScroll = document.documentElement.scrollTop || document.body.scrollTop
            const currentScroll = window.scrollY
            if (currentScroll > 0) {
                // @ts-ignore
                window.requestAnimationFrame(window.smoothscroll)
                window.scrollTo(0, Math.floor(currentScroll - currentScroll / 5))
            }
        }
        window.addEventListener('scroll', this.handleScroll)
    }

    destroyed() {
        window.removeEventListener('scroll', this.handleScroll)
    }
}
