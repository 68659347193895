/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'prev': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M22 23h-5V1h5v22zm-5-11.69v1.31L2 23V1l15 10.31z" id="svgicon_prev_a"/></defs><use transform="rotate(180 12 12)" xlink:href="#svgicon_prev_a"/>'
  }
})
