
































































































































































import { Component, Vue } from 'vue-property-decorator'

import PColorPicker from '@elitepage/components/PColorPicker.vue'
// @ts-ignore
import draggable from 'vuedraggable'

import { ElitepageStore, IElitepagePatch } from '@elitepage/store/elitepage'
import { ModalStore, IModalParams, ModalType } from '~/store/modal'
import { UploadStore, UploadType } from '~/store/upload'
import { youTubeGetVideoID } from '~/utils/youtubeStrings'
import { updateElitePage } from '@elitepage/api/elitepage'

const sections = [
    'section_order',
    'colors',
    'logo',
    'navbar',
    'header',
    'licensing_information',
    'mailing_list',
    'about',
    'sound_kits',
    'services',
    'youtube',
    'footer'
]
type SectionKey = typeof sections[number]

enum FileToRemoveOrAdd {
    Logo,
    HeaderBackground,
    SoundKitsBackground,
    ServicesBackground
}

@Component({ components: { PColorPicker, draggable } })
export default class SidebarEdit extends Vue {
    FileToRemoveOrAdd = FileToRemoveOrAdd
    openSection: SectionKey = 'sections_order'
    isSidebarOpen = false

    @ElitepageStore.State('elitepage') elitepage: IElitepage
    @ElitepageStore.State('producer') producer: IProducer

    @ElitepageStore.Mutation('UPDATE_ELITEPAGE') UPDATE_ELITEPAGE: (payload: IElitepagePatch) => void
    @ModalStore.Mutation('SET_ACTIVE_MODAL') SET_ACTIVE_MODAL: (modalParams: IModalParams) => void

    @UploadStore.Action('singleFileUpload') singleFileUpload: (IClientUploadParams: IClientUploadParams) => Promise<IUploadReturn>

    get youtubeVideoLink() {
        return this.elitepage.youtube_video_id ? 'https://www.youtube.com/watch?v=' + this.elitepage.youtube_video_id : null
    }

    set youtubeVideoLink(newValue: string) {
        this.UPDATE_ELITEPAGE({ key: 'youtube_video_id', value: youTubeGetVideoID(newValue) })
    }

    get bodySections() {
        return this.elitepage.body_sections
    }

    set bodySections(newValue: BodySection[]) {
        this.UPDATE_ELITEPAGE({ key: 'body_sections', value: newValue })
    }

    openCloseSection(section: SectionKey) {
        this.openSection = section === this.openSection ? null : section
    }

    toggleShowHideAbout() {
        this.UPDATE_ELITEPAGE({ key: 'about_visible', value: !this.elitepage.about_visible })
    }

    toggleLogoType() {
        const newValue = !this.elitepage.navbar_is_text
        this.UPDATE_ELITEPAGE({ key: 'navbar_is_text', value: newValue })
    }

    toggleShowAnalytics() {
        const newValue = !this.elitepage.show_analytics
        this.UPDATE_ELITEPAGE({ key: 'show_analytics', value: newValue })
    }

    async removeFile(property: keyof IElitepage) {
        try {
            this.UPDATE_ELITEPAGE({ key: property, value: '' })
        } catch (error) {
            this.$store.dispatch('modal/showNotification', { name: 'error', title: 'Error!', content: `There was an error removing the image` })
        }
    }

    async uploadFile(fileList: FileList, fileToAdd: FileToRemoveOrAdd) {
        try {
            const uploadType = UploadType.Public

            const { url } = await this.singleFileUpload({ fileList, uploadType, maxPxSize: 2000 })
            switch (fileToAdd) {
                case FileToRemoveOrAdd.Logo:
                    this.UPDATE_ELITEPAGE({ key: 'navbar_brand_image_url', value: url })
                    break
                case FileToRemoveOrAdd.HeaderBackground:
                    this.UPDATE_ELITEPAGE({ key: 'header_image_url', value: url })
                    break
                case FileToRemoveOrAdd.SoundKitsBackground:
                    this.UPDATE_ELITEPAGE({ key: 'soundkit_image_url', value: url })
                    break
                case FileToRemoveOrAdd.ServicesBackground:
                    this.UPDATE_ELITEPAGE({ key: 'service_image_url', value: url })
                    break
            }
        } catch (err) {
            this.SET_ACTIVE_MODAL({ modal: ModalType.Error })
        }
    }

    async saveChanges() {
        try {
            await updateElitePage(this.producer.id, this.elitepage)
            this.$store.dispatch('modal/showNotification', { name: 'success', title: 'Success!', content: `Elite page settings saved` })
        } catch (error) {
            this.$store.dispatch('modal/showNotification', { name: 'error', title: 'Error!', content: `There was an error saving your settings` })
        }
    }
}
