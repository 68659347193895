export enum ParentStateType {
    Purchases,
    ArtistType,
    Producer,
    Playlist,
    Genre,
    Instrument,
    Trending,
    Favourites,
    RelatedBeats
}

export enum ColorPickerParentStateType {
    Elitepage,
    MailingListPage,
    ProductPage
}
