/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'speaker': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M12.95 18.831a.415.415 0 01-.238-.074l-5.8-4.044H5.166A1.169 1.169 0 014 13.545v-3.26c0-.643.524-1.166 1.167-1.166h1.744l5.801-4.044a.413.413 0 01.651.34v13.002a.414.414 0 01-.414.414zM5.166 9.947a.34.34 0 00-.338.339v3.259a.34.34 0 00.338.339h1.875c.084 0 .167.026.236.074l5.256 3.665V6.208L7.278 9.873a.412.412 0 01-.236.074H5.167zM15.84 16.03a.414.414 0 01-.259-.738 4.204 4.204 0 001.59-3.3 4.2 4.2 0 00-1.607-3.314.415.415 0 01.514-.65A5.025 5.025 0 0118 11.99a5.028 5.028 0 01-1.903 3.948.412.412 0 01-.258.09zm-1.476-1.587a.414.414 0 01-.237-.754 2.07 2.07 0 00.889-1.698c0-.669-.328-1.3-.876-1.687a.414.414 0 01.479-.677 2.901 2.901 0 011.226 2.364c0 .948-.465 1.837-1.245 2.379a.416.416 0 01-.236.073zm2.83 3.048a.414.414 0 01-.265-.733 6.17 6.17 0 002.244-4.767 6.164 6.164 0 00-2.28-4.796.414.414 0 11.523-.643A6.991 6.991 0 0120 11.992a6.997 6.997 0 01-2.543 5.404.413.413 0 01-.264.095z" id="svgicon_speaker_a"/></defs><use xlink:href="#svgicon_speaker_a"/>'
  }
})
