import { getTextColorFromBackground } from '~/utils/getTextColorFromBackground'

function getCssVariableFromElitepageKey(elitepageKey: keyof IElitepage | keyof IAmplifyPlayer | keyof IMailingListPage | keyof IProductPage) {
    switch (elitepageKey) {
        case 'color_body_background':
            return '--body-bg-color'
        case 'color_body_text':
            return '--body-text-color'
        case 'color_primary':
            return '--primary-color'
        case 'color_primary_button_bg':
            return '--primary-button-bg-color'
        case 'color_primary_button_text':
            return '--primary-button-text-color'
        case 'color_secondary_button':
            return '--secondary-button-main-color'
        case 'color_input_background':
            return '--input-bg-color'
        case 'color_input_border':
            return '--input-border-color'
        case 'color_input_text':
            return '--input-text-color'
        case 'footer_bg_color':
            return '--footer-bg-color'
        case 'footer_items_color':
            return '--footer-text-color'
        default:
            return ''
    }
}

export function updateCssVariable(elitepageKey: keyof IElitepage | keyof IAmplifyPlayer | keyof IMailingListPage | keyof IProductPage, newValue: any) {
    const cssVariable = getCssVariableFromElitepageKey(elitepageKey)
    if (cssVariable) document.documentElement.style.setProperty(cssVariable, newValue)
    if (elitepageKey === 'color_secondary_button') {
        const textColor = getTextColorFromBackground(newValue)
        document.documentElement.style.setProperty('--secondary-button-text-color-hover', textColor)
    }
    if (elitepageKey === 'color_primary') {
        const textColor = getTextColorFromBackground(newValue)
        document.documentElement.style.setProperty('--text-color-on-primary', textColor)
    }
}
