/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'credit-card': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M20.8 5H3.2C1.953 5 1 5.953 1 7.2v10.267c0 1.246.953 2.2 2.2 2.2h17.6c1.247 0 2.2-.954 2.2-2.2V7.2c0-1.247-.953-2.2-2.2-2.2zM7.967 11.6h1.466c.22 0 .367.147.367.367s-.147.366-.367.366H7.967c-.22 0-.367-.146-.367-.366s.147-.367.367-.367zm-3.667 0h1.467c.22 0 .366.147.366.367s-.146.366-.366.366H4.3c-.22 0-.367-.146-.367-.366s.147-.367.367-.367zm0 2.2h3.667c.22 0 .366.147.366.367s-.146.366-.366.366H4.3c-.22 0-.367-.146-.367-.366s.147-.367.367-.367zm8.067 2.933H4.3c-.22 0-.367-.146-.367-.366S4.08 16 4.3 16h8.067c.22 0 .366.147.366.367s-.146.366-.366.366zm.733-4.4h-1.467c-.22 0-.366-.146-.366-.366s.146-.367.366-.367H13.1c.22 0 .367.147.367.367s-.147.366-.367.366zm5.133 4.4c-.293 0-.513-.073-.733-.146-.22.073-.44.146-.733.146a1.816 1.816 0 01-1.834-1.833c0-1.027.807-1.833 1.834-1.833.22 0 .513.073.733.146.22-.073.513-.146.733-.146 1.027 0 1.834.806 1.834 1.833a1.816 1.816 0 01-1.834 1.833zM16.4 14.9c0-.44.147-.807.367-1.1-.587 0-1.1.513-1.1 1.1 0 .587.513 1.1 1.1 1.1a1.801 1.801 0 01-.367-1.1zm2.611-.778a1.1 1.1 0 10-1.555 1.556 1.1 1.1 0 001.555-1.556z" id="svgicon_credit-card_a"/></defs><use xlink:href="#svgicon_credit-card_a"/>'
  }
})
