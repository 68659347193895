/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'camera': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M12 9.6a3.3 3.3 0 100 6.6 3.3 3.3 0 000-6.6zm8.8-3.3h-2.64c-.363 0-.754-.282-.869-.626l-.682-2.048C16.494 3.282 16.103 3 15.74 3H8.26c-.363 0-.754.282-.869.626L6.71 5.674c-.115.344-.506.626-.869.626H3.2C1.99 6.3 1 7.29 1 8.5v9.9c0 1.21.99 2.2 2.2 2.2h17.6c1.21 0 2.2-.99 2.2-2.2V8.5c0-1.21-.99-2.2-2.2-2.2zM12 18.4a5.5 5.5 0 110-11 5.5 5.5 0 010 11zm8.03-8.362a.77.77 0 110-1.54.77.77 0 010 1.54z" id="svgicon_camera_a"/></defs><use _fill="#424242" xlink:href="#svgicon_camera_a"/>'
  }
})
