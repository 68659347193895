/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'credit-card-o': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" id="svgicon_credit-card-o_a" d="M22.267 7.933V7.2c0-.807-1.027-1.467-1.834-1.467H3.2c-.807 0-1.467.66-1.467 1.467v.733h20.534zm0 3.667H1.733v5.867c0 .806.66 1.466 1.467 1.466h17.6a1.47 1.47 0 001.467-1.466V11.6zM20.8 19.667H3.2c-1.247 0-2.2-.954-2.2-2.2V7.2C1 5.953 1.953 5 3.2 5h17.233C21.607 5 23 5.953 23 7.2v10.267c0 1.246-.953 2.2-2.2 2.2zm-19.067-8.8h20.534v-2.2H1.733v2.2zm6.234 4.4H4.3c-.22 0-.367-.147-.367-.367s.147-.367.367-.367h3.667c.22 0 .366.147.366.367s-.146.367-.366.367zm2.2 1.466H4.3c-.22 0-.367-.146-.367-.366S4.08 16 4.3 16h5.867c.22 0 .366.147.366.367s-.146.366-.366.366z"/></defs><use xlink:href="#svgicon_credit-card-o_a"/>'
  }
})
