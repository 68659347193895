/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'ServicePencil1': {
    width: 25,
    height: 25,
    viewBox: '0 0 25 25',
    data: '<defs><path pid="0" d="M2.208 21.68l1.112 1.112-.83.198a.4.4 0 01-.374-.106.397.397 0 01-.09-.146.396.396 0 01-.026-.133l.002-.015c0-.027.002-.054.008-.08l.198-.83zM22.952 2.047a3.587 3.587 0 010 5.067l-14.36 14.36c-.003.004-.007.005-.01.008-.01.009-.02.014-.029.022a.392.392 0 01-.12.064c-.01.004-.02.01-.03.012l-4.173.994-1.806-1.805.994-4.173.005-.012.011-.03a.387.387 0 01.05-.095l.023-.03c.006-.008.01-.016.017-.023l14.36-14.36a3.59 3.59 0 015.068 0zM8.832 20.11L19.855 9.087l-3.942-3.942L4.89 16.168l1.267-.141 8.912-8.91a.398.398 0 11.563.562L6.72 16.59l-.21 1.9 1.9-.21 8.91-8.912a.398.398 0 11.563.563l-8.91 8.912-.141 1.267zM20.418 8.524l.563-.563-3.942-3.942-.563.563 3.942 3.942zm1.126-1.126l.563-.564-3.942-3.942-.563.564 3.942 3.942z" id="svgicon_ServicePencil1_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon_ServicePencil1_b" _fill="#fff"><use xlink:href="#svgicon_ServicePencil1_a"/></mask><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_ServicePencil1_a"/><g mask="url(#svgicon_ServicePencil1_b)" _fill="#FFF"><path pid="1" d="M0 0h25v25H0z"/></g></g>'
  }
})
