import { signS3 } from '#/api/upload'
import Axios, { CancelTokenStatic, CancelTokenSource } from 'axios'
import Vue from 'vue'
import { namespace } from 'vuex-class'
import { cleanFile } from '~/utils/cleanFile'
import { ModalType } from '~/store/modal'

export enum UploadType {
    NewBeat = 'new_beat',
    MatchBeat = 'match_beat',
    TaggedBeat = 'tagged_beat',
    Public = 'public', // used for beat's images and producers' images (logo, background), also for license summaries pdfs
    SoundKit = 'sound_kit',
    SoundKitDemo = 'sound_kit_demo'
}

export enum UploadStep {
    Initial = 'Initial',
    Started = 'Started'
}

export const UploadStore = namespace('upload')

interface UploadState {
    step: UploadStep
    type: UploadType
    stagedFiles: IItemToUpload[]
    serverUploadIsAllowed: boolean
}

export default {
    namespaced: true,
    state: {
        step: UploadStep.Initial,
        type: UploadType.NewBeat,
        stagedFiles: [],
        serverUploadIsAllowed: false // we keep watch on this, when value changes we do a bulk upload of all staged files
    } as UploadState,
    mutations: {
        SET_UPLOAD_TYPE: (state: UploadState, type: UploadType) => (state.type = type),
        INCREMENT_UPLOAD_STEP: (state: UploadState) => (state.step = UploadStep.Started),
        // crud staged files
        ADD_STAGED_FILE: (state: UploadState, itemToUpload: IItemToUpload) => state.stagedFiles.push(itemToUpload),
        DELETE_STAGED_FILE: (state: UploadState, itemToUploadId: IItemToUpload['id']) => {
            const indexOfElementToRemove = state.stagedFiles.findIndex(item => item.id === itemToUploadId)
            state.stagedFiles.splice(indexOfElementToRemove, 1)
            if (state.stagedFiles.length === 0) state.step = UploadStep.Initial
        },
        RESET_STAGED_FILES: (state: UploadState) => {
            state.stagedFiles = []
            state.step = UploadStep.Initial
        },
        // add properties to staged files
        ADD_URL_TO_STAGED_FILE: (state: UploadState, { stagedFileId, url }: { stagedFileId: IItemToUpload['id']; url: string }) => {
            const indexOfElementToUpdate = state.stagedFiles.findIndex(item => item.id === stagedFileId)
            if (state.stagedFiles && state.stagedFiles[indexOfElementToUpdate]) state.stagedFiles[indexOfElementToUpdate].url = url
        },
        ADD_CANCELLATION_TOKEN_TO_STAGED_FILE: (
            state: UploadState,
            { itemToUploadId, cancelTokenSource }: { itemToUploadId: IItemToUpload['id']; cancelTokenSource: CancelTokenSource }
        ) => {
            const indexOfElementToUpdate = state.stagedFiles.findIndex(item => item.id === itemToUploadId)
            if (state.stagedFiles && state.stagedFiles[indexOfElementToUpdate]) state.stagedFiles[indexOfElementToUpdate].cancelTokenSource = cancelTokenSource
        },
        UPDATE_FILE_UPLOAD_PROGRESS: (
            state: UploadState,
            { stagedFileId, percentageCompleted = 0 }: { stagedFileId: IItemToUpload['id']; percentageCompleted: number }
        ) => {
            const itemToUpdateIndex = state.stagedFiles.findIndex(item => item.id === stagedFileId)
            Vue.set(state.stagedFiles[itemToUpdateIndex], 'progress', percentageCompleted)
        },
        // allows the upload
        ALLOW_SERVER_UPLOAD: (state: UploadState) => (state.serverUploadIsAllowed = !state.serverUploadIsAllowed)
    },
    actions: {
        async singleFileUpload({ dispatch }: any, { fileList, uploadType, maxPxSize = 1200 }: IClientUploadParams): Promise<IUploadReturn> {
            const itemToUpload = await cleanFile(fileList[0], maxPxSize)
            const s3Data = await signS3(itemToUpload, uploadType)
            const dispatchParams = { itemToUpload, s3Data, uploadType, hasCancelTokenAndProgress: false }
            return dispatch('amazonPostRequest', dispatchParams)
        },

        async multipleFilesUpload({ commit, dispatch }: any, { fileList, uploadType }: IClientUploadParams) {
            commit('SET_UPLOAD_TYPE', uploadType)

            for (const file of Array.from(fileList)) {
                const itemToUpload = await cleanFile(file, 400)
                // add image, if it's image, otherwise the file, to the staged file
                commit('ADD_STAGED_FILE', itemToUpload)
                // post request to amazon
                const s3Data = await signS3(itemToUpload, uploadType)
                // update upload step
                commit('INCREMENT_UPLOAD_STEP')
                const dispatchParams = { itemToUpload, s3Data, uploadType }
                dispatch('amazonPostRequest', dispatchParams)
            }
        },

        async amazonPostRequest(
            { commit }: any,
            { itemToUpload, s3Data, uploadType, hasCancelTokenAndProgress = true }: IS3PostRequestParams
        ): Promise<IUploadReturn> {
            const bucketLink = s3Data.url
            const pathToFile = s3Data.fields.key
            const url = (bucketLink + '/' + pathToFile).replace(/ /g, '+')
            const postData = new FormData() // => data to post
            Object.keys(s3Data.fields).forEach(key => postData.append(key, s3Data.fields[key]))
            postData.append('file', itemToUpload.file) // => file to post

            try {
                const cancelToken: CancelTokenStatic = Axios.CancelToken
                const cancelTokenSource: CancelTokenSource = cancelToken.source()
                if (hasCancelTokenAndProgress) commit('ADD_CANCELLATION_TOKEN_TO_STAGED_FILE', { itemToUploadId: itemToUpload.id, cancelTokenSource })

                await Vue.$axios.post(bucketLink, postData, {
                    headers: { Authorization: '' },
                    cancelToken: cancelTokenSource.token,
                    // creates the progress bar
                    onUploadProgress: (progressEvent: any) => {
                        if (hasCancelTokenAndProgress) {
                            const percentageCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
                            commit('UPDATE_FILE_UPLOAD_PROGRESS', { stagedFileId: itemToUpload.id, percentageCompleted })
                            commit('ADD_URL_TO_STAGED_FILE', { stagedFileId: itemToUpload.id, url: uploadType === UploadType.Public ? url : pathToFile })
                        }
                    }
                })

                return { url, pathToFile }
            } catch (err: any) {
                if (err && err.message === 'Operation canceled by the user.') return
                commit('modal/SET_ACTIVE_MODAL', { modal: ModalType.Error }, { root: true })
                throw err
            }
        }
    }
}
