import { namespace } from 'vuex-class'
import { VuexActionData } from '~/interfaces/interface-utils'

export enum ModalType {
    LicenseOptionSelection,
    RequireAccountForLike,
    RequireAccountForFollow,
    Coupon,
    BeatAction,
    Share,
    DemoDownload,
    MailingList,
    LicenseContract,
    Success,
    Error,
    PayPalGuide,
    DeleteLicense,
    DeleteCoupon,
    ConfirmAction,
    SetVaultPassword,
    Subscribe,
    TermsOfService
}

export interface INotificationParams {
    id?: number
    name: string
    title: string
    content: string
}

export interface IModalParams {
    modal: ModalType
    payload?: any
    callback?: () => void
}

interface State {
    isLoading: boolean
    activeNotifications: INotificationParams[]
    lastNotificationId: INotificationParams['id']
    activeModal: IModalParams
}

export const ModalStore = namespace('modal')

export default {
    namespaced: true,
    state: {
        isLoading: false,
        activeNotifications: [],
        lastNotificationId: 0,
        activeModal: {
            modal: null,
            payload: null,
            // a new modal obj to open later => modalCallback: {modal: X, payload: Y}
            callbackModal: null
        }
    } as State,
    mutations: {
        SET_LOADING_STATE: (state: State, payload: boolean) => (state.isLoading = payload),
        PUSH_NOTIFICATION: (state: State, payload: INotificationParams) => {
            state.lastNotificationId++
            state.activeNotifications.unshift(payload)
        },
        REMOVE_NOTIFICATION: (state: State, notification: INotificationParams) => {
            if (state.activeNotifications.length === 0) return
            const notificationToCloseIndex = state.activeNotifications.findIndex(item => item.id === notification.id)
            state.activeNotifications.splice(notificationToCloseIndex, 1)
        },
        SET_ACTIVE_MODAL: (state: State, { modal, payload, callback }: IModalParams) => {
            state.activeModal.modal = modal
            state.activeModal.callback = callback
            state.activeModal.payload = payload
            // if it's success message, close it back after 2 seconds
            if (modal === ModalType.Success) setTimeout(() => (state.activeModal.modal = null), 2000)
        }
    },
    actions: {
        showNotification({ commit, state }: VuexActionData<State>, notification: INotificationParams) {
            // set the id of the notification
            // @ts-ignore
            notification = { ...notification, id: state.lastNotificationId }
            commit('PUSH_NOTIFICATION', notification)
            setTimeout(() => commit('REMOVE_NOTIFICATION', notification), 3000)
        },
        showErrorUnexpected({ dispatch }: VuexActionData<State>) {
            dispatch('showNotification', {
                name: 'error',
                title: 'Unexpected Error',
                content: 'Unexpected error occured, try again later or contact support'
            })
        },
        showErrorUserNotLogged({ dispatch }: VuexActionData<State>) {
            dispatch('showNotification', {
                name: 'error',
                title: 'Account Needed',
                content: 'In order to complete this, you need to be signed in to your account'
            })
        }
    }
}
