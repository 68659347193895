/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'paypal': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M7.712 19.773a.315.315 0 01-.293.294H4.266c-.367 0-.734-.147-.954-.44-.22-.294-.366-.66-.293-1.027L5.072 2.907c.22-1.1 1.1-1.907 2.2-1.907h7.187c1.393 0 2.787.66 3.593 1.76.66.807 1.32 2.127.88 4.18-.733 3.227-1.98 6.527-6.013 6.527H9.106l-1.394 6.306zm12.614-12.76c.586.734 1.32 1.98.953 3.96-.66 3.887-2.713 6.16-5.5 6.16h-2.42l-.88 4.84c-.147.66-.66 1.027-1.247 1.027h-3.3c-.366 0-.733-.147-.953-.513-.293-.294-.367-.66-.293-1.027l.073-.367c.073-.146.22-.293.367-.293h.22c.586 0 1.026-.367 1.1-.88l1.246-5.427c.074-.146.22-.293.367-.293h2.933c5.134 0 6.234-4.913 6.674-7.04.073-.147.146-.293.293-.293.073 0 .22 0 .367.146z" id="svgicon_paypal_a"/></defs><use xlink:href="#svgicon_paypal_a"/>'
  }
})
