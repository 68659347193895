/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'chart-graph': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.233 13.944c.356 0 .645.289.645.645v7.766a.645.645 0 01-.645.645H2.645A.645.645 0 012 22.355V14.59c0-.356.289-.645.645-.645zm5.178-2.589c.356 0 .645.289.645.645v10.355a.645.645 0 01-.645.645H7.822a.645.645 0 01-.644-.645V12c0-.356.288-.645.644-.645zm5.178-2.588c.356 0 .644.288.644.644v12.944a.645.645 0 01-.644.645H13a.645.645 0 01-.645-.645V9.411c0-.356.289-.644.645-.644zm5.178-2.59c.356 0 .644.29.644.645v15.533a.645.645 0 01-.644.645h-2.59a.645.645 0 01-.644-.645V6.822c0-.356.289-.644.645-.644zM18.177 1c.357 0 .645.289.645.645v2.108a.645.645 0 01-1.289 0v-.694a34.659 34.659 0 01-9.272 5.495c-2.885 1.145-4.867 1.48-4.95 1.493a.645.645 0 01-.21-1.272c.02-.003 1.957-.333 4.721-1.434a33.399 33.399 0 008.617-5.052h-.37a.645.645 0 010-1.289z" _fill="#000" fill-rule="nonzero"/>'
  }
})
