/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'mp3': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" id="svgicon_mp3_a" d="M11.067 14.933c-.22 0-.367-.146-.367-.366V9.8l-1.54 2.053c-.073.074-.147.147-.293.147-.147 0-.22-.073-.294-.147L7.033 9.8v4.767c0 .22-.146.366-.366.366s-.367-.146-.367-.366V8.7c0-.147.073-.293.22-.367.147 0 .367 0 .44.147l1.907 2.567 1.906-2.567c.147-.147.294-.22.44-.147.147.074.22.22.22.367v5.867c0 .22-.146.366-.366.366zm4.766-4.766c0 .953-.88 1.833-1.76 1.833h-1.1v2.567c0 .22-.146.366-.366.366s-.367-.146-.367-.366V8.7c0-.147 0-.22.073-.293.074 0 .22-.074.294-.074h1.466c.954 0 1.76.88 1.76 1.834zm-2.933 1.1h1.027c.586 0 1.1-.514 1.1-1.1 0-.587-.44-1.1-1.027-1.1h-1.1v2.2zm6.6.366c.073.074.147.074.22.147.293.367.513.807.513 1.32a1.816 1.816 0 01-1.833 1.833 1.816 1.816 0 01-1.833-1.833c0-.22.146-.367.366-.367s.367.147.367.367c0 .587.513 1.1 1.1 1.1.587 0 1.1-.513 1.1-1.1 0-.293-.073-.587-.293-.807-.22-.146-.514-.293-.807-.293h-.367c-.22 0-.366-.147-.366-.367s.146-.366.366-.366h.367c.587 0 1.1-.514 1.1-1.1 0-.587-.513-1.1-1.1-1.1-.587 0-1.1.513-1.1 1.1 0 .22-.147.366-.367.366s-.366-.146-.366-.366c0-1.027.806-1.834 1.833-1.834s1.833.807 1.833 1.834c0 .586-.293 1.1-.733 1.466zm-6.967 8.434c.22 0 .294.146.294.366s-.147.367-.367.367h-8.8c-.22 0-.367-.147-.367-.367V6.353l.074-.073c.073 0 .073-.073.073-.073l5.133-5.134C8.573 1 8.647 1 8.647 1h10.486c.22 0 .367.147.367.367v5.866c0 .22-.147.367-.367.367s-.366-.147-.366-.367v-5.5H9.233V6.5c0 .22-.146.367-.366.367H4.1v13.2h8.433zM4.613 6.133H8.5V2.247L4.613 6.133zM19.793 16.4h.294c.073.073.146.147.146.293v4.767c0 .44-.44.733-1.1.733-.66 0-1.1-.293-1.1-.733s.44-.733 1.1-.733c.147 0 .22.073.367.073v-3.593l-2.933.586v4.474c0 .44-.44.733-1.1.733-.66 0-1.1-.293-1.1-.733s.44-.734 1.1-.734c.146 0 .22.074.366.074V17.5c0-.147.147-.367.294-.367l3.666-.733z"/></defs><use xlink:href="#svgicon_mp3_a"/>'
  }
})
