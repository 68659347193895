import { namespace } from 'vuex-class'
import { VuexActionData } from '~/interfaces/interface-utils'
import set from 'lodash.set'
import { updateCssVariable } from '~/utils/updateCssVariable'
import { fetchProductPage, fetchProducts } from '@elitepage/api/product'

export const ProductPageStore = namespace('salepage')

interface ProductPageState {
    productPage: IProductPage
    product: IProduct
}

export interface IProductPagePatch {
    key: keyof IProductPage
    value: any
}

export default {
    namespaced: true,
    state: {
        productPage: null,
        product: null
    } as ProductPageState,
    mutations: {
        SET_PRODUCT_PAGE: (state: ProductPageState, payload: IProductPage) => (state.productPage = payload),
        SET_PRODUCT: (state: ProductPageState, payload: IProduct) => (state.product = payload),
        UPDATE_SALE_PAGE: (state: ProductPageState, payload: IProductPagePatch) => set(state.productPage, payload.key, payload.value)
    },
    actions: {
        async loadProductPage({ commit }: VuexActionData<ProductPageState>, producerSlug: IProducer['slug']) {
            const productPage = await fetchProductPage(producerSlug)
            // update css variables from js variables
            Object.keys(productPage).forEach(it => {
                const key = it as keyof IProductPage
                updateCssVariable(key, productPage[key])
            })
            commit('SET_PRODUCT_PAGE', productPage)
        },
        async loadProducts({ commit }: VuexActionData<ProductPageState>, producerId: IProducer['id']) {
            const product = await fetchProducts(producerId)
            commit('SET_PRODUCT', product)
        }
    }
}
