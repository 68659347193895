import { get, update } from '~/services/http-requests'

const _BS_ELITE_PAGE = 'elite_page'
const _BS_ANALYTICS_URL = 'api_producer/analytics'

export async function fetchProducerhasEliteSubscription(producerSlug: IProducer['slug']): Promise<boolean> {
    const data = await get({ fullUrl: `producer_has_premium_subscription/${producerSlug}/` })
    return data.has_premium_subscription
}

export async function fetchElitePage(producerSlug: IProducer['slug']): Promise<IElitepage> {
    const [first] = await get({ fullUrl: `${_BS_ELITE_PAGE}.json?slug=${producerSlug}` })
    return first
}

export async function fetchElitePageAnalytics(producerId: IProducer['id']): Promise<{ beatPlays: number; pageViews: number }> {
    const beatPlays = await get({ fullUrl: `/${_BS_ANALYTICS_URL}/total_elitepage_beat_plays?producer=${producerId}`, isFreeRequest: true })
    const pageViews = await get({ fullUrl: `/${_BS_ANALYTICS_URL}/total_elitepage_page_visits?producer=${producerId}`, isFreeRequest: true })
    return { beatPlays: beatPlays.all_time, pageViews: pageViews.all_time }
}

export function updateElitePage(producerId: IProducer['id'], elitepage: IElitepage): Promise<IService> {
    return update({ baseProperty: _BS_ELITE_PAGE, fullUrl: `/api_producer/${_BS_ELITE_PAGE}/${producerId}/`, dispatchParams: elitepage, isFreeRequest: true })
}
