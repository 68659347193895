/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'checkbox-custom': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.083 3.142c-.948-.51-2.005.474-2.624 1.057-1.422 1.385-2.624 2.989-3.973 4.447-1.494 1.603-2.88 3.207-4.41 4.774-.875.875-1.822 1.823-2.406 2.916-1.312-1.276-2.442-2.66-3.9-3.79-1.056-.802-2.806-1.385-2.77.546.073 2.515 2.297 5.212 3.937 6.925.692.73 1.604 1.495 2.66 1.531 1.276.073 2.588-1.458 3.354-2.296 1.348-1.458 2.442-3.098 3.68-4.593 1.604-1.968 3.245-3.9 4.812-5.904.984-1.24 4.082-4.3 1.64-5.613z" _fill="#14F35F"/>'
  }
})
