import { get, update } from '~/services/http-requests'

const _BS_MAILING_LIST_PAGE = 'mailing_list_page'

export async function fetchMailingListPage(producerSlug: IProducer['slug']): Promise<IMailingListPage> {
    const [first] = await get({ fullUrl: `${_BS_MAILING_LIST_PAGE}.json?slug=${producerSlug}` })
    return first
}

export function updateMailingListPage(producerId: IProducer['id'], mailingListPage: IMailingListPage): Promise<IMailingListPage> {
    return update({
        baseProperty: _BS_MAILING_LIST_PAGE,
        fullUrl: `/api_producer/${_BS_MAILING_LIST_PAGE}/${producerId}/`,
        dispatchParams: mailingListPage,
        isFreeRequest: true
    })
}
