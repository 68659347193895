





























































































import { Component, Vue } from 'vue-property-decorator'

import PColorPicker from '@elitepage/components/PColorPicker.vue'
// @ts-ignore
import draggable from 'vuedraggable'

import { ElitepageStore } from '@elitepage/store/elitepage'
import { ModalStore, IModalParams, ModalType } from '~/store/modal'
import { UploadStore, UploadType } from '~/store/upload'
import { youTubeGetVideoID } from '~/utils/youtubeStrings'
import { updateMailingListPage } from '@elitepage/api/mailing-list-page'
import { ColorPickerParentStateType } from '~/const/parent-state-type'
import { IMailingListPagePatch, MailingListPageStore } from '@elitepage/store/mailing-list-page'

const sections = ['colors', 'header', 'textContent1', 'youtube', 'textContent2', 'thankYou']
type SectionKey = typeof sections[number]

enum FileToRemoveOrAdd {
    HeaderBackground
}

@Component({ components: { PColorPicker, draggable } })
export default class SidebarMailingListPage extends Vue {
    FileToRemoveOrAdd = FileToRemoveOrAdd
    ColorPickerParentStateType = ColorPickerParentStateType
    openSection: SectionKey = 'colors'
    isSidebarOpen = false
	regexUrl = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/

    @ElitepageStore.State('producer') producer: IProducer
    @MailingListPageStore.State('mailingListPage') mailingListPage: IMailingListPage

    get youtubeVideoLink() {
        return this.mailingListPage.youtube_video_id ? 'https://www.youtube.com/embed/' + this.mailingListPage.youtube_video_id : null
    }

    set youtubeVideoLink(newValue: string) {
        this.UPDATE_MAILING_LIST_PAGE({ key: 'youtube_video_id', value: youTubeGetVideoID(newValue) })
    }

    @MailingListPageStore.Mutation('UPDATE_MAILING_LIST_PAGE') UPDATE_MAILING_LIST_PAGE: (payload: IMailingListPagePatch) => void
    @ModalStore.Mutation('SET_ACTIVE_MODAL') SET_ACTIVE_MODAL: (modalParams: IModalParams) => void

    @UploadStore.Action('singleFileUpload') singleFileUpload: (IClientUploadParams: IClientUploadParams) => Promise<IUploadReturn>

    openCloseSection(section: SectionKey) {
        this.openSection = section === this.openSection ? null : section
    }

    async removeFile(property: keyof IMailingListPage) {
        try {
            this.UPDATE_MAILING_LIST_PAGE({ key: property, value: '' })
        } catch (error) {
            this.$store.dispatch('modal/showNotification', { name: 'error', title: 'Error!', content: `There was an error removing the image` })
        }
    }

    async uploadFile(fileList: FileList, fileToAdd: FileToRemoveOrAdd) {
        try {
            const uploadType = UploadType.Public

            const { url } = await this.singleFileUpload({ fileList, uploadType, maxPxSize: 2000 })
            switch (fileToAdd) {
                case FileToRemoveOrAdd.HeaderBackground:
                    this.UPDATE_MAILING_LIST_PAGE({ key: 'header_image_url', value: url })
                    this.UPDATE_MAILING_LIST_PAGE({ key: 'thankyou_image_url', value: url })
                    break
            }
        } catch (err) {
            this.SET_ACTIVE_MODAL({ modal: ModalType.Error })
        }
    }

    async saveChanges() {
        try {
            await updateMailingListPage(this.producer.id, this.mailingListPage)
            this.$store.dispatch('modal/showNotification', { name: 'success', title: 'Success!', content: `Mailing List page settings saved` })
        } catch (error) {
            this.$store.dispatch('modal/showNotification', { name: 'error', title: 'Error!', content: `There was an error saving your settings` })
        }
    }
}
