/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'ServicePicture': {
    width: 25,
    height: 25,
    viewBox: '0 0 25 25',
    data: '<defs><path pid="0" d="M17.211 9.896a2.598 2.598 0 01-2.601-2.601 2.598 2.598 0 012.601-2.602 2.598 2.598 0 012.601 2.602 2.61 2.61 0 01-2.6 2.6zM7.01 20.282c0 .277.13.535.406.535h11.198c-.074 0-.184.203-.313.221l-12.545 2.38h-.092a.497.497 0 01-.277-.092c-.11-.055-.185-.184-.203-.314L2.01 6.243c-.056-.258.129-.516.406-.572l4.593-.922v15.533zM23.557 2c.277 0 .425.203.443.48v17.083c0 .276-.074.516-.35.516H8.19c-.277 0-.443-.24-.443-.516V2.48c0-.277.185-.48.461-.48h15.35zm-.682 7.121V3.107H8.855v10.866l4.04-4.022.22-.203a.65.65 0 01.425-.166c.147 0 .295.074.406.185l3.302 3.726 3.985-5.055c.092-.11.221-.166.406-.166.147 0 .313.056.424.166l.812.683z" id="svgicon_ServicePicture_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon_ServicePicture_b" _fill="#fff"><use xlink:href="#svgicon_ServicePicture_a"/></mask><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_ServicePicture_a"/><g mask="url(#svgicon_ServicePicture_b)" _fill="#FFF"><path pid="1" d="M0 0h25v25H0z"/></g></g>'
  }
})
