/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'alert-off': {
    width: 25,
    height: 25,
    viewBox: '0 0 25 25',
    data: '<defs><path pid="0" d="M18.294 11.5V9.726c0-2.262-1.442-4.313-3.533-5.048v-.912a1.763 1.763 0 10-3.525 0v.93c-2.066.757-3.53 2.918-3.53 5.23V11.5c0 1.307-.341 2.436-1.064 3.517h12.716c-.723-1.08-1.064-2.21-1.064-3.517zm-4.706-7.117a5.79 5.79 0 00-1.18.004v-.622c0-.325.268-.589.592-.589.325 0 .588.264.588.589v.618zm6.47 11.812c.973 0 1.765.791 1.765 1.764s-.792 1.765-1.765 1.765H5.942a1.766 1.766 0 01-1.765-1.765c0-.973.792-1.764 1.765-1.764h14.116zM10.664 20.9h4.674A2.928 2.928 0 0113 22.076c-.882 0-1.707-.362-2.336-1.176z" id="svgicon_alert-off_a"/></defs><use _fill="#FFF" xlink:href="#svgicon_alert-off_a"/>'
  }
})
