/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'heart': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" id="svgicon_heart_a" d="M16.4 2c-1.933 0-3.467 1.2-4.4 2.4C11.067 3.2 9.467 2 7.6 2 4.533 2 2 4.533 2 7.6c0 6.667 9.4 14 9.8 14.333.067.067.133.067.2.067s.133 0 .2-.067C12.6 21.6 22 14.267 22 7.6 22 4.533 19.467 2 16.4 2z"/></defs><use xlink:href="#svgicon_heart_a"/>'
  }
})
