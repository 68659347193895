/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'heart-o': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" id="svgicon_heart-o_a" d="M12 22c-.067 0-.133 0-.2-.067C11.4 21.6 2 14.267 2 7.6 2 4.533 4.533 2 7.6 2c1.933 0 3.467 1.2 4.4 2.4.933-1.2 2.533-2.4 4.4-2.4C19.467 2 22 4.533 22 7.6c0 6.667-9.4 14-9.8 14.333-.067.067-.133.067-.2.067zM7.866 3.228A4.625 4.625 0 003.23 7.864c0 5.45 7.454 11.776 8.77 12.841 1.316-1.065 8.77-7.329 8.77-12.841a4.625 4.625 0 00-4.636-4.636c-1.754 0-3.132 1.19-3.883 2.38-.126.188-.439.188-.502 0-.751-1.19-2.13-2.38-3.883-2.38z"/></defs><use xlink:href="#svgicon_heart-o_a"/>'
  }
})
