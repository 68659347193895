






import { Vue, Component } from 'vue-property-decorator'

import ModalManager from '@elitepage/components/modals/Index.vue'
import TheFixedBottomElements from '~/components/TheFixedBottomElements.vue'

@Component({ components: { ModalManager, TheFixedBottomElements } })
export default class App extends Vue {
    mounted() {
        this.$store.dispatch('base/onAppLoad')
    }
}
