/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'download-o': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.629 15.908c.247.247.371.55.371.9v4.23c0 .35-.124.653-.37.899-.247.247-.55.37-.899.37H2.269a1.23 1.23 0 01-.898-.37A1.227 1.227 0 011 21.038v-4.23c0-.35.124-.653.37-.899a1.23 1.23 0 01.899-.37h6.148l1.785 1.798c.507.49 1.113.74 1.798.74a2.51 2.51 0 001.797-.74l1.8-1.798h6.134c.35 0 .652.125.898.37zm-.41 5.13v-4.23a.448.448 0 00-.142-.347.446.446 0 00-.346-.14H15.92l-1.575 1.573c-.664.642-1.452.965-2.345.965-.894 0-1.683-.325-2.347-.966l-1.561-1.572H2.269a.447.447 0 00-.346.142.44.44 0 00-.141.345v4.23c0 .146.04.247.141.347.1.1.2.14.346.14h19.462a.45.45 0 00.348-.142.445.445 0 00.14-.345zm-5.142-2.115a.82.82 0 01.595.251.81.81 0 01.251.595.81.81 0 01-.25.595.814.814 0 01-.596.251.814.814 0 01-.595-.25.81.81 0 01-.25-.596.81.81 0 01.25-.595.814.814 0 01.595-.25zm3.385 0c.23 0 .428.085.594.251a.811.811 0 01.252.595.81.81 0 01-.252.595.81.81 0 01-.594.251.817.817 0 01-.596-.25.816.816 0 01-.25-.596c0-.229.084-.427.25-.595a.817.817 0 01.596-.25zm-9.057-2.79l-5.923-5.922c-.273-.256-.336-.561-.186-.926.15-.344.413-.515.78-.515h3.386V2.846c0-.228.084-.428.25-.595A.815.815 0 0110.309 2h3.384c.23 0 .43.085.597.252.166.166.25.366.25.594v5.923h3.384c.37 0 .631.174.78.516.151.361.088.67-.186.926l-5.922 5.922a.787.787 0 01-.595.251.79.79 0 01-.595-.25zM6.077 9.551l-.078.095L12 15.603a.108.108 0 00.023-.002l5.951-5.953-.016-.104-4.201.007V2.846l-.065-.064h-3.384l-.064.048v6.72H6.076z"/>'
  }
})
