import { Vue, Component } from 'vue-property-decorator'

@Component
export class BeatIsFullyBoughtAndBeatIsLiked extends Vue {
    get beatIsFullyBought() {
        // // retrive bought beats
        // const boughtBeats = this.$store.state.user.boughtBeats
        // // if the user is authenticated && has bought beats
        // if (this.$store.getters['auth/isAuthenticated'] && boughtBeats) {
        //     // grab producer licenses
        //     const licenseOptions = this.beat.producer.license_options
        //     // grab the value of the last license available in the array of licenses
        //     const topLicenseValue = licenseOptions.length ? licenseOptions[licenseOptions.length - 1].value : null
        //     // find the beat match inside the bought beats
        //     const boughtBeat = boughtBeats.find(item => item.beat.id === this.beat.id)
        //     // compare the license's value of the bought beat to the const topLicenseValue
        //     return boughtBeat && boughtBeat.license.value >= topLicenseValue
        // }
        return false
    }

    get beatIsLiked() {
        // retrive liked beats
        const likedBeats: ILikedBeat[] = this.$store.state.user.likedBeats
        // if user is authenticated, find the beat inside the likedBeats array and return true if it's there
        if (this.$store.getters['auth/isAuthenticated'] && likedBeats.length > 0) {
            // @ts-ignore
            return likedBeats.some(item => item.beat.id === this.beat.id)
        } else return false
    }
}
