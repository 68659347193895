/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'instagram': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M16.446 2A6.561 6.561 0 0123 8.554v8.892A6.561 6.561 0 0116.446 24H7.554A6.561 6.561 0 011 17.446V8.554A6.561 6.561 0 017.554 2h8.892zm4.34 15.446V8.554a4.34 4.34 0 00-4.34-4.34H7.554a4.34 4.34 0 00-4.34 4.34v8.892a4.34 4.34 0 004.34 4.34h8.892a4.34 4.34 0 004.34-4.34zM12 7.31A5.696 5.696 0 0117.69 13 5.696 5.696 0 0112 18.69 5.696 5.696 0 016.31 13 5.696 5.696 0 0112 7.31zm0 9.167a3.477 3.477 0 100-6.954 3.477 3.477 0 000 6.954zm5.701-7.76a1.363 1.363 0 110-2.728 1.363 1.363 0 010 2.727z" id="svgicon_instagram_a"/></defs><use _fill="#FFF" xlink:href="#svgicon_instagram_a"/>'
  }
})
