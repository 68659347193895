/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'alert-on': {
    width: 25,
    height: 25,
    viewBox: '0 0 25 25',
    data: '<defs><path pid="0" d="M18.283 11.5V9.724c0-2.262-1.442-4.313-3.533-5.048v-.913a1.763 1.763 0 10-3.525 0v.93c-2.065.758-3.529 2.919-3.529 5.23V11.5a6.084 6.084 0 01-1.065 3.517h12.717c-.723-1.08-1.065-2.21-1.065-3.517zm-4.705-7.118a5.79 5.79 0 00-1.18.003v-.622a.589.589 0 011.18 0v.619zm6.47 11.811a1.766 1.766 0 010 3.529H5.932a1.766 1.766 0 01-1.765-1.764c0-.973.792-1.765 1.765-1.765h14.116zM4.837 4.912c.29.146.408.499.262.789-1.135 2.26-1.205 4.9-.37 7.12a.589.589 0 01-1.102.414c-.948-2.525-.863-5.506.42-8.061a.588.588 0 01.79-.262zm17.095.262c1.284 2.556 1.368 5.538.42 8.06a.59.59 0 01-1.101-.413c.834-2.22.763-4.86-.37-7.12a.588.588 0 111.05-.527zM6.263 7.426a.59.59 0 01.435.71c-.217.9-.23 1.9-.073 2.759a.588.588 0 11-1.156.211 8.058 8.058 0 01.085-3.245.585.585 0 01.709-.435zm14.163.435c.248 1.033.277 2.2.085 3.245a.587.587 0 11-1.157-.211 6.89 6.89 0 00-.073-2.76.588.588 0 011.145-.274zM11.99 20.999h4.673a2.928 2.928 0 01-2.338 1.176c-.881 0-1.707-.362-2.335-1.176z" id="svgicon_alert-on_a"/></defs><use _fill="#FFF" transform="rotate(12 12.99 12.087)" xlink:href="#svgicon_alert-on_a"/>'
  }
})
