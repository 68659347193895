/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'ServiceCd': {
    width: 25,
    height: 25,
    viewBox: '0 0 25 25',
    data: '<defs><path pid="0" d="M12.005 10.997a.58.58 0 01-.367.122.622.622 0 01-.524-.262l-1.589-2.13a.641.641 0 01.123-.908.66.66 0 01.908.14l1.588 2.13a.66.66 0 01-.14.908zm5.43 2.025a2.891 2.891 0 01-2.898 2.899c-1.607 0-2.934-1.292-2.934-2.899a2.921 2.921 0 012.916-2.916 2.921 2.921 0 012.916 2.916zM2.663 22.556c-.349 0-.663-.385-.663-.734V3.733C2 3.384 2.314 3 2.663 3H4.62v19.556H2.663zM17.19 16.13l1.502 1.903c.21.28.157.681-.122.908a.7.7 0 01-.402.14.645.645 0 01-.506-.244l-1.484-1.921a.642.642 0 01.105-.908.66.66 0 01.907.122zM23.337 3c.349 0 .663.384.663.733v18.09c0 .348-.314.733-.663.733H5.84V3h17.496zm-8.8 17.862c4.312 0 7.84-3.527 7.84-7.84a7.84 7.84 0 00-7.84-7.84c-4.33 0-7.84 3.528-7.84 7.84 0 4.33 3.527 7.84 7.84 7.84z" id="svgicon_ServiceCd_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon_ServiceCd_b" _fill="#fff"><use xlink:href="#svgicon_ServiceCd_a"/></mask><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_ServiceCd_a"/><g mask="url(#svgicon_ServiceCd_b)" _fill="#FFF"><path pid="1" d="M0 0h25v25H0z"/></g></g>'
  }
})
