/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'pause': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 22.7c5.91 0 10.7-4.79 10.7-10.7 0-5.91-4.79-10.7-10.7-10.7C6.09 1.3 1.3 6.09 1.3 12c0 5.91 4.79 10.7 10.7 10.7zm0 1.3C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12zM9.5 8h1a.5.5 0 01.5.5v7a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-7a.5.5 0 01.5-.5zm4 0h1a.5.5 0 01.5.5v7a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-7a.5.5 0 01.5-.5z" _fill="#FFF"/>'
  }
})
