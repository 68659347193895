/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'arrow-left': {
    width: 16,
    height: 16,
    viewBox: '0 0 13.89 25',
    data: '<path pid="0" class="a" d="M.58 13.93l9.26 10.3a2.35 2.35 0 003.27.2 2.24 2.24 0 00.21-3.2l-7.9-8.79 7.88-8.66a2.23 2.23 0 00-.19-3.2 2.35 2.35 0 00-3.26.18L.59 10.93a2.23 2.23 0 00-.01 3z"/>'
  }
})
