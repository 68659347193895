/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'add-to-cart-o': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" id="svgicon_add-to-cart-o_a" d="M4.593 8.333L5.987 15.3c.293 1.467 1.613 2.567 3.08 2.567h10.56c.22 0 .366-.147.366-.367s-.146-.367-.366-.367H9.067c-1.011 0-1.858-.598-2.261-1.514l13.627-1.712a.573.573 0 00.294-.294l.66-2.64c0-.22-.074-.366-.294-.44-.22 0-.366.074-.44.294l-.586 2.346L6.72 14.86 5.033 6.867h7.407c.147 0 .367-.147.367-.367s-.147-.367-.367-.367H4.888l-.515-2.64c-.073-.146-.22-.293-.366-.293h-2.64c-.22 0-.367.147-.367.367s.147.366.367.366h2.346l.515 2.573-.001.067.366 1.76zM8.627 23c-1.247 0-2.2-.953-2.2-2.2 0-1.247.953-2.2 2.2-2.2 1.246 0 2.2.953 2.2 2.2 0 1.247-1.027 2.2-2.2 2.2zm0-3.667c-.807 0-1.467.66-1.467 1.467s.66 1.467 1.467 1.467c.806 0 1.466-.66 1.466-1.467s-.66-1.467-1.466-1.467zM18.16 23c-1.247 0-2.2-.953-2.2-2.2 0-1.247.953-2.2 2.2-2.2 1.247 0 2.2.953 2.2 2.2 0 1.247-1.027 2.2-2.2 2.2zm0-3.667c-.807 0-1.467.66-1.467 1.467s.66 1.467 1.467 1.467 1.467-.66 1.467-1.467-.66-1.467-1.467-1.467zm.44-13.2v1.1c0 .22-.147.367-.367.367s-.366-.147-.366-.367v-1.1h-1.1c-.22 0-.367-.146-.367-.366s.147-.367.367-.367h1.1V4.3c0-.22.146-.367.366-.367s.367.147.367.367v1.1h1.1c.22 0 .367.147.367.367s-.147.366-.367.366h-1.1zm-.367 4.4a4.757 4.757 0 01-4.766-4.766A4.757 4.757 0 0118.233 1 4.757 4.757 0 0123 5.767a4.757 4.757 0 01-4.767 4.766zm0-8.8c-2.2 0-4.033 1.834-4.033 4.034 0 2.2 1.833 4.033 4.033 4.033s4.034-1.833 4.034-4.033-1.834-4.034-4.034-4.034z"/></defs><use xlink:href="#svgicon_add-to-cart-o_a"/>'
  }
})
