/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'google': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g fill-rule="nonzero" _fill="none"><path pid="0" d="M5.876 14.295l-.766 2.859-2.8.059A10.95 10.95 0 011 12c0-1.824.444-3.544 1.23-5.059l2.492.457 1.092 2.477A6.539 6.539 0 005.461 12c0 .807.146 1.581.415 2.295z" _fill="#FBBB00"/><path pid="1" d="M22.808 9.945c.126.665.192 1.353.192 2.055a10.998 10.998 0 01-4.113 8.578h-.001l-3.139-.16-.444-2.774a6.556 6.556 0 002.82-3.347h-5.881V9.945H22.808z" _fill="#518EF8"/><path pid="2" d="M18.886 20.578A10.954 10.954 0 0112 23c-4.19 0-7.831-2.341-9.69-5.787l3.566-2.918a6.54 6.54 0 009.427 3.35l3.583 2.933z" _fill="#28B446"/><path pid="3" d="M19.021 3.532L15.458 6.45a6.543 6.543 0 00-9.644 3.425L2.231 6.94H2.23A10.998 10.998 0 0112 1c2.669 0 5.116.95 7.021 2.532z" _fill="#F14336"/></g>'
  }
})
