/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'twitter': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M23 4.326a8.383 8.383 0 01-2.591.782c.932-.614 1.645-1.588 1.983-2.745a8.615 8.615 0 01-2.867 1.205C18.7 2.602 17.53 2 16.23 2c-2.493 0-4.514 2.223-4.514 4.963 0 .389.04.768.117 1.131-3.75-.207-7.075-2.183-9.301-5.187a5.309 5.309 0 00-.61 2.495c0 1.723.797 3.243 2.007 4.132a4.206 4.206 0 01-2.045-.623v.062c0 2.404 1.557 4.41 3.62 4.868a4.182 4.182 0 01-1.19.174 3.93 3.93 0 01-.849-.093c.575 1.974 2.241 3.409 4.215 3.448-1.544 1.33-3.49 2.122-5.605 2.122A8.73 8.73 0 011 19.424c1.998 1.41 4.369 2.233 6.918 2.233 8.302 0 12.84-7.564 12.84-14.124l-.015-.642A9.559 9.559 0 0023 4.326z" id="svgicon_twitter_a"/></defs><use _fill="#FFF" xlink:href="#svgicon_twitter_a"/>'
  }
})
