/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'open-menu': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M3.115 14.23a2.115 2.115 0 110-4.23 2.115 2.115 0 010 4.23zm8.885 0A2.115 2.115 0 1112 10a2.115 2.115 0 010 4.23zm8.885 0a2.115 2.115 0 110-4.23 2.115 2.115 0 010 4.23z" id="svgicon_open-menu_a"/></defs><use _fill="#FFF" xlink:href="#svgicon_open-menu_a" fill-rule="evenodd"/>'
  }
})
