import { namespace } from 'vuex-class'
import { VuexActionData } from '~/interfaces/interface-utils'
import { fetchSingleProducer } from '@/api/producers'
import set from 'lodash.set'
import { updateCssVariable } from '~/utils/updateCssVariable'
import { fetchProducerHasSoundKits } from '@elitepage/api/sound-kits'
import { fetchProducerHasServices } from '@elitepage/api/services'
import { fetchElitePage } from '@elitepage/api/elitepage'

export const ElitepageStore = namespace('elitepage')

interface ElitepageState {
    producer: IProducer
    hasSoundKits: boolean
    hasServices: boolean
    elitepage: IElitepage
}

export interface IElitepagePatch {
    key: keyof IElitepage
    value: any
}

export default {
    namespaced: true,
    state: {
        producer: null,
        hasPremiumSub: false,
        hasSoundKits: false,
        hasServices: false,
        elitepage: null
    } as ElitepageState,
    getters: {
        bodySectionsAsMap: (state: ElitepageState) => {
            // we convert the array to a dictionary
            // where each key is mapped to the index
            const map: Record<string, number> = {}
            state.elitepage.body_sections
                .filter(it => it.visible)
                .forEach((it, index) => {
                    // index + 1 to avoid js issues with 0 being false, needed for Home.vue show/hide sections
                    map[it.key] = index + 1
                })
            return map
        }
    },
    mutations: {
        SET_PRODUCER: (state: ElitepageState, payload: IProducer) => (state.producer = payload),
        SET_ELITEPAGE: (state: ElitepageState, payload: IElitepage) => (state.elitepage = payload),
        UPDATE_ELITEPAGE: (state: ElitepageState, payload: IElitepagePatch) => set(state.elitepage, payload.key, payload.value),
        SET_HAS_SOUND_KITS: (state: ElitepageState, payload: boolean) => (state.hasSoundKits = payload),
        SET_HAS_SERVICES: (state: ElitepageState, payload: boolean) => (state.hasServices = payload)
    },
    actions: {
        async fetchSingleProducer({ commit }: VuexActionData<ElitepageState>, producerSlug: IProducer['slug']) {
            commit('SET_PRODUCER', await fetchSingleProducer(producerSlug))
            commit('SET_HAS_SOUND_KITS', await fetchProducerHasSoundKits(producerSlug))
            commit('SET_HAS_SERVICES', await fetchProducerHasServices(producerSlug))
        },
        async loadElitepage({ commit }: VuexActionData<ElitepageState>, producerSlug: IProducer['slug']) {
            const elitepage = await fetchElitePage(producerSlug)
            // update css variables from js variables
            Object.keys(elitepage).forEach(it => {
                const key = it as keyof IElitepage
                updateCssVariable(key, elitepage[key])
            })
            commit('SET_ELITEPAGE', elitepage)
        }
    }
}
