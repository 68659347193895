/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'piano-o': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M21.615 5H2.385C2.154 5 2 5.154 2 5.385V19.23c0 .23.154.384.385.384h19.23c.231 0 .385-.153.385-.384V5.385c0-.231-.154-.385-.385-.385zm-3.461.77v6.153h-.77V5.77h.77zm-3.846 0v6.153h-.77V5.77h.77zm-3.846 0v6.153h-.77V5.77h.77zm-3.847 0v6.153h-.769V5.77h.77zM2.77 18.845V5.77h2.308v6.539c0 .23.154.384.385.384h.384v6.154H2.77zm3.846 0v-6.154H7c.23 0 .385-.154.385-.384V5.769h1.538v6.539c0 .23.154.384.385.384h.384v6.154H6.615zm3.847 0v-6.154h.384c.23 0 .385-.154.385-.384V5.769h1.538v6.539c0 .23.154.384.385.384h.384v6.154h-3.076zm3.846 0v-6.154h.384c.231 0 .385-.154.385-.384V5.769h1.538v6.539c0 .23.154.384.385.384h.385v6.154h-3.077zm6.923 0h-3.077v-6.154h.384c.231 0 .385-.154.385-.384V5.769h2.308v13.077z" id="svgicon_piano-o_a"/></defs><use xlink:href="#svgicon_piano-o_a"/>'
  }
})
