import { Vue, Component } from 'vue-property-decorator'

@Component
export class DynamicClassesOnShow extends Vue {
    get playerIsShowing() {
        return {
            playerIsVisible: this.$store.state.player.playerIsShowing
        }
    }
    get dealBarIsShowing() {
        return {
            dealBarIsVisible: this.$store.getters['user/userActiveDeals']
        }
    }
}
