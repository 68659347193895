import { get } from '~/services/http-requests'

const _BS_SOUND_KITS = 'sound_kits'

export async function fetchSoundKits(producerId: IProducer['id'], limit?: number): Promise<ISoundKit[]> {
    let fullUrl
    if (limit) fullUrl = `${_BS_SOUND_KITS}.json?producer=${producerId}&limit=${limit}`
    else fullUrl = `${_BS_SOUND_KITS}.json?producer=${producerId}`

    const data = await get({ baseProperty: _BS_SOUND_KITS, fullUrl })

    return data.results ? data.results : data
}

export async function fetchSoundKit(producerId: IProducer['id'], soundkitId: ISoundKit['id']): Promise<ISoundKit> {
    const [first] = await get({
        baseProperty: _BS_SOUND_KITS,
        fullUrl: `${_BS_SOUND_KITS}.json?producer=${producerId}&id=${soundkitId}`
    })
    return first
}

export async function fetchProducerHasSoundKits(producerSlug: IProducer['slug']): Promise<boolean> {
    const data = await get({ baseProperty: _BS_SOUND_KITS, fullUrl: `producer_has_sound_kits/${producerSlug}/` })
    return data.has_sound_kits
}
