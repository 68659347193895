/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'copyright': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M12 1c6.05 0 11 4.95 11 11s-4.95 11-11 11S1 18.05 1 12 5.95 1 12 1zm0 2.063A8.92 8.92 0 003.062 12 8.92 8.92 0 0012 20.938 8.92 8.92 0 0020.938 12 8.92 8.92 0 0012 3.062zm2.613 12.1l1.237 1.65c-1.1.825-2.338 1.375-3.85 1.375A6.162 6.162 0 015.812 12 6.162 6.162 0 0112 5.812c1.65 0 3.3.688 4.4 1.788l-1.512 1.513C14.2 8.288 13.1 7.875 12 7.875c-2.338 0-4.125 1.787-4.125 4.125S9.662 16.125 12 16.125c.963 0 1.925-.412 2.613-.963z" id="svgicon_copyright_a"/></defs><use xlink:href="#svgicon_copyright_a"/>'
  }
})
