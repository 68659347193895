import { namespace } from 'vuex-class'
import { fetchOwnProfile } from '~/api/user'
import { VuexActionData } from '~/interfaces/interface-utils'

export const UserStore = namespace('user')

interface State {
    authenticatedUser: IUser
    likedBeats: ILikedBeat[]
    boughtBeats: IBoughtBeat[]
    followedProducers: IFollowedProducer[]
    followedPlaylists: IFollowedPlaylist[]
}

export default {
    namespaced: true,
    state: {
        authenticatedUser: null,
        likedBeats: [],
        boughtBeats: [],
        followedProducers: [],
        followedPlaylists: []
    } as State,
    getters: {
        userActiveDeals: () => ''
    },
    mutations: {
        SET_AUTHENTICATED_USER: (state: State, payload: IUser) => (state.authenticatedUser = payload),
    },
    actions: {
        async fetchOwnProfile({ commit, dispatch }: VuexActionData<State>) {
            const user = await fetchOwnProfile()
            commit('SET_AUTHENTICATED_USER', user)
        },
        resetEverythingOfUser({ commit }: VuexActionData<State>) {
            commit('SET_AUTHENTICATED_USER', null)
        }
    }
}
