import { get, update } from '~/services/http-requests'

const _BS_PRODUCTS = 'products'
const _BS_PRODUCT_PAGE = 'product_page'

export async function fetchProductPage(producerSlug: IProducer['slug']): Promise<IProductPage> {
    const [first] = await get({ fullUrl: `${_BS_PRODUCT_PAGE}.json?slug=${producerSlug}` })
    return first
}

export async function fetchProducts(producerId: IProducer['id']): Promise<IProduct> {
    const [first] = await get({ fullUrl: `${_BS_PRODUCTS}.json?producer=${producerId}` })
    return first
}

export function updateProductPage(producerId: IProducer['id'], productPage: IProductPage): Promise<IProductPage> {
    return update({
        baseProperty: _BS_PRODUCT_PAGE,
        fullUrl: `/api_producer/${_BS_PRODUCT_PAGE}/${producerId}/`,
        dispatchParams: productPage,
        isFreeRequest: true
    })
}
