import { get } from '~/services/http-requests'

const _BS_SERVICES = 'producer_services'

export async function fetchServices(producerId: IProducer['id'], limit?: number): Promise<IService[]> {
    let fullUrl
    if (limit) {
        fullUrl = `${_BS_SERVICES}.json?producer=${producerId}&limit=${limit}`
    } else {
        fullUrl = `${_BS_SERVICES}.json?producer=${producerId}`
    }
    const data = await get({ baseProperty: _BS_SERVICES, fullUrl })

    return data.results ? data.results : data
}

export async function fetchService(producerId: IProducer['id'], serviceId: IService['id']): Promise<IService> {
    const [first] = await get({
        baseProperty: _BS_SERVICES,
        fullUrl: `${_BS_SERVICES}.json?producer=${producerId}&id=${serviceId}`
    })
    return first
}

export async function fetchProducerHasServices(producerSlug: IProducer['slug']): Promise<boolean> {
    const data = await get({ fullUrl: `producer_has_services/${producerSlug}/` })
    return data.has_services
}
