/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'play': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 22.7c5.91 0 10.7-4.79 10.7-10.7 0-5.91-4.79-10.7-10.7-10.7C6.09 1.3 1.3 6.09 1.3 12c0 5.91 4.79 10.7 10.7 10.7zm0 1.3C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12zm3.817-12.34l-5.04-3.568a.523.523 0 00-.507-.048.43.43 0 00-.27.388v7.136c0 .165.105.316.27.389a.524.524 0 00.507-.05l5.04-3.567A.418.418 0 0016 12a.418.418 0 00-.183-.34z" _fill="#FFF"/>'
  }
})
