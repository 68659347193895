import { get } from '~/services/http-requests'
import { UploadType } from '~/store/upload'

function signS3(itemToUpload: IItemToUpload, uploadType: UploadType): Promise<any> {
    return get({
        fullUrl: `/s3/sign_s3/?file_type=${itemToUpload.file.type}&upload_type=${uploadType}&file_name=${encodeURIComponent(itemToUpload.file.name)}`,
        isFreeRequest: true
    })
}

export { signS3 }
