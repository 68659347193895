/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'chart-new': {
    width: 22,
    height: 22,
    viewBox: '0 0 22 22',
    data: '<circle pid="0" cx="11" cy="11" r="4" _fill="#1FA4EE" fill-rule="evenodd"/>'
  }
})
