/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'ServiceHandshake': {
    width: 25,
    height: 25,
    viewBox: '0 0 25 25',
    data: '<defs><path pid="0" d="M7.073 6.809c.073.073.073.147.073.294-.22 2.426-1.397 7.204-1.47 7.425-.074.147-.22.294-.368.294h-2.94c-.221 0-.368-.147-.368-.368V6.368C2 6.147 2.147 6 2.368 6c.661 0 3.896 0 4.705.809zm2.499 4.116c-.074-.514.515-1.543 1.176-2.058 1.03-1.103 1.691-1.47 2.426-1.47.441 0 .956.147 1.544.44.956.368 1.838.736 2.426 1.03.515.956 1.323 3.014 1.911 4.631l.22.956c-.808-.808-1.984-1.69-3.087-2.573-1.029-.808-1.985-1.617-2.5-2.132-.073 0-.22-.073-.293-.073h-.147c-.441.22-.662.367-.882.514-.221.147-.294.22-.515.22-.147 0-.22.074-.294.148-.441.735-.956.882-1.764.588-.147-.073-.221-.147-.221-.22zm14.115-4.19c.22 0 .367.147.294.368v8.086c0 .22-.147.368-.368.368h-2.94c-.147 0-.294-.147-.368-.294-.294-1.25-2.279-6.322-2.867-7.278 0-.074-.073-.22 0-.294 0-.074.147-.22.22-.22.148 0 2.427-.736 6.029-.736zm-7.94 5.734s4.044 3.603 3.97 3.676a.71.71 0 010 1.03.794.794 0 01-.515.22.794.794 0 01-.514-.22l-3.308-3.309c-.074-.073-.294-.441-.294-.441a.355.355 0 00-.515 0c-.074.147-.074.22-.074.368l3.382 3.528a.71.71 0 010 1.03.794.794 0 01-.514.22.794.794 0 01-.515-.22l-3.088-3.088c-.147-.074-.294 0-.367.073a.355.355 0 000 .515l2.205 2.205c.294.294.294.736 0 .956-.22.294-.661.22-.882 0l-1.838-1.838a.355.355 0 00-.514 0 .355.355 0 000 .515l1.764 1.764c-.368.22-.809.22-1.103.22-.44 0-.882-.146-1.323-.44-.22-.147-2.573-1.765-4.41-3.309l-.957-.955c0-.074.074-.074.074-.147.073-.22 1.176-4.779 1.47-7.278h3.308c-.294.22-.661.514-.955.808-.662.662-1.544 1.912-1.324 2.794.074.22.22.588.736.735 1.029.368 1.837.147 2.499-.735.368-.073.515-.22.735-.368.074-.073.22-.22.441-.294.588.515 1.47 1.25 2.426 1.985z" id="svgicon_ServiceHandshake_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon_ServiceHandshake_b" _fill="#fff"><use xlink:href="#svgicon_ServiceHandshake_a"/></mask><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_ServiceHandshake_a"/><g mask="url(#svgicon_ServiceHandshake_b)" _fill="#FFF"><path pid="1" d="M0 0h25v25H0z"/></g></g>'
  }
})
