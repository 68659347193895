import Vue from 'vue'
import { namespace } from 'vuex-class'
import { VuexActionData } from '~/interfaces/interface-utils'
import { IS_ELITEPAGE } from '~/const/environment'

export const EVENT_AUDIO_TOGGLE = 'audioPausedChanged'
export const EVENT_LOAD_TRACK = 'loadTrack'

// *********************
// player bus - plugin
// *********************
const playerBus = new Vue()
// Bind playerBus to Vue.
Vue.playerBus = playerBus
Object.defineProperty(Vue.prototype, 'playerBus', { get: () => playerBus })

// *********************
// store
// *********************

export const PlayerStore = namespace('player')

interface PlayerState {
    playerIsShowing: boolean
    queue: IBeat[]
    isAudioPaused: boolean
    beatPlayingIndex: number
    initialPlaylistRoute: string
    currentPosition: number
}

export interface IQueuePushParams {
    route: string
    beatsToAdd: IBeat[]
}

export default {
    namespaced: true,
    state: {
        playerIsShowing: false,
        queue: [],
        isAudioPaused: true,
        beatPlayingIndex: null,
        initialPlaylistRoute: null,
        // use for progress bar
        currentPosition: null
    } as PlayerState,
    getters: {
        beatPlaying: (state: PlayerState) => state.queue[state.beatPlayingIndex]
    },
    mutations: {
        SHOW_PLAYER: (state: PlayerState) => (state.playerIsShowing = true),
        SET_PLAYLIST: (state: PlayerState, payload: IBeat[]) => (state.queue = [...payload]),
        SET_CURRENT_BEAT_PLAYING: (state: PlayerState, index: number) => (state.beatPlayingIndex = index),
        // TOGGLE_AUDIO_PAUSED_BOOLEAN: state => (state.isAudioPaused = !state.isAudioPaused),
        TOGGLE_PLAY_STATUS: (state: PlayerState) => {
            state.isAudioPaused = !state.isAudioPaused
            Vue.playerBus.$emit(EVENT_AUDIO_TOGGLE, state.isAudioPaused)
        },
        SET_INITIAL_PLAYLIST_ROUTE: (state: PlayerState, route: string) => (state.initialPlaylistRoute = route),
        ADD_BEATS_TO_PLAYLIST: (state: PlayerState, { route, beatsToAdd }: IQueuePushParams) => {
            // if the route passed is the same as the route we initally set up from togglePlayStatus push the fetched beats to the queue array
            if (route === state.initialPlaylistRoute) state.queue.push(...beatsToAdd)
        },
        SET_CURRENT_POSITION: (state: PlayerState, position: number) => (state.currentPosition = position)
    },
    actions: {
        togglePlayStatus({ state, getters, commit }: VuexActionData<PlayerState>, { beats, index, route, doNotPlay }: any) {
            // if the beat clicked is the same as the currently playing beat, set sameBeatPlaying to true
            const sameBeatPlaying = getters.beatPlaying && getters.beatPlaying.id === beats[index].id
            // set the "route" to later compare it when we fetch new beats from the lazy load
            // commit('SET_INITIAL_PLAYLIST_ROUTE', route)
            commit('SET_PLAYLIST', beats)
            commit('SHOW_PLAYER')
            commit('SET_CURRENT_BEAT_PLAYING', index)
            const autoplay = !Boolean(doNotPlay)
            const params: ILoadTrackParams = { beatPlaying: getters.beatPlaying, autoplay }
            if (!sameBeatPlaying) Vue.playerBus.$emit(EVENT_LOAD_TRACK, params)
            if (autoplay && (sameBeatPlaying || state.isAudioPaused)) commit('TOGGLE_PLAY_STATUS')
        },

        playNext({ state, getters, commit }: VuexActionData<PlayerState>) {
            let index
            // if the current beat playing is the ltas in the array, we jump back the first one
            if (state.beatPlayingIndex === state.queue.length - 1) index = 0
            else index = state.beatPlayingIndex + 1 // otherwise we go to the next one

            commit('SET_CURRENT_BEAT_PLAYING', index)
            const params: ILoadTrackParams = { beatPlaying: getters.beatPlaying, autoplay: true }
            Vue.playerBus.$emit(EVENT_LOAD_TRACK, params)
            if (state.isAudioPaused) commit('TOGGLE_PLAY_STATUS')
        },

        playPrevious({ state, getters, commit }: VuexActionData<PlayerState>) {
            // set the new track to play
            let index
            // if the current beat playing is the first in the array, we jump to the last one
            if (state.beatPlayingIndex === 0) index = state.queue.length - 1
            else index = state.beatPlayingIndex - 1 // otherwise we go to the previous one

            commit('SET_CURRENT_BEAT_PLAYING', index)
            const params: ILoadTrackParams = { beatPlaying: getters.beatPlaying, autoplay: true }
            Vue.playerBus.$emit(EVENT_LOAD_TRACK, params)
            // if the audio is stopped, play
            if (state.isAudioPaused) commit('TOGGLE_PLAY_STATUS')
        },

        increasePopularityOfPlayedBeat({}, beatId: IBeat['id']) {
            try {
                Vue.$axios.post(`/api/increase_popularity_of_played_beat/`, {
                    beat_id: beatId,
                    from_elite_page: IS_ELITEPAGE
                })
            } catch (err) {
                console.error(err)
            }
        }
    }
}
