/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'lock': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g _fill="none" fill-rule="evenodd"><path pid="0" d="M8.11 12.51V7.47C8.11 5.56 9.802 4 11.872 4s3.763 1.561 3.763 3.47v5.04H8.11z" _stroke="#FFF" stroke-width="2"/><path pid="1" d="M5.164 11.527h13.745V22H5.164V11.527zm6.872 6.546c.54 0 .982-.442.982-.982v-1.964a.985.985 0 00-.982-.982.985.985 0 00-.981.982v1.964c0 .54.441.982.981.982z" _fill="#FFC700"/></g>'
  }
})
