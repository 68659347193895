/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'ServiceMixing4': {
    width: 25,
    height: 25,
    viewBox: '0 0 25 25',
    data: '<defs><path pid="0" d="M23.633 2H2.367A.367.367 0 002 2.367v21.266c0 .203.164.367.367.367h21.266a.367.367 0 00.367-.367V2.367A.367.367 0 0023.633 2zm-15.4 18.333a.367.367 0 01-.366.367H6.4v1.1a.367.367 0 11-.733 0v-1.1H4.2a.367.367 0 01-.367-.367v-4.4c0-.202.165-.366.367-.366h1.467V4.2a.367.367 0 11.733 0v11.367h1.467c.202 0 .366.164.366.366v4.4zM15.2 15.2a.367.367 0 01-.367.367h-1.466V21.8a.367.367 0 11-.734 0v-6.233h-1.466a.367.367 0 01-.367-.367v-4.4c0-.203.164-.367.367-.367h1.466V4.2a.367.367 0 11.734 0v6.233h1.466c.203 0 .367.164.367.367v4.4zm6.967-5.133a.367.367 0 01-.367.366h-1.467V21.8a.367.367 0 11-.733 0V10.433h-1.467a.367.367 0 01-.366-.366v-4.4c0-.203.164-.367.366-.367H19.6V4.2a.367.367 0 11.733 0v1.1H21.8c.202 0 .367.164.367.367v4.4z" id="svgicon_ServiceMixing4_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon_ServiceMixing4_b" _fill="#fff"><use xlink:href="#svgicon_ServiceMixing4_a"/></mask><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_ServiceMixing4_a"/><g mask="url(#svgicon_ServiceMixing4_b)" _fill="#FFF"><path pid="1" d="M0 0h25v25H0z"/></g></g>'
  }
})
