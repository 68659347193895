/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'ServiceHandMoney': {
    width: 25,
    height: 25,
    viewBox: '0 0 25 25',
    data: '<defs><path pid="0" d="M19.248 8.872a3.289 3.289 0 013.302 3.302 3.289 3.289 0 01-3.302 3.303 3.289 3.289 0 01-3.303-3.303 3.289 3.289 0 013.303-3.302zm.367 4.036V11.44c0-.22-.147-.367-.367-.367s-.367.147-.367.367v1.468c0 .22.147.367.367.367s.367-.147.367-.367zM13.376 3a3.289 3.289 0 013.303 3.303 3.289 3.289 0 01-3.303 3.303 3.289 3.289 0 01-3.303-3.303A3.289 3.289 0 0113.376 3zm.367 4.037V5.569c0-.22-.147-.367-.367-.367s-.367.147-.367.367v1.468c0 .22.147.367.367.367s.367-.147.367-.367zm10.202 11.45c.073.073.073.146 0 .22l-.22.22-5.064 2.348c-2.422 1.028-3.45 1.395-4.404 1.395-.66 0-1.174-.147-1.908-.44l-5.505-1.836c-.147-.073-.294-.22-.22-.44.367-1.248.954-3.596 1.248-5.431.073-.073.073-.147.146-.22.074-.074.22-.074.294-.074.66.147 1.394.294 2.128.367l2.202.22c2.642.294 4.77.514 4.77 1.615 0 1.175-2.054 1.615-3.962 1.762.954.073 2.055-.074 3.082-.294l4.037-.954c.22-.073.514-.073.734-.073 1.1 0 2.128.587 2.642 1.614zM7.211 12.834c.147.073.147.147.147.22-.22 2.422-1.615 7.633-1.688 7.853-.074.22-.22.294-.367.294h-.074s-1.761-.367-2.862-.367c-.22 0-.367-.147-.367-.367v-8.294c0-.146.073-.22.073-.293.074-.074.22-.074.294-.074.66.074 4.037.294 4.844 1.028z" id="svgicon_ServiceHandMoney_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon_ServiceHandMoney_b" _fill="#fff"><use xlink:href="#svgicon_ServiceHandMoney_a"/></mask><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_ServiceHandMoney_a"/><g mask="url(#svgicon_ServiceHandMoney_b)" _fill="#FFF"><path pid="1" d="M0 0h25v25H0z"/></g></g>'
  }
})
