/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'ServiceSpeakers': {
    width: 25,
    height: 25,
    viewBox: '0 0 25 25',
    data: '<defs><path pid="0" d="M13 14.38a1.138 1.138 0 110-2.277 1.138 1.138 0 010 2.276zm0 4.551a1.138 1.138 0 110-2.276 1.138 1.138 0 010 2.276zm3.034-9.103c.228 0 .38.151.38.379v12.138c0 .227-.152.38-.38.38H9.966c-.228 0-.38-.153-.38-.38V10.207c0-.228.152-.38.38-.38h6.068zM13 19.69a1.878 1.878 0 001.897-1.897A1.878 1.878 0 0013 15.897a1.878 1.878 0 00-1.897 1.896c0 1.062.835 1.897 1.897 1.897zm0-4.552a1.878 1.878 0 001.897-1.897A1.878 1.878 0 0013 11.345a1.878 1.878 0 00-1.897 1.896c0 1.062.835 1.897 1.897 1.897zM7.31 13.62H2V3.379C2 3.152 2.152 3 2.38 3H6.93c.228 0 .38.152.38.38v10.24zM4.655 4.897c-.455 0-.758.303-.758.758s.303.759.758.759.759-.304.759-.759-.304-.758-.759-.758zm0 3.034c-.455 0-.758.303-.758.759 0 .455.303.758.758.758s.759-.303.759-.758c0-.456-.304-.759-.759-.759zM2 14.379h5.31v7.966c0 .227-.151.38-.379.38H2.379c-.227 0-.379-.153-.379-.38v-7.966zm22-.758h-5.31V3.379c0-.227.151-.379.379-.379h4.552c.227 0 .379.152.379.38v10.24zm-2.655-8.724c-.455 0-.759.303-.759.758s.304.759.759.759.758-.304.758-.759-.303-.758-.758-.758zm0 3.034c-.455 0-.759.303-.759.759 0 .455.304.758.759.758s.758-.303.758-.758c0-.456-.303-.759-.758-.759zm-2.655 6.448H24v7.966c0 .227-.152.38-.38.38H19.07c-.228 0-.38-.153-.38-.38v-7.966z" id="svgicon_ServiceSpeakers_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon_ServiceSpeakers_b" _fill="#fff"><use xlink:href="#svgicon_ServiceSpeakers_a"/></mask><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_ServiceSpeakers_a"/><g mask="url(#svgicon_ServiceSpeakers_b)" _fill="#FFF"><path pid="1" d="M0 0h25v25H0z"/></g></g>'
  }
})
