export const LocaleComponent = {
    render(h: any) {
        return h('router-view')
    }
}

export function castParam(route: any) {
    const props = { ...route.params }
    props.id = +props.id
    props.producerId = +props.producerId
    props.orderId = +props.orderId
    props.pageIndex = +props.pageIndex
    return props
}
