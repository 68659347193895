/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'arrow-right': {
    width: 16,
    height: 16,
    viewBox: '0 0 13.89 25',
    data: '<path pid="0" class="a" d="M13.31 11.07L4.05.77A2.35 2.35 0 00.78.57a2.24 2.24 0 00-.21 3.2l7.9 8.79-7.88 8.66a2.23 2.23 0 00.19 3.2A2.35 2.35 0 004 24.24l9.3-10.17a2.23 2.23 0 00.01-3z"/>'
  }
})
