export function getTextColorFromBackground(backgroundColor: any): string {
    if (typeof backgroundColor !== 'string') return null
    // https://awik.io/determine-color-bright-dark-using-javascript/
    backgroundColor.substring(1) // strip #
    let newColor: any = ''
    // Variables for red, green, blue values // @ts-ignore
    let r
    let g
    let b
    let hsp

    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    newColor = +('0x' + backgroundColor.slice(1).replace(backgroundColor.length < 5 && /./g, '$&$&'))

    // tslint:disable-next-line: no-bitwise
    r = newColor >> 16
    // tslint:disable-next-line: no-bitwise
    g = (newColor >> 8) & 255
    // tslint:disable-next-line: no-bitwise
    b = newColor & 255

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 127.5) return 'black'
    else return 'white'
}
