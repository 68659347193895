import { get } from '~/services/http-requests'

export const _BS_BEATS = 'beats'
export const _BS_PRIVATE_BEATS = 'private_beats'

export enum BeatFilterType {
    Playlist,
    ArtistType,
    Producer,
    ProducerPopular,
    NewUploads,
    Genres,
    MainInstrument,
    Related,
    Admin
}

/*
 **** GET ****
 */
async function fetchSingleBeat(beatId: IBeat['id']): Promise<IBeat> {
    const [first] = await get({ baseProperty: _BS_BEATS, fullUrl: `${_BS_BEATS}.json?id=${beatId}` })
    return first
}

async function fetchBeatsWithSearch(value: string, producerSlug?: IProducer['slug'], limit = 30): Promise<IBeat[]> {
    const beatsUrl = producerSlug ? `${_BS_BEATS}.json/?producer__slug=${producerSlug}&` : `${_BS_BEATS}.json/?`
    const fields = 'id,name,producer,image_url,artist_type,demo_download_allowed,length,bpm'
    const data = await get({ baseProperty: _BS_BEATS, fullUrl: `${beatsUrl}name__icontains=${value}&fields=${fields}&limit=${limit}` })
    return data.results
}

async function fetchBeats({ filterType, offset = 0, limit = 14, slug, playlistId, bpm, producerId }: IBeatParams): Promise<IBeat[]> {
    let url

    switch (filterType) {
        case BeatFilterType.ArtistType:
            url = `limit=${limit}&offset=${offset}&artist_type__slug=${slug}&ordering=-popularity`
            break
        case BeatFilterType.Producer:
            url = `limit=${limit}&offset=${offset}&producer__slug=${slug}`
            break
        case BeatFilterType.ProducerPopular:
            url = `limit=5&producer__slug=${slug}&ordering=-popularity`
            break
        case BeatFilterType.NewUploads:
            url = `limit=10&ordering=-id&is_shown_in_marketplace_homepage=true`
            break
        case BeatFilterType.Genres:
            url = `limit=${limit}&offset=${offset}&genre__slug=${slug}&ordering=-popularity`
            break
        case BeatFilterType.MainInstrument:
            url = `limit=${limit}&offset=${offset}&main_instrument__slug=${slug}&ordering=-popularity`
            break
        case BeatFilterType.Playlist:
            url = `limit=${limit}&offset=${offset}&playlist__contains=${playlistId}`
            break
        case BeatFilterType.Related:
            url = producerId ? `limit=10&artist_type__slug=${slug}&producer__id=${producerId}` : `limit=10&artist_type__slug=${slug}`
            break
        case BeatFilterType.Admin:
            url = slug ? `limit=${limit}&artist_type__slug=${slug}&ordering=-id` : `limit=${limit}&ordering=-id`
            break
    }

    const data = await get({ baseProperty: _BS_BEATS, fullUrl: `${_BS_BEATS}.json?${url}` })

    return data.results ? data.results : data
}

async function fetchPrivateBeats(producerId: IProducer['id'], password: string, offset: number = 0): Promise<IBeat[]> {
    const fullUrl = `${_BS_PRIVATE_BEATS}.json?producer=${producerId}&password=${password}&limit=${14}&offset=${offset}`
    const data = await get({ baseProperty: _BS_PRIVATE_BEATS, fullUrl })
    return data.results ? data.results : data
}

export { fetchSingleBeat, fetchBeatsWithSearch, fetchBeats, fetchPrivateBeats }
