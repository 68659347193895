/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'ServiceMicrophone3': {
    width: 25,
    height: 25,
    viewBox: '0 0 25 25',
    data: '<defs><path pid="0" d="M18.1 11.533c.22 0 .367.147.367.367v.733c0 3.227-2.347 5.354-5.867 5.5v2.934h1.467c1.246 0 2.126 1.246 2.2 2.42v.146c0 .22-.147.367-.367.367H8.567c-.22 0-.367-.147-.367-.367 0-1.246.953-2.566 2.2-2.566h1.467v-2.934C8.347 17.987 6 15.787 6 12.633V11.9c0-.22.147-.367.367-.367H18.1zm-.367 1.1v-.366h-1.466v.366c0 2.274-1.76 4.034-4.034 4.034-2.273 0-4.033-1.76-4.033-4.034v-.366H6.733v.366c0 2.86 2.127 4.767 5.5 4.767 3.374 0 5.5-1.907 5.5-4.767zM16.267 8.6v2.2H8.2V8.6h1.833c.22 0 .367-.147.367-.367s-.147-.366-.367-.366H8.2V6.4h1.833c.22 0 .367-.147.367-.367s-.147-.366-.367-.366H8.2C8.42 3.613 10.033 2 12.233 2s3.887 1.613 4.034 3.667h-1.834c-.22 0-.366.146-.366.366s.146.367.366.367h1.834v1.467h-1.834c-.22 0-.366.146-.366.366s.146.367.366.367h1.834z" id="svgicon_ServiceMicrophone3_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon_ServiceMicrophone3_b" _fill="#fff"><use xlink:href="#svgicon_ServiceMicrophone3_a"/></mask><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_ServiceMicrophone3_a"/><g mask="url(#svgicon_ServiceMicrophone3_b)" _fill="#FFF"><path pid="1" d="M0 0h25v25H0z"/></g></g>'
  }
})
