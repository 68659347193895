/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'email-help-o': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" id="svgicon_email-help-o_a" d="M20.724 16.172h-1.896c-.228 0-.38-.151-.38-.379 0-.227.152-.38.38-.38h1.896a1.52 1.52 0 001.517-1.516V3.276a1.52 1.52 0 00-1.517-1.517H3.276a1.52 1.52 0 00-1.517 1.517v10.62c0 .835.682 1.518 1.517 1.518h1.896c.228 0 .38.152.38.38 0 .227-.152.378-.38.378H3.276c-1.29 0-2.276-.986-2.276-2.275V3.276C1 1.986 1.986 1 3.276 1h17.448C22.014 1 23 1.986 23 3.276v10.62c0 1.29-.986 2.276-2.276 2.276zm-8.952-6.827c-.53 0-1.138-.152-1.517-.455L3.048 3.58c-.151-.152-.227-.38-.076-.532.152-.151.38-.227.531-.076l7.207 5.31c.531.38 1.67.38 2.2 0l7.207-5.31c.152-.151.38-.075.531.076.152.152.076.38-.076.531l-7.206 5.31c-.456.304-.987.456-1.594.456zm-8.117 4.552c-.076 0-.227 0-.303-.076a.367.367 0 010-.531l4.551-4.552a.367.367 0 01.531 0 .367.367 0 010 .531l-4.551 4.552c0 .076-.152.076-.228.076zm16.69 0c-.076 0-.228 0-.304-.076L15.49 9.269a.367.367 0 010-.531.367.367 0 01.53 0l4.552 4.552a.367.367 0 010 .53c0 .077-.151.077-.227.077zM12 22.24c-3.11 0-5.69-2.579-5.69-5.69 0-3.11 2.58-5.689 5.69-5.689 3.11 0 5.69 2.58 5.69 5.69 0 3.11-2.58 5.69-5.69 5.69zm0-10.62a4.921 4.921 0 00-4.931 4.93c0 2.732 2.2 4.932 4.931 4.932s4.931-2.2 4.931-4.931-2.2-4.931-4.931-4.931zm0 8.345c-.076 0-.228-.076-.303-.152-.076-.076-.076-.152-.076-.228 0-.076.076-.227.076-.227a.367.367 0 01.53 0c.076.075.152.151.152.227 0 .076-.076.228-.151.228 0 .076-.152.152-.228.152zm0-1.518c-.228 0-.38-.151-.38-.379v-.986c0-.152.152-.304.304-.38h.152c.455-.075 1.82-.303 1.82-.986 0-.53-.682-1.062-1.517-1.062-.758 0-1.441.455-1.517.986 0 .228-.228.38-.38.38-.227 0-.379-.228-.379-.38.076-.986 1.063-1.669 2.276-1.669 1.29 0 2.276.835 2.276 1.821 0 1.29-1.517 1.593-2.276 1.745v.607c0 .152-.151.303-.379.303z"/></defs><use xlink:href="#svgicon_email-help-o_a"/>'
  }
})
