/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'check': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" id="svgicon_check_a" d="M8.364 16.045l-4.773-4.772L2 12.864l6.364 6.363L22 5.591 20.409 4z"/></defs><use xlink:href="#svgicon_check_a"/>'
  }
})
