/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'info': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" id="svgicon_info_a" d="M11.633 5.4c1.01 0 1.834.823 1.834 1.833s-.823 1.834-1.834 1.834A1.836 1.836 0 019.8 7.233c0-1.01.823-1.833 1.833-1.833zm3.3 13.2H9.8a.735.735 0 010-1.467h1.1a.367.367 0 00.367-.366V13.1a.367.367 0 00-.367-.367H9.8a.735.735 0 010-1.466h3.667v5.5c0 .202.164.366.366.366h1.1a.735.735 0 010 1.467zM12 1C5.935 1 1 5.935 1 12s4.935 11 11 11 11-4.935 11-11S18.065 1 12 1z"/></defs><use xlink:href="#svgicon_info_a"/>'
  }
})
