/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'error': {
    width: 25,
    height: 25,
    viewBox: '0 0 25 25',
    data: '<defs><path pid="0" d="M18.667 14.333a4.338 4.338 0 00-4.334 4.334A4.338 4.338 0 0018.667 23 4.338 4.338 0 0023 18.667a4.338 4.338 0 00-4.333-4.334zm1.569 5.902a.332.332 0 01-.472 0l-1.097-1.097-1.098 1.097a.332.332 0 01-.472 0 .334.334 0 010-.471l1.098-1.097-1.098-1.098a.334.334 0 01.472-.471l1.097 1.097 1.097-1.097a.334.334 0 01.472.471l-1.098 1.098 1.099 1.097c.13.13.13.341 0 .471zm-6.903.098H4.984a1.319 1.319 0 01-1.317-1.318V6.333h16.666v7a.333.333 0 00.667 0V4.985C21 3.89 20.11 3 19.016 3H4.984C3.89 3 3 3.89 3 4.985v14.03C3 20.11 3.89 21 4.984 21h8.35a.333.333 0 000-.667zm-4-16a.333.333 0 110 .667.333.333 0 010-.667zm-2 0a.333.333 0 110 .667.333.333 0 010-.667zm-2 0a.333.333 0 110 .667.333.333 0 010-.667z" id="svgicon_error_a"/></defs><use xlink:href="#svgicon_error_a"/>'
  }
})
