/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'ServiceMixing3': {
    width: 25,
    height: 25,
    viewBox: '0 0 25 25',
    data: '<defs><path pid="0" d="M6.481 16.667c.225 0 .408.182.408.407v4.889a.407.407 0 01-.408.407h-1.63v1.223a.407.407 0 11-.814 0V22.37h-1.63A.407.407 0 012 21.963v-4.889c0-.225.183-.407.407-.407h1.63V2.407a.407.407 0 11.815 0v14.26h1.63zm7.741-6.519c.225 0 .408.182.408.408v4.888a.407.407 0 01-.408.408h-1.63v7.74a.407.407 0 11-.814 0v-7.74h-1.63a.407.407 0 01-.407-.408v-4.888c0-.226.182-.408.407-.408h1.63v-7.74a.407.407 0 11.815 0v7.74h1.63zm7.741-6.518c.225 0 .407.182.407.407v4.889a.407.407 0 01-.407.407h-1.63v14.26a.407.407 0 11-.814 0V9.333h-1.63a.407.407 0 01-.408-.407V4.037c0-.225.183-.407.408-.407h1.63V2.407a.407.407 0 11.814 0V3.63h1.63z" id="svgicon_ServiceMixing3_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon_ServiceMixing3_b" _fill="#fff"><use xlink:href="#svgicon_ServiceMixing3_a"/></mask><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_ServiceMixing3_a"/><g mask="url(#svgicon_ServiceMixing3_b)" _fill="#FFF"><path pid="1" d="M0 0h25v25H0z"/></g></g>'
  }
})
