/* eslint-disable promise/param-names */
function _resizeImage(settings: { file: any, maxSizePx: number, isTransparent: boolean }): Promise<Blob> {
    // https://stackoverflow.com/questions/23945494/use-html5-to-resize-an-image-before-upload
    const file = settings.file
    const maxSizePx = settings.maxSizePx
    const reader = new FileReader()
    const image = new Image()
    const canvas = document.createElement('canvas')
    const dataURItoBlob = (dataURI: any) => {
        const bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ? atob(dataURI.split(',')[1]) : decodeURI(dataURI.split(',')[1])
        const mime = dataURI
            .split(',')[0]
            .split(':')[1]
            .split(';')[0]
        const max = bytes.length
        const ia = new Uint8Array(max)
        for (let i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i)
        return new Blob([ia], { type: mime })
    }
    const resize = () => {
        let width = image.width
        let height = image.height
        if (width > height) {
            if (width > maxSizePx) {
                height *= maxSizePx / width
                width = maxSizePx
            }
        } else {
            if (height > maxSizePx) {
                width *= maxSizePx / height
                height = maxSizePx
            }
        }
        canvas.width = width
        canvas.height = height
        canvas.getContext('2d').drawImage(image, 0, 0, width, height)
        const dataUrl = canvas.toDataURL(settings.isTransparent ? 'image/png' : 'image/jpeg')
        return dataURItoBlob(dataUrl)
    }
    return new Promise((ok, no) => {
        if (!file.type.match(/image.*/)) {
            no(new Error('Not an image'))
            return
        }
        reader.onload = (readerEvent: Event) => {
            image.onload = () => ok(resize())
            // @ts-ignore
            image.src = readerEvent.target.result
        }
        reader.readAsDataURL(file)
    })
}

function _covertBlobToFile(theBlob: Blob, fileName: string): File {
    const b: any = theBlob
    // A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date()
    b.name = fileName
    // Cast to a File() type
    return theBlob as File
}

export async function cleanFile(file: File, maxSizePx: number): Promise<IItemToUpload> {
    const isTransparent = file.name.endsWith('png')
    file.name.replace(/&/g, '')
    const id = `${new Date().getTime()}_${file.name}`
    const itemToUpload = { file, id, url: '' }

    if (file.type.match(/image.*/)) {
        const blobOfResizedImage: Blob = await _resizeImage({ file: itemToUpload.file, maxSizePx: maxSizePx || 2000, isTransparent })
        itemToUpload.file = _covertBlobToFile(blobOfResizedImage, `r_${id}`)
    }
    return itemToUpload
}
