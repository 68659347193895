import Vue from 'vue'
import { IS_ADMIN, IS_DASHBOARD } from '~/const/environment'

interface IRequestParameters {
    baseProperty?: string
    fullUrl?: string
    dispatchParams?: object
    isFreeRequest?: boolean
}

export interface IApiResponseExtended<T> {
    count: number
    next: string
    previous: string
    results: T
}

type ActualApiResponse<T> = IApiResponseExtended<T> | T

function getFullUrl({ baseProperty, fullUrl, isFreeRequest }: IRequestParameters) {
    const url = fullUrl ?? `${baseProperty}.json`
    if (isFreeRequest) return url

    else if (IS_ADMIN) return `/api_admin/${url}`
    else if (IS_DASHBOARD)  return `/api_producer/${url}`
    else return `/api/${url}`
}

const getSingularOfBaseProperty = (baseProperty: string) => baseProperty.slice(0, -1)

/**
 * generic method to fetch api data
 */
async function get({ baseProperty, fullUrl, isFreeRequest = false }: IRequestParameters): Promise<ActualApiResponse<any>> {
    try {
        const { data } = await Vue.$axios.get(getFullUrl({ baseProperty, fullUrl, isFreeRequest }))
        return data
    } catch (err) {
        console.error(err)
        throw err
    }
}

async function post({ baseProperty, fullUrl, dispatchParams, isFreeRequest = false }: IRequestParameters): Promise<any> {
    try {
        const { data } = await Vue.$axios.post(getFullUrl({ baseProperty, fullUrl, isFreeRequest }), dispatchParams)
        return data
    } catch (err) {
        console.error(err)
        throw err
    }
}

async function update({ baseProperty, fullUrl, dispatchParams, isFreeRequest = false }: IRequestParameters): Promise<any> {
    try {
        const { data } = await Vue.$axios.patch(getFullUrl({ baseProperty, fullUrl, isFreeRequest }), dispatchParams)
        // they are all api requests
        return data[getSingularOfBaseProperty(baseProperty)]
    } catch (err) {
        console.error(err)
        throw err
    }
}

async function remove({ baseProperty, fullUrl, isFreeRequest = false }: IRequestParameters): Promise<any> {
    try {
        const { data } = await Vue.$axios.delete(getFullUrl({ baseProperty, fullUrl, isFreeRequest }))
        // they are all api requests
        return data[getSingularOfBaseProperty(baseProperty)]
    } catch (err) {
        console.error(err)
        throw err
    }
}

export { get, post, update, remove }
