/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'hamburger': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" id="svgicon_hamburger_a" d="M1 3h22v3H1V3zm0 7.5h22v3H1v-3zM1 18h22v3H1v-3z"/></defs><use xlink:href="#svgicon_hamburger_a"/>'
  }
})
