






















import Vue from 'vue'
import { Component, Watch, Mixins } from 'vue-property-decorator'

import debounce from 'lodash.debounce'
import { BeatImageOrProducerLogo } from '~/mixins/beat-image-or-producer-logo'
import { convertToGoodQuotes } from '~/utils/convertToGoodQuotes'
import { analyticsSearch } from '~/services/analytics'
import { fetchBeatsWithSearch } from '~/api/beats'
import { fetchProducersWithSearch } from '@/api/producers'
import { fetchArtistTypesWithSearch } from '@/api/artist-type'

@Component
export default class NavbarSearch extends Mixins(BeatImageOrProducerLogo) {
    searchKeyword: string = ''
    beats: IBeat[] = []

    makeSearchCall = debounce(async (component: NavbarSearch, value: string) => {
        component.beats = await fetchBeatsWithSearch(value, component.$route.params.producerSlug)
    }, 500)

    clearSearch() {
        this.searchKeyword = ''
    }

    goToBeat(beat: IBeat) {
        this.$emit('closeMenu')
        Vue.router.push({ name: 'Beat', params: { id: beat.id } })
        this.clearSearch()
    }

    @Watch('searchKeyword')
    onSearchKeywordChange(newValue: string) {
        if (newValue.length >= 1) this.makeSearchCall(this, convertToGoodQuotes(newValue))
    }
}
