/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'ServiceNote': {
    width: 25,
    height: 25,
    viewBox: '0 0 25 25',
    data: '<defs><path pid="0" d="M17.9 7.004l-4.094 1.227V4.686l4.487-1.344a.685.685 0 00-.394-1.313l-4.974 1.49c-.012.004-.021.01-.032.014a.665.665 0 00-.357.29.618.618 0 00-.076.182.614.614 0 00-.02.136c-.001.012-.004.023-.004.035v13.116c-.685-.62-1.588-1-2.582-1A3.858 3.858 0 006 20.146 3.858 3.858 0 009.854 24a3.86 3.86 0 003.835-3.47.68.68 0 00.117-.384V9.661l4.486-1.344a.685.685 0 10-.393-1.313z" id="svgicon_ServiceNote_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon_ServiceNote_b" _fill="#fff"><use xlink:href="#svgicon_ServiceNote_a"/></mask><use _fill="#231F20" fill-rule="nonzero" xlink:href="#svgicon_ServiceNote_a"/><g mask="url(#svgicon_ServiceNote_b)" _fill="#FFF"><path pid="1" d="M0 0h25v25H0z"/></g></g>'
  }
})
