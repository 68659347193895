/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'search': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" id="svgicon_search_a" d="M15.356 4.288c-3.05-3.05-8.017-3.05-11.068 0-3.05 3.052-3.05 8.017 0 11.069 2.717 2.716 6.949 3.007 9.998.886.064.303.211.593.447.83l4.444 4.442a1.652 1.652 0 002.338 0 1.65 1.65 0 000-2.338l-4.444-4.444a1.653 1.653 0 00-.828-.446c2.122-3.05 1.831-7.28-.887-9.999zm-1.403 9.666a5.85 5.85 0 01-8.262 0 5.851 5.851 0 010-8.262 5.85 5.85 0 018.262 0 5.849 5.849 0 010 8.262z"/></defs><use xlink:href="#svgicon_search_a"/>'
  }
})
