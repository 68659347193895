/**
 * import and init global plugins
 */

import Vue from 'vue'

import './axios'
import './sentry'
import './vue-tooltip'
import './vee-validate'
import './vue-lazy-load'
// @ts-ignore
import ToggleButton from 'vue-js-toggle-button'

Vue.use(ToggleButton)
