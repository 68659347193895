/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'ServiceHeadphones': {
    width: 25,
    height: 25,
    viewBox: '0 0 25 25',
    data: '<defs><path pid="0" d="M19.703 11.287V8.996C19.703 5.303 16.88 2 13.186 2h-.426C9.067 2 6.208 5.303 6.208 8.996v2.29c-2.13.25-3.729 1.794-4.084 3.783-.053.266-.124.55-.124.834 0 .284.018.568.071.835.373 2.042 1.722 3.373 4.581 3.373H8.96c.32 0 .462 0 .462-.32v-7.705c0-.32-.142-.853-.462-.853H7.274v.054c0-3.037 2.432-6.09 5.486-6.09h.426c3.055 0 5.452 3.053 5.452 6.09v-.054h-1.634c-.32 0-.497.533-.497.853v7.67c0 .32.177.355.497.355h1.51c-.48 2.486-1.74 2.486-3.232 2.486h-.817c.053 0 .089-.124.089-.32 0-.923-.764-1.58-1.687-1.58s-1.687.8-1.687 1.723.764 1.58 1.687 1.58c.018 0 0 0 0 0h2.486c2.201 0 3.942-1.722 4.403-3.8 2.362-.23 4.12-2.06 4.12-4.474.017-2.362-1.687-4.155-4.173-4.44zM5.036 17.288l-.39.39a.593.593 0 01-.409.16.626.626 0 01-.408-.16.602.602 0 010-.816l.39-.39c.231-.231.586-.231.817 0 .231.23.231.603 0 .816zm0-2.308l-.39.39a.612.612 0 01-.409.178.567.567 0 01-.408-.178.602.602 0 010-.816l.39-.391a.602.602 0 01.817 0c.231.23.231.604 0 .817zm3.303-2.681v6.747H7.274v-6.747h1.065zm4.847-8.168h-.426c-2.077 0-3.942.994-5.167 2.486.781-2.096 2.806-3.552 5.167-3.552h.426c2.38 0 4.386 1.456 5.168 3.552-1.226-1.492-3.072-2.486-5.168-2.486zm-.301 18.892a.534.534 0 01-.533-.532c0-.284.23-.533.533-.533.284 0 .532.23.532.533-.017.284-.248.532-.532.532zm5.753-3.977h-1.066v-6.747h1.066v6.747zm3.657-1.758l-.39.39a.626.626 0 01-.409.16.568.568 0 01-.408-.977l.39-.39a.568.568 0 01.817 0c.231.231.231.604 0 .817zm0-2.308l-.39.39a.65.65 0 01-.409.178.54.54 0 01-.408-.178.568.568 0 010-.816l.39-.391c.214-.213.587-.213.817 0 .231.23.231.604 0 .817z" id="svgicon_ServiceHeadphones_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon_ServiceHeadphones_b" _fill="#fff"><use xlink:href="#svgicon_ServiceHeadphones_a"/></mask><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_ServiceHeadphones_a"/><g mask="url(#svgicon_ServiceHeadphones_b)" _fill="#FFF"><path pid="1" d="M0 0h25v25H0z"/></g></g>'
  }
})
