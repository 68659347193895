/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'chart-same': {
    width: 22,
    height: 22,
    viewBox: '0 0 22 22',
    data: '<path pid="0" d="M5 10h12v2H5z" _fill="#909090" fill-rule="evenodd"/>'
  }
})
