/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'ServiceMixing1': {
    width: 25,
    height: 25,
    viewBox: '0 0 25 25',
    data: '<defs><path pid="0" d="M5.143 17.746A2.752 2.752 0 017.5 20.464a2.753 2.753 0 01-2.75 2.75A2.753 2.753 0 012 20.464a2.752 2.752 0 012.357-2.718V2.393a.393.393 0 11.786 0v15.353zm8.25-8.009a2.834 2.834 0 012.357 2.789c0 1.4-1.021 2.562-2.357 2.788v7.507a.393.393 0 11-.786 0V15.34a2.835 2.835 0 01-2.52-2.813 2.835 2.835 0 012.52-2.813v-7.32a.393.393 0 11.786 0v7.344zM24 4.75a2.752 2.752 0 01-2.357 2.719V22.82a.393.393 0 11-.786 0V7.47A2.752 2.752 0 0118.5 4.75 2.753 2.753 0 0121.25 2 2.753 2.753 0 0124 4.75z" id="svgicon_ServiceMixing1_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon_ServiceMixing1_b" _fill="#fff"><use xlink:href="#svgicon_ServiceMixing1_a"/></mask><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_ServiceMixing1_a"/><g mask="url(#svgicon_ServiceMixing1_b)" _fill="#FFF"><path pid="1" d="M0 0h25v25H0z"/></g></g>'
  }
})
