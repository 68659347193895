









import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'

import ThePlayer from '~/components/ThePlayer.vue'
import TheBackToTop from '~/components/TheBackToTop.vue'
import { UserStore } from '@/store/user'

@Component({ components: { ThePlayer, TheBackToTop } })
export default class TheFixedBottomElements extends Vue {
    showDealAlertBar = true

    @UserStore.Getter('userActiveDeals') userActiveDeals: string

    @Watch('userActiveDeals')
    onUserActiveDealsChange(newValue: string) {
        if (newValue) this.showDealAlertBar = true
    }
}
