import { get } from '~/services/http-requests'

const _BS_ORDERS = 'orders'

interface IOrderParams {
    email: string
    orderId: IBoughtBeat['id']
}

/*
 **** GET ****
 */
function fetchSingleOrder({ email, orderId }: IOrderParams): Promise<IOrderDataSimple> {
    return get({ fullUrl: `fetch_order_data/${email}/${orderId}/` })
}

async function fetchBoughtBeats(): Promise<IBoughtBeat[]> {
    const responseData: IOrderDataComplex[] = await get({ baseProperty: _BS_ORDERS })
    // removes empty array
    return responseData.map(item => item.bought_beats).flat()
}

export { fetchSingleOrder, fetchBoughtBeats }
