




















import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { UserStore } from '@elitepage/store/user'
import { AuthStore } from '~/store/auth'

@Component
export default class NavbarUser extends Vue {
    @UserStore.State('authenticatedUser') authenticatedUser: IUser

    @AuthStore.Action('logout') logout: () => void

    closeMenu() {
        this.$emit('closeMenu')
    }
}
