import { namespace } from 'vuex-class'
import { fetchContractPdf } from '~/api/pdf'
import { BASE_URL } from '~/const/base-url'
import { VuexActionData } from '~/interfaces/interface-utils'
import { analyticsDemoDownload } from '~/services/analytics'
import { AudioFileType } from '~/const/audio-file-type'

export const BeatStore = namespace('beat')

export default {
    namespaced: true,
    actions: {
        demoDownloadBeat({ dispatch }: VuexActionData<any>, beat: IBeat) {
            dispatch('forceDemoDownloadBeat', beat)

            // // if the producer has a youtube channel to be subscribed to
            // if (beat.producer.require_youtube_follow_for_download && beat.producer.youtube_channel_id) {
            //     commit('modal/SET_ACTIVE_MODAL', { modal: ModalType.DemoDownload, payload: beat }, { root: true })
            // } else dispatch('forceDemoDownloadBeat', beat) // just download the demo
        },

        forceDemoDownloadBeat({ dispatch }: VuexActionData<any>, beat: IBeat) {
            window.open(`${BASE_URL}/s3/beat_demo_download/${beat.id}/`)
            analyticsDemoDownload(beat)
        },

        downloadFileType({ rootState }: VuexActionData<any>, { beatId, fileType }: { beatId: IBeat['id']; fileType: AudioFileType }) {
            const userEmail = rootState.user.authenticatedUser.email
            const url = `${BASE_URL}/s3/beat_download/${userEmail}/${beatId}/${fileType}/`
            window.open(url)
        },

        async openPdfContact({ rootState }: VuexActionData<any>, { beatId, email = null }: { beatId: number; email?: string }) {
            const userEmail = email ? email : rootState.user.authenticatedUser.email
            // https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743
            const pdfFile = await fetchContractPdf(userEmail, beatId)
            const url = window.URL.createObjectURL(new Blob([pdfFile]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `license-contract-${beatId}.pdf`)
            document.body.appendChild(link)
            link.click()
        }
    }
}
