import { BASE_URL } from '~/const/base-url'
import Vue from 'vue'
import { analyticsSignupStarted, analyticsSignupCompleted, analyticsSignupFull } from '~/services/analytics'
import { post } from '~/services/http-requests'

function signupProducer(signupParams: ISignupParams) {
	return post({ fullUrl: `api_producer/add_new_producer/`, dispatchParams: signupParams, isFreeRequest: true })
}

// method
async function _postUnauthenticated({ url, dispatchParams = {} }: { url: string; dispatchParams?: object }): Promise<any> {
	try {
		const { data } = await Vue.$axios({
			method: 'post',
			headers: { Authorization: '' },
			baseURL: BASE_URL,
			url: `/rest-auth/${url}`,
			data: dispatchParams
		})
		return data
	} catch (err) {
		console.error(err)
		throw err
	}
}

async function facebookLogin(accessToken: string): Promise<any> {
	try {
		const key = await _postUnauthenticated({
			url: `facebook/`,
			dispatchParams: { access_token: accessToken, add_free_beats_to_account: false }
		})
		analyticsSignupFull()
		return key
	} catch (err) {
		throw err
	}
}

async function googleLogin(accessToken: string): Promise<any> {
	try {
		const key = await _postUnauthenticated({
			url: `google/`,
			dispatchParams: { access_token: accessToken, add_free_beats_to_account: false }
		})
		analyticsSignupFull()
		return key
	} catch (err) {
		throw err
	}
}

async function signup(dispatchParams: ISignupParams): Promise<any> {
	try {
		const token = await _postUnauthenticated({ url: `registration/`, dispatchParams })
		analyticsSignupFull()
		return token
	} catch (err) {
		throw err
	}
}

async function verifyEmail(key: string): Promise<any> {
	try {
		await Vue.$axios.post(`/rest-auth/registration/verify-email/`, { key })
	} catch (err) {
		throw err
	}
}

function login(dispatchParams: ILoginParams): Promise<any> {
	return _postUnauthenticated({ url: `login/`, dispatchParams })
}

const logoutApi = async (): Promise<any> => {
	try {
		await Vue.$axios.post(`/rest-auth/logout/`)
		return null
	} catch (err) {
		console.error(err)
	}
}

function forgotPassword(email: string): Promise<any> {
	return Vue.$axios.post(`/rest-auth/password/reset/`, { email })
}

function resetPasswordConfirm(dispatchParams: IResetPasswordConfirmParams): Promise<any> {
	return Vue.$axios.post(`/rest-auth/password/reset/confirm/`, dispatchParams)
}

function changePassword(dispatchParams: IResetPasswordChangeParams): Promise<any> {
	return Vue.$axios.post(`/rest-auth/password/change/`, dispatchParams)
}

export { login, facebookLogin, googleLogin, signup, signupProducer, logoutApi, forgotPassword, resetPasswordConfirm, changePassword, verifyEmail }
