/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'settings': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M23.92 10.68c-.16-1.12-1.36-1.92-2.48-1.52-.48.16-1.12-.08-1.36-.56-.24-.56-.56-1.04-.88-1.52-.32-.48-.24-1.12.16-1.44.4-.4.64-.96.64-1.52s-.32-1.12-.72-1.44c-.8-.64-1.68-1.12-2.56-1.52-.32-.08-.56-.16-.8-.16-.88 0-1.68.64-1.84 1.52-.08.56-.56.96-1.2.88-.56-.08-1.2-.08-1.76 0s-1.12-.32-1.2-.88C9.76 1.64 8.96 1 8.08 1c-.24 0-.48.08-.72.16-.96.4-1.84.88-2.64 1.52C4.32 3 4 3.56 4 4.12c0 .56.24 1.12.64 1.44.48.4.56.96.16 1.44-.32.48-.64 1.04-.88 1.52s-.8.8-1.36.56C2.32 9 2.16 9 1.92 9 .96 9 .16 9.72.08 10.68 0 11.16 0 11.72 0 12.2s0 1.04.08 1.52c.16 1.12 1.36 1.92 2.48 1.52.48-.16 1.12.08 1.36.64s.56 1.04.88 1.52c.32.48.24 1.12-.16 1.44-.4.32-.64.88-.64 1.44 0 .56.32 1.12.72 1.44.8.64 1.68 1.12 2.56 1.52.24.08.48.16.72.16.88 0 1.68-.64 1.84-1.52.08-.56.56-.96 1.2-.88.56.08 1.2.08 1.76 0s1.12.32 1.2.88c.16.88.96 1.52 1.84 1.52.24 0 .48-.08.72-.16.88-.4 1.76-.88 2.56-1.52.48-.32.72-.88.72-1.44 0-.56-.24-1.12-.64-1.44-.4-.32-.48-.96-.16-1.44.32-.48.64-1.04.88-1.52s.8-.8 1.36-.56c.24.08.4.08.64.08.96 0 1.76-.72 1.84-1.68.24-.48.24-1.04.24-1.52s0-1.04-.08-1.52zM16 12.2c0 2.24-1.76 4-4 4s-4-1.76-4-4 1.76-4 4-4 4 1.76 4 4z" id="svgicon_settings_a"/></defs><use xlink:href="#svgicon_settings_a"/>'
  }
})
