/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'cart': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" id="svgicon_cart_a" d="M10.622 18.044a2.2 2.2 0 11-3.111 3.112 2.2 2.2 0 013.111-3.112zm9.534 0a2.2 2.2 0 11-3.112 3.112 2.2 2.2 0 013.112-3.112zm2.77-12.964c.074.073.074.22.074.293l-1.907 7.04a.315.315 0 01-.293.294l-13.86 1.76a2.402 2.402 0 002.2 1.466H19.7c.22 0 .367.147.367.367s-.147.367-.367.367H9.14c-1.54 0-2.787-1.1-3.08-2.567L3.787 2.733h-2.42c-.22 0-.367-.146-.367-.366S1.147 2 1.367 2H4.08c.147 0 .367.147.367.293l.586 2.64h17.6c.147 0 .22.074.294.147z"/></defs><use xlink:href="#svgicon_cart_a"/>'
  }
})
