/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'timer': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<g stroke-width=".4" _stroke="#A0A0A0" _fill="#A0A0A0" fill-rule="evenodd"><path pid="0" d="M7 13.767A6.772 6.772 0 01.233 7 6.772 6.772 0 017 .233 6.772 6.772 0 0113.767 7 6.772 6.772 0 017 13.767zM7 .7C3.547.7.7 3.547.7 7s2.847 6.3 6.3 6.3 6.3-2.847 6.3-6.3S10.453.7 7 .7z"/><path pid="1" d="M10.267 7.233H7c-.14 0-.233-.093-.233-.233V2.333c0-.14.093-.233.233-.233s.233.093.233.233v4.434h3.034c.14 0 .233.093.233.233s-.093.233-.233.233z"/></g>'
  }
})
