import { ENV_IS_DEVELOPMENT, ENV_IS_PRODUCTION, ENV_IS_STAGING } from './environment'

const forceUseLocalhost = false

let baseUrl: string
if (ENV_IS_PRODUCTION) {
	baseUrl = 'https://api.thecharts.com'
} else if (forceUseLocalhost) {
	baseUrl = 'http://localhost:8000'
} else if (ENV_IS_STAGING || ENV_IS_DEVELOPMENT) {
	baseUrl = 'https://test-api.thecharts.com'
} else {
	baseUrl = 'https://api.thecharts.com'
}
export const BASE_URL = baseUrl
