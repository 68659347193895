/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'eye': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.004 5c5.967 0 10.694 6.117 10.886 6.373.15.214.15.492-.021.706-.193.235-4.898 5.924-10.865 5.924-5.967 0-10.672-5.689-10.864-5.924a.55.55 0 01-.022-.706C1.311 11.095 6.038 5 12.004 5zm0 1.176c-4.62 0-8.576 4.256-9.667 5.54 1.09 1.197 5.069 5.154 9.667 5.154s8.576-3.957 9.667-5.133c-1.09-1.305-5.047-5.56-9.667-5.56zm-.021.214c2.823 0 5.111 2.289 5.111 5.09a5.102 5.102 0 01-5.09 5.09 5.102 5.102 0 01-5.09-5.09c0-2.801 2.267-5.09 5.069-5.09zm-.214 3.465c-.813 0-1.497.684-1.497 1.497 0 .813.663 1.497 1.497 1.497.834 0 1.497-.684 1.497-1.497 0-.813-.684-1.497-1.497-1.497z" _fill="#000" fill-rule="nonzero"/>'
  }
})
