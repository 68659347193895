import Vue from 'vue'

import { logoutApi } from '~/api/auth'
import { namespace } from 'vuex-class'
import { VuexActionData } from '~/interfaces/interface-utils'
import { ENV_IS_DEVELOPMENT, ENV_IS_STAGING } from '~/const/environment'

export const AuthStore = namespace('auth')

export interface IAuthState {
	token_id: string
}

export interface IOnLoginSuccess {
	key: string
	doRedirect?: boolean
	redirectToProducer?: boolean
}

export default {
	namespaced: true,
	state: { token_id: null } as IAuthState,
	getters: { isAuthenticated: (state: IAuthState) => state.token_id !== null },
	mutations: {
		LOGIN: (state: IAuthState, token: IAuthState['token_id']) => {
			// token gets set in state and local storage
			state.token_id = token
			// sets axios to use token by default for every call
			Vue.$axios.defaults.headers.common.Authorization = `Token ${token}`
		},

		LOGOUT: (state: IAuthState) => {
			state.token_id = null
			Vue.$axios.defaults.headers.common.Authorization = ''
		}
	},
	actions: {
		onLoginSuccess({ commit, dispatch }: VuexActionData<IAuthState>, { key, doRedirect = true, redirectToProducer = false }: IOnLoginSuccess) {
			commit('LOGIN', key)
			if (redirectToProducer) {
				window.location.href = ENV_IS_DEVELOPMENT || ENV_IS_STAGING ? 'https://test-producer.thecharts.com' : 'https://producer.thecharts.com'
			} else {
				// fetch user info
				dispatch('user/fetchOwnProfile', null, { root: true })
				// post the cached cart items to the cart, if there are any
				dispatch('cart/createCartAfterLogin', null, { root: true })
				if (doRedirect) {
					// notification
					dispatch('modal/showNotification', { name: 'success', title: 'Login Successful', content: 'Welcome back to TheCharts' }, { root: true })
					// redirect
					Vue.router.push({ path: Vue.router.history.current.query.redirect || '/' })
				}
			}
		},

		async logout({ commit }: VuexActionData<IAuthState>, { redirectToLogin = false } = {}) {
			await logoutApi()
			commit('LOGOUT')
			if (redirectToLogin) setTimeout(() => Vue.router.push({ name: 'Login' }), 1000)
			else setTimeout(() => (window.location.href = ENV_IS_DEVELOPMENT || ENV_IS_STAGING ? 'https://test.thecharts.com' : 'https://thecharts.com'), 1000)
		}
	}
}
