
























import { Component, Mixins } from 'vue-property-decorator'

import { AuthStore } from '~/store/auth'
import { DynamicClassesOnShow } from '~/mixins/dynamic-classes-on-show'
import { ElitepageStore } from '@elitepage/store/elitepage'

@Component
export default class Footer extends Mixins(DynamicClassesOnShow) {
    @ElitepageStore.State('elitepage') elitepage: IElitepage
    @ElitepageStore.State('producer') producer: IProducer
}
