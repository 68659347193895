/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'ServiceMusicFolder': {
    width: 25,
    height: 25,
    viewBox: '0 0 25 25',
    data: '<defs><path pid="0" d="M22.925 4.343c.593 0 1.075.478 1.075 1.067v1.825c-.476-.125-1.037-.07-1.503-.07H2.736c-.283 0-.527.018-.736.062v-.648l.001-.01V4.068c0-.59.483-1.068 1.076-1.068h5.19a.62.62 0 01.427.17l1.23 1.173h13zM11.999 12.9v-.96l4.572-.783v.96l-4.572.783zm11.392-4.702c.587 0 .5.247.5.668v12.159c-.188.767-1.377.519-1.893.519H2.593c-.82 0-.523-1.883-.523-2.287v-4.694-4.507-1.19c0-.453-.11-.668.51-.668h20.811zm-5.787 8.794v-6.448c0-.02-.003-.039-.006-.058 0-.01 0-.02-.002-.03l-.001-.005a.538.538 0 00-.02-.07l-.009-.03c-.008-.02-.018-.038-.029-.056-.006-.012-.011-.023-.018-.034-.01-.015-.022-.029-.033-.042-.01-.012-.019-.025-.03-.036-.012-.012-.025-.022-.038-.033-.013-.01-.024-.022-.037-.03-.014-.01-.03-.018-.045-.026-.014-.008-.027-.017-.042-.023-.015-.007-.032-.011-.049-.017l-.046-.014c-.016-.003-.033-.004-.05-.006-.018-.002-.035-.005-.053-.006h-.01c-.015 0-.03.003-.045.004-.014.001-.028 0-.042.003l-5.604.96-.008.002c-.02.004-.039.01-.058.016l-.041.013c-.017.007-.032.016-.048.024-.014.008-.029.014-.042.023-.013.009-.025.02-.037.03-.014.01-.028.02-.04.032l-.032.038c-.01.012-.022.024-.032.038-.01.014-.017.03-.025.044l-.023.043c-.006.014-.01.03-.015.046-.006.016-.012.033-.016.05-.003.014-.004.03-.006.045l-.006.057v4.807a1.822 1.822 0 00-.802-.187 1.837 1.837 0 00-1.835 1.835c0 1.013.823 1.836 1.835 1.836a1.838 1.838 0 001.835-1.836v-4.003l4.572-.782v2.178a1.82 1.82 0 00-.802-.188 1.838 1.838 0 00-1.836 1.836c0 1.012.823 1.835 1.836 1.835a1.838 1.838 0 001.835-1.835z" id="svgicon_ServiceMusicFolder_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon_ServiceMusicFolder_b" _fill="#fff"><use xlink:href="#svgicon_ServiceMusicFolder_a"/></mask><use _fill="#231F20" fill-rule="nonzero" xlink:href="#svgicon_ServiceMusicFolder_a"/><g mask="url(#svgicon_ServiceMusicFolder_b)" _fill="#FFF"><path pid="1" d="M0 0h25v25H0z"/></g></g>'
  }
})
