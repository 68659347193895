/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'visa': {
    width: 87,
    height: 56,
    viewBox: '0 0 87 56',
    data: '<path pid="0" _fill="#F1F2F2" d="M1 2.729v50.423h85V2.729z"/><path pid="1" _fill="#F1F2F2" d="M1 14.01h85v27.979H1z"/><path pid="2" d="M1 41.988v10.646c0 .813.45 1.528 1.135 1.954.415.262.917.412 1.458.412h79.814a2.74 2.74 0 001.458-.41C85.55 54.164 86 53.448 86 52.636V41.988H1z" _fill="#FAA61A"/><path pid="3" d="M84.865 1.41A2.752 2.752 0 0083.407 1H3.593c-.541 0-1.042.151-1.457.41C1.451 1.835 1 2.552 1 3.365V14.01h85V3.365c0-.813-.45-1.53-1.135-1.955zM36.924 17.067l-3.438 21.747h5.437l3.516-21.747zM58.43 22.264l.797-4.555s-2.396-.959-5.272-.959c-2.88 0-9.114 1.839-9.114 7.192 0 5.358 7.754 5.596 7.754 8.553 0 2.959-6.953 2.639-9.033.723l-.88 4.716s3.117 1.198 5.917 1.198c2.797 0 9.756-.639 9.756-7.592 0-6.08-7.756-4.878-7.756-8.556-.003.001.794-3.676 7.831-.72zM58.027 38.814h5.994l1.041-3.119h7.354l.613 3.09h5.195L73.457 17.31H68.66s-1.76.2-2.557 2.317c-.759 2.014-8.076 19.187-8.076 19.187z" _fill="#191E62"/><path pid="4" _fill="#F1F2F2" d="M71.538 31.219h-4.877l3.039-7.994z"/><path pid="5" d="M28.908 17.148l-5.64 14.709-2.318-11.511s-.232-3.191-2.506-3.191H9.157v.443s2.792.471 6.017 2.512l-.095-.004 4.958 18.708h5.754l8.953-21.667-5.836.001z" _fill="#191E62"/>'
  }
})
