/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'ServiceMixing5': {
    width: 25,
    height: 25,
    viewBox: '0 0 25 25',
    data: '<defs><path pid="0" d="M4.567 3.367V16.2H6.4v5.133H4.567v1.1a.366.366 0 11-.734 0v-1.1H2V16.2h1.833V3.367a.366.366 0 11.734 0zm0 16.5a.366.366 0 100-.734h-.734a.366.366 0 100 .734h.734zm.366-1.834a.366.366 0 00-.366-.366h-.734a.366.366 0 100 .733h.734a.366.366 0 00.366-.367zm5.5-14.666v9.166h1.834v5.134h-1.834v4.766a.366.366 0 11-.733 0v-4.766H7.867v-5.134H9.7V3.367a.366.366 0 11.733 0zm0 12.833a.366.366 0 100-.733H9.7a.366.366 0 100 .733h.733zm.367-1.833a.366.366 0 00-.367-.367H9.7a.366.366 0 100 .733h.733a.366.366 0 00.367-.366zm5.5-11v5.5h1.833V14H16.3v8.433a.366.366 0 11-.733 0V14h-1.834V8.867h1.834v-5.5a.366.366 0 11.733 0zm0 9.166a.366.366 0 100-.733h-.733a.366.366 0 100 .733h.733zm.367-1.833a.366.366 0 00-.367-.367h-.733a.366.366 0 100 .734h.733a.366.366 0 00.367-.367zM24 4.467V9.6h-1.833v12.833a.366.366 0 11-.734 0V9.6H19.6V4.467h1.833v-1.1a.366.366 0 11.734 0v1.1H24zm-1.833 3.666a.366.366 0 100-.733h-.734a.366.366 0 100 .733h.734zm0-1.466a.366.366 0 100-.734h-.734a.366.366 0 100 .734h.734z" id="svgicon_ServiceMixing5_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon_ServiceMixing5_b" _fill="#fff"><use xlink:href="#svgicon_ServiceMixing5_a"/></mask><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_ServiceMixing5_a"/><g mask="url(#svgicon_ServiceMixing5_b)" _fill="#FFF"><path pid="1" d="M0 0h25v25H0z"/></g></g>'
  }
})
