/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'license': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" id="svgicon_license_a" d="M20.556 1c.222 0 .37.147.37.367v21.266c0 .22-.148.367-.37.367H3.37c-.222 0-.37-.147-.37-.367V7.087c0-.074.074-.147.074-.147L9 1.073c.074 0 .074-.073.148-.073h11.408zM14 9.145c.244 0 .407-.163.407-.408 0-.244-.163-.407-.407-.407h-1.63c-.244 0-.407.163-.407.407v5.215c-.326-.163-.733-.326-1.222-.326-1.141 0-2.037.734-2.037 1.63 0 .896.896 1.63 2.037 1.63 1.14 0 2.037-.734 2.037-1.63V9.145H14zM8.704 7V2.2L4.222 7h4.482z"/></defs><use xlink:href="#svgicon_license_a"/>'
  }
})
