/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'negotiation': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.915 18.822c.074.073.074.147 0 .22l-.22.22-5.057 2.345C15.22 22.634 14.193 23 13.24 23c-.66 0-1.172-.147-1.905-.44l-5.497-1.832c-.147-.073-.294-.22-.22-.44.366-1.246.952-3.591 1.246-5.424 0-.073.073-.146.146-.22.074-.073.22-.073.293-.073.66.147 1.393.293 2.126.366l2.199.22c2.638.294 4.764.513 4.764 1.613 0 1.173-2.052 1.612-3.958 1.759.953.073 2.052-.073 3.079-.293l4.03-.953c.22-.073.514-.073.734-.073 1.1 0 2.125.586 2.638 1.612zM6.204 13.178c.147.074.147.147.147.22-.22 2.419-1.613 7.623-1.686 7.843-.074.22-.22.293-.367.293h-.073s-1.76-.366-2.859-.366c-.22 0-.366-.147-.366-.367V12.52c0-.147 0-.22.073-.293.074-.074.22-.074.293-.074.66.074 4.032.293 4.838 1.026zm12.02-6.303c.22 0 .367.147.367.366 0 .22-.147.367-.367.367h-7.33c-.219 0-.366-.147-.366-.367s.147-.366.367-.366h7.33zm-7.622-.953l-2.2-4.398c-.072-.146 0-.366.074-.44.073-.073.293-.073.367 0l3.445 2.053 1.978-1.98a.354.354 0 01.514 0l1.979 1.98 3.444-2.052c.147-.074.294-.147.44 0 .147.146.147.293.073.44l-2.198 4.397c0 .147-.147.22-.294.22h-7.33c-.146 0-.292-.073-.292-.22zm3.591 7.623c-.513-.147-.733-.367-.733-.733 0-.367.293-.66.733-.806v1.539zm-5.937.44c-.073 0-.146-.074-.22-.367a.223.223 0 010-.293c1.027-2.345 2.566-4.984 2.566-4.984.073-.073.146-.147.293-.147h7.33c.146 0 .22.074.293.147.146.22 3.738 4.91 3.738 7.55v.366c0 .22-.22.44-.44.366h-.073c-.807-.366-1.613-.44-2.346-.22l-1.832.44h-.074c-.073 0-.146 0-.22-.073-.073-.073-.146-.22-.146-.293 0-.587-.293-.953-.733-1.246v-.147c0-.733-.513-1.173-1.466-1.466v-1.686c.44.074.733.367.733.733 0 .22.147.367.367.367s.366-.147.366-.367c-.073-.66-.66-1.246-1.466-1.392v-.367c0-.22-.146-.366-.366-.366s-.367.146-.367.366v.367c-.806.146-1.466.806-1.466 1.539 0 .733.513 1.173 1.466 1.466v.44c-.733-.147-1.539-.22-2.492-.294-.733-.073-1.466-.146-2.125-.22l-1.32-.22zm6.67.88v-.367c.367.146.587.366.66.586-.147-.073-.44-.147-.66-.22z"/>'
  }
})
