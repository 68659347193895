/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'ServicePencil3': {
    width: 25,
    height: 25,
    viewBox: '0 0 25 25',
    data: '<defs><path pid="0" d="M12.532 14.075c.496 0 .9.405.9.9 0 .497-.404.901-.9.901a.902.902 0 01-.9-.9c0-.496.404-.9.9-.9zm2.5-5.128c1.82.92 3.345 2.812 3.345 5 0 2.848-5.128 9.961-5.128 9.961v-6.874c.919-.386 1.599-1.084 1.599-2.058 0-1.195-1.103-2.15-2.297-2.15a2.173 2.173 0 00-2.17 2.168c0 .974.644 1.801 1.692 2.077V24C10.84 22.511 7 16.74 7 14.112c0-2.187 1.397-3.988 3.216-4.98l.11.018c-.33-.074-.918-.331-.973-.57L8.084 3.25c-.036-.184-.018-.662.074-.827.11-.202.35-.423.551-.423h7.628c.183 0 .478.184.625.423.147.275.055.79 0 .974l-1.25 5.073c-.074.257-.423.44-.68.477zm-.478-1.8l.956-3.677H9.518l.956 3.676h4.08z" id="svgicon_ServicePencil3_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon_ServicePencil3_b" _fill="#fff"><use xlink:href="#svgicon_ServicePencil3_a"/></mask><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_ServicePencil3_a"/><g mask="url(#svgicon_ServicePencil3_b)" _fill="#FFF"><path pid="1" d="M0 0h25v25H0z"/></g></g>'
  }
})
