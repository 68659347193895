



























































import { Component, Mixins } from 'vue-property-decorator'
import { BeatIsFullyBoughtAndBeatIsLiked } from '@/mixins/beat-is-liked'
import ThePlayerLoader from '@/components/ThePlayerLoader.vue'
import { AbstractPlayer } from '~/mixins/abstract-player'
import { IS_MARKETPLACE } from '~/const/environment'

@Component({ components: { ThePlayerLoader } })
export default class ThePlayer extends Mixins(AbstractPlayer, BeatIsFullyBoughtAndBeatIsLiked) {
    get showLikeOptions() {
        return IS_MARKETPLACE
    }
}
