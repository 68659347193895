/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'play-full': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.995 11.065l-13.86-9.81a1.439 1.439 0 00-1.392-.135C4.288 1.319 4 1.734 4 2.19v19.62c0 .456.288.871.743 1.07a1.441 1.441 0 001.392-.134l13.86-9.81A1.15 1.15 0 0020.5 12c0-.365-.186-.71-.505-.935z" _fill="#FFF" fill-rule="nonzero"/>'
  }
})
