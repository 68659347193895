/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'youtube': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M18.43 4H5.57A4.57 4.57 0 001 8.57v6.429a4.57 4.57 0 004.57 4.569h12.86a4.57 4.57 0 004.57-4.57V8.57A4.57 4.57 0 0018.43 4zm-3.09 8.097l-6.015 2.869a.241.241 0 01-.346-.218V8.83c0-.18.19-.297.351-.215l6.016 3.048a.242.242 0 01-.005.434z" id="svgicon_youtube_a"/></defs><use _fill="#F61C0D" xlink:href="#svgicon_youtube_a"/>'
  }
})
