/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'download': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" id="svgicon_download_a" d="M22.63 15.452a1.184 1.184 0 00-.9-.397h-6.134l-1.798 1.927c-.511.528-1.11.793-1.798.793-.687 0-1.287-.264-1.798-.793l-1.785-1.927H2.27c-.352 0-.652.132-.899.397a1.36 1.36 0 00-.37.963v4.533c0 .378.123.699.37.963.247.265.547.397.9.397H21.73c.352 0 .652-.132.899-.397a1.36 1.36 0 00.37-.963v-4.533c0-.378-.123-.699-.37-.963zm-5.714 5.19a.71.71 0 01-.516.215.709.709 0 01-.516-.215.693.693 0 01-.217-.51c0-.196.072-.366.217-.51a.709.709 0 01.516-.215.71.71 0 01.516.215.693.693 0 01.217.51.694.694 0 01-.217.51zm4.4 0a.708.708 0 01-.516.215.709.709 0 01-.516-.215.693.693 0 01-.217-.51c0-.196.072-.366.217-.51a.708.708 0 01.516-.215c.198 0 .37.072.516.215a.693.693 0 01.217.51.693.693 0 01-.217.51zm-9.895-4.39L5.658 10.28c-.266-.258-.326-.57-.18-.934.145-.346.398-.52.758-.52H9.53V2.853c0-.23.081-.43.244-.6a.778.778 0 01.58-.253h3.293c.223 0 .416.084.579.253a.836.836 0 01.244.6v5.973h3.294c.36 0 .613.174.759.52.145.365.085.676-.18.933l-5.764 5.973a.752.752 0 01-.579.254.752.752 0 01-.579-.254z"/></defs><use xlink:href="#svgicon_download_a"/>'
  }
})
