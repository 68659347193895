import { get, post, update } from '~/services/http-requests'
import unset from 'lodash.unset'

const _BS_OWN_PROFILE = 'api/user'
const _BS_UPDATE_USER = 'api/update_user'

/*
 **** GET ****
 */
async function fetchOwnProfile(): Promise<IUser | null> {
    try {
        const [first] = await get({ baseProperty: _BS_OWN_PROFILE, isFreeRequest: true })

        if (!first)
            return null

        // map the object
        const user = { ...first, email: first.user.email, first_name: first.user.first_name }
        // remove the nested user object
        unset(user, 'user')
        return user
    } catch {
        return null
    }
}

/*
 **** POST ****
 */
function updateUserFullName(name: string) {
    const firstName = name.replace(/ /g, '+')
    return post({ fullUrl: `${_BS_UPDATE_USER}/set_full_name/${firstName}/`, isFreeRequest: true })
}

function updateUserEmail(email: string) {
    return post({ fullUrl: `${_BS_UPDATE_USER}/set_email/${email}/`, isFreeRequest: true })
}

function addFreeBeatsToAccount(): Promise<string> {
    return post({ fullUrl: `${_BS_UPDATE_USER}/add_free_beats_to_account/`, isFreeRequest: true })
}

function removeUserFromMailingList(): Promise<string> {
    return post({ fullUrl: `${_BS_UPDATE_USER}/unsubscribe_artist_from_mailinglist/`, isFreeRequest: true })
}

/*
 **** PATCH ****
 */
function updateUser(user: Partial<IUser>) {
    return update({ fullUrl: `${_BS_OWN_PROFILE}/${user.id}`, dispatchParams: user, isFreeRequest: true })
}

export {
    fetchOwnProfile,
    updateUser,
    updateUserFullName,
    updateUserEmail,
    addFreeBeatsToAccount,
    removeUserFromMailingList
}
