/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'profile-star': {
    width: 27,
    height: 27,
    viewBox: '0 0 27 27',
    data: '<path pid="0" d="M13.5 0C6.03 0 0 6.03 0 13.5S6.03 27 13.5 27 27 20.97 27 13.5 20.97 0 13.5 0zm8.82 10.71l-5.04 3.96 1.62 5.94c.09.18 0 .36-.18.45-.09.09-.18.09-.27.09-.09 0-.18 0-.27-.09l-4.68-2.97-4.68 2.97c-.18.09-.36.09-.54 0-.18-.09-.18-.27-.18-.45l1.44-5.94-4.86-3.96c-.18-.09-.18-.36-.18-.54 0-.18.27-.27.45-.27h6.03l2.16-5.13c.18-.36.72-.36.81 0l2.16 5.13h6.03c.18 0 .36.09.45.27-.09.18-.09.45-.27.54z" _fill="#E63C3C" fill-rule="evenodd"/>'
  }
})
