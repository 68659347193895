import { createFollowedPlaylist, fetchFollowedPlaylists, removeFollowedPlaylist } from '@/api/followed-playlists'
import {
    createFollowedProducer,
    fetchFollowedProducers,
    IFollowedProducerParams,
    removeFollowedProducer,
    updateFollowedProducer
} from '@/api/followed-producers'
import { createLikedBeat, fetchLikedBeats, removeLikedBeat } from '@/api/liked-beats'
import { fetchBoughtBeats } from '~/api/order'
import { namespace } from 'vuex-class'
import { fetchOwnProfile } from '~/api/user'
import { VuexActionData } from '~/interfaces/interface-utils'
import { ModalType } from '~/store/modal'
import { analyticsLike } from '~/services/analytics'

export const UserStore = namespace('user')

interface State {
    authenticatedUser: IUser
    likedBeats: ILikedBeat[]
    boughtBeats: IBoughtBeat[]
    followedProducers: IFollowedProducer[]
    followedPlaylists: IFollowedPlaylist[]
}

export default {
    namespaced: true,
    state: {
        authenticatedUser: null,
        likedBeats: [],
        boughtBeats: [],
        followedProducers: [],
        followedPlaylists: []
    } as State,
    getters: {
        userActiveDeals: (state: State, getters: any, rootState: any, rootGetters: any): string => {
            const cart: ICartItem[] = rootGetters['cart/cartItemsWithDeals'] ?? []
            const beatItems = cart.filter(it => it.selected_license_option)
            let message = null
            beatItems.forEach(item => {
                const license = item.selected_license_option
                const nOfAddedLicensesOfThisType = beatItems.filter(item2 => license.id === item2.selected_license_option.id).length
                const dealIsActive = nOfAddedLicensesOfThisType >= license.deal_buy + license.deal_get
                if (!dealIsActive) {
                    message = `Add <strong>${license.deal_buy + license.deal_get - nOfAddedLicensesOfThisType}
        					more <a href="${item.beat.producer.slug}" class="h-textUnderline">${item.beat.producer.display_name}</a></strong> beat with <strong>${
                        license.name
                    }</strong> license to activate the <strong>'Buy
        					${license.deal_buy} Get ${license.deal_get} Free'</strong> deal.`
                }
            })
            return message
        },
        likedBeatsMapped: (state: State): IBeat[] => state.likedBeats.map(item => item.beat)
    },
    mutations: {
        SET_AUTHENTICATED_USER: (state: State, payload: IUser) => (state.authenticatedUser = payload),
        // set after fetch
        SET_BOUGHT_BEATS: (state: State, payload: IBoughtBeat[]) => (state.boughtBeats = payload),
        SET_FOLLOWED_PRODUCERS: (state: State, payload: IFollowedProducer[]) => (state.followedProducers = payload),
        SET_LIKED_BEATS: (state: State, payload: ILikedBeat[]) => (state.likedBeats = payload),
        SET_FOLLOWED_PLAYLISTS: (state: State, payload: IFollowedPlaylist[]) => (state.followedPlaylists = payload),
        // updates after patch or delete
        UPDATE_FOLLOWED_PRODUCERS: (state: State, followedProducerPayload: IFollowedProducer) => {
            const followedProducerToUpdateIndex = state.followedProducers.findIndex(item => item.id === followedProducerPayload.id)
            if (followedProducerToUpdateIndex > -1) state.followedProducers.splice(followedProducerToUpdateIndex, 1)
            else state.followedProducers.push({ ...followedProducerPayload, should_receive_email: false })
        },
        UPDATE_SHOULD_RECEIVE_EMAIL: (state: State, followedProducerPayload: IFollowedProducer) => {
            const followedProducerToUpdateIndex = state.followedProducers.findIndex(item => item.id === followedProducerPayload.id)
            state.followedProducers[followedProducerToUpdateIndex].should_receive_email = followedProducerPayload.should_receive_email
        },
        UPDATE_FOLLOWED_PLAYLISTS: (state: State, followedPlaylistPayload: IFollowedPlaylist) => {
            const followedPlaylistToUpdateIndex = state.followedPlaylists.findIndex(item => item.id === followedPlaylistPayload.id)
            if (followedPlaylistToUpdateIndex > -1) state.followedPlaylists.splice(followedPlaylistToUpdateIndex, 1)
            else state.followedPlaylists.push(followedPlaylistPayload)
        },
        // id set to undefined since we don't have it, will be replace with real ID by the fetch and SET_LIKED_BEATS
        LIKE_BEAT: (state: State, beatPayload: IBeat) => state.likedBeats.push({ beat: beatPayload, id: undefined }),
        UNLIKE_BEAT: (state: State, likedBeatItemId: ILikedBeat['id']) => {
            const likedBeatToRemoveIndex = state.likedBeats.findIndex(item => item.id === likedBeatItemId)
            state.likedBeats.splice(likedBeatToRemoveIndex, 1)
        }
    },
    actions: {
        async fetchOwnProfile({ commit, dispatch }: VuexActionData<State>) {
            const user = await fetchOwnProfile()
            commit('SET_AUTHENTICATED_USER', user)
        },

        async fetchBoughtBeats({ commit }: VuexActionData<State>) {
            commit('SET_BOUGHT_BEATS', await fetchBoughtBeats())
        },
        async fetchLikedBeats({ commit }: VuexActionData<State>) {
            commit('SET_LIKED_BEATS', await fetchLikedBeats())
        },
        async fetchFollowedPlaylists({ commit }: VuexActionData<State>) {
            commit('SET_FOLLOWED_PLAYLISTS', await fetchFollowedPlaylists())
        },
        async fetchFollowedProducers({ commit }: VuexActionData<State>) {
            commit('SET_FOLLOWED_PRODUCERS', await fetchFollowedProducers())
        },

        async toggleLike({ dispatch, commit, state, rootGetters }: VuexActionData<State>, beat: IBeat) {
            if (!rootGetters['auth/isAuthenticated']) commit('modal/SET_ACTIVE_MODAL', { modal: ModalType.RequireAccountForLike }, { root: true })
            else {
                // if we have any likedBeats, try and find the liked beat
                const beatInLikedBeats = state.likedBeats.find((item: ILikedBeat) => item.beat.id === beat.id)

                if (beatInLikedBeats) {
                    commit('UNLIKE_BEAT', beatInLikedBeats.id)
                    removeLikedBeat(beatInLikedBeats.id)
                } else {
                    commit('LIKE_BEAT', beat)
                    await createLikedBeat(beat.id)
                    dispatch('fetchLikedBeats')
                    analyticsLike(beat)
                }
            }
        },

        async toggleFollowPlaylist({ commit, dispatch, state, rootGetters }: VuexActionData<State>, playlistId: IPlaylist['id']) {
            if (!rootGetters['auth/isAuthenticated']) dispatch('modal/showErrorUserNotLogged', null, { root: true })
            else {
                const playlistInFollowedPlaylists = state.followedPlaylists.find((item: IFollowedPlaylist) => item.playlist.id === playlistId)

                if (playlistInFollowedPlaylists) {
                    await removeFollowedPlaylist(playlistInFollowedPlaylists.id)
                    commit('UPDATE_FOLLOWED_PLAYLISTS', playlistInFollowedPlaylists)
                } else {
                    // otherwise we follow
                    const followedPlaylist = await createFollowedPlaylist(playlistId)
                    commit('UPDATE_FOLLOWED_PLAYLISTS', followedPlaylist)
                    dispatch(
                        'modal/showNotification',
                        { name: 'success', title: 'Following Playlist', content: 'Playlist added to your favourites!' },
                        { root: true }
                    )
                }
            }
        },

        async toggleFollowProducer({ state, commit, rootGetters }: VuexActionData<State>, { producerId, shouldReceiveEmail = false }: IFollowedProducerParams) {
            if (!rootGetters['auth/isAuthenticated']) commit('modal/SET_ACTIVE_MODAL', { modal: ModalType.RequireAccountForFollow }, { root: true })
            else {
                const producerInFollowedProducers = state.followedProducers.find((item: IFollowedProducer) => item.producer.id === producerId)
                if (producerInFollowedProducers) {
                    // if producer was follow already, we unfollow
                    removeFollowedProducer(producerInFollowedProducers.id)
                    commit('UPDATE_FOLLOWED_PRODUCERS', producerInFollowedProducers)
                } else {
                    // otherwise we follow and ask if the user wants to receive email notifications
                    const followedProducer = await createFollowedProducer({ producerId, shouldReceiveEmail })
                    commit('UPDATE_FOLLOWED_PRODUCERS', followedProducer)
                    // open modal for subscribing to notifications
                    commit(
                        'modal/SET_ACTIVE_MODAL',
                        {
                            modal: ModalType.MailingList,
                            payload: producerId
                        },
                        { root: true }
                    )
                }
            }
        },

        async toggleProducerMailingListSubscription(
            { state, commit, dispatch, rootState, rootGetters }: VuexActionData<State>,
            { producerId, shouldReceiveEmail }: IFollowedProducerParams
        ) {
            // if user isn't signed in, show "requre account" modal
            if (!rootGetters['auth/isAuthenticated']) commit('modal/SET_ACTIVE_MODAL', { modal: ModalType.RequireAccountForFollow }, { root: true })
            else {
                // otherwise proceed with...
                const producerInFollowedProducers = state.followedProducers.find((item: IFollowedProducer) => item.producer.id === producerId)
                if (producerInFollowedProducers) {
                    commit('UPDATE_SHOULD_RECEIVE_EMAIL', {
                        ...producerInFollowedProducers,
                        should_receive_email: shouldReceiveEmail
                    })
                    await updateFollowedProducer({
                        followedProducerId: producerInFollowedProducers.id,
                        shouldReceiveEmail
                    })
                    // show notification
                    if (shouldReceiveEmail) {
                        dispatch(
                            'modal/showNotification',
                            {
                                name: 'success',
                                title: 'Email notifications activated',
                                content: 'You will be notified when the producer uploads new beats.'
                            },
                            { root: true }
                        )
                    }
                    // open callback modal or close modals
                    const callbackModal = rootState.modal.activeModal.callbackModal
                    if (callbackModal)
                        commit(
                            'modal/SET_ACTIVE_MODAL',
                            {
                                modal: callbackModal.modal,
                                payload: callbackModal.payload
                            },
                            { root: true }
                        )
                    else commit('modal/SET_ACTIVE_MODAL', { modal: null }, { root: true })
                }
            }
        },

        resetEverythingOfUser({ commit }: VuexActionData<State>) {
            commit('SET_LIKED_BEATS', [])
            commit('SET_BOUGHT_BEATS', [])
            commit('SET_AUTHENTICATED_USER', [])
            commit('SET_FOLLOWED_PRODUCERS', [])
        }
    }
}
