/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'ServicePencil2': {
    width: 25,
    height: 25,
    viewBox: '0 0 25 25',
    data: '<defs><path pid="0" d="M14.755 19.114c-.937-.51-1.588-1.323-1.955-2.443L2.153 6.003c-.204-.224-.204-.57 0-.774l3.054-3.054c.223-.203.57-.203.773 0l.306.306 2.239-.468c.163-.041.366.02.489.142l5.028 5.008c.204.224.204.57 0 .774a.556.556 0 01-.387.163.556.556 0 01-.386-.163L8.464 3.132l-1.262.265 9.405 9.426c1.16.367 1.996 1.018 2.505 1.954.997 1.873.264 4.154.223 4.255-.04.163-.183.285-.346.347a6.98 6.98 0 01-1.913.264c-.713 0-1.548-.122-2.321-.53zm1.73-5.212l-2.606 2.586c.285.753.733 1.323 1.364 1.649.815.427 1.772.448 2.464.366l-1.385-1.384c-.203-.224-.224-.57 0-.774.224-.203.57-.203.774 0l1.405 1.405c.102-.692.102-1.65-.346-2.484-.346-.631-.916-1.079-1.67-1.364zm6.658 6.84c.305 0 .55.306.55.611 0 .306-.245.611-.55.611h-12.99c-.305 0-.549-.305-.549-.61 0-.306.244-.611.55-.611h12.989zm-3.258 2.036c.306 0 .55.306.55.611 0 .306-.244.611-.55.611h-6.494c-.306 0-.55-.305-.55-.61 0-.306.244-.612.55-.612h6.494z" id="svgicon_ServicePencil2_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon_ServicePencil2_b" _fill="#fff"><use xlink:href="#svgicon_ServicePencil2_a"/></mask><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_ServicePencil2_a"/><g mask="url(#svgicon_ServicePencil2_b)" _fill="#FFF"><path pid="1" d="M0 0h25v25H0z"/></g></g>'
  }
})
