import Vue from 'vue'

export const analyticsPageView = ({ page, title }: { page: string; title: string }) => {
    if (Vue.$ga) Vue.$ga.page({ page, title, location: window.location.href })
}

export const analyticsSignupStarted = () => {
    if (Vue.$ga) Vue.$ga.event({ eventCategory: 'Account', eventAction: 'Signup Started' })
    // @ts-ignore
    if (window.fbq) window.fbq('track', 'Lead')
}

export const analyticsSignupCompleted = () => {
    if (Vue.$ga) Vue.$ga.event({ eventCategory: 'Account', eventAction: 'Signup Completed' })
    // @ts-ignore
    if (window.fbq) window.fbq('track', 'CompleteRegistration')
}

export const analyticsSignupFull = () => {
    analyticsSignupStarted()
    analyticsSignupCompleted()
}

export const analyticsLike = (beat: IBeat) => {
    if (Vue.$ga) Vue.$ga.event({
        eventCategory: 'Beat',
        eventAction: 'Like'
        // eventLabel: `${beat.producer.display_name} - ${beat.name}`
    })
    // @ts-ignore
    if (window.fbq) window.fbq('track', 'AddToWishlist', {
        content_ids: [beat.id],
        content_name: `${beat.producer.display_name} - ${beat.name}`
    })
}

export const analyticsDemoDownload = (beat: IBeat) => {
    if (Vue.$ga) Vue.$ga.event({
        eventCategory: 'Beat',
        eventAction: 'Demo Download'
        // eventLabel: `${beat.producer.display_name} - ${beat.name}`
    })
    // @ts-ignore
    if (window.fbq) window.fbq('trackCustom', 'DemoDownload', {
        content_ids: [beat.id],
        content_name: `${beat.producer.display_name} - ${beat.name}`
    })
}

export const analyticsPlay = (beat: IBeat) => {
    if (Vue.$ga) Vue.$ga.event({
        eventCategory: 'Beat',
        eventAction: 'Play'
        // eventLabel: `${beat.producer.display_name} - ${beat.name}`
    })
    // @ts-ignore
    if (window.fbq) window.fbq('trackCustom', 'BeatPlay', {
        content_ids: [beat.id],
        content_name: `${beat.producer.display_name} - ${beat.name}`
    })
}

export const analyticsGetFreeBeats = () => {
    if (Vue.$ga) Vue.$ga.event({ eventCategory: 'Account', eventAction: 'Get Free Beats' })
    // @ts-ignore
    if (window.fbq) window.fbq('trackCustom', 'GetFreeBeats')
}

export const analyticsSearch = (searchString: string) => {
    // Vue.$ga.page({ page, title, location: window.location.href })
    // @ts-ignore
    if (window.fbq) window.fbq('track', 'Search', { search_string: searchString })
}

export const analyticsAddToCart = (item: ICartItem) => {
    if (item.beat && Vue.$ga) {
        Vue.$ga.event({
            eventCategory: 'Beat',
            eventAction: 'Add To Cart',
            eventValue: item.selected_license_option.price
            // eventLabel: 'label'
        })
    }
    // @ts-ignore
    if (window.fbq) {
        // @ts-ignore
        window.fbq('track', 'AddToCart', {
            currency: 'USD',
            value: item.selected_license_option.price,
            content_name: item.beat.name,
            content_ids: [item.id],
            content_type: 'product'
        })
    }
}

export const analyticsCheckoutInitiate = (cartTotalAmount: number) => {
    if (Vue.$ga) Vue.$ga.event({
        eventCategory: 'Checkout',
        eventAction: 'Initiate Checkout',
        eventValue: cartTotalAmount
    })
    // @ts-ignore
    if (window.fbq) window.fbq('track', 'InitiateCheckout', { currency: 'USD', value: cartTotalAmount })
}

export const analyticsCheckoutAddPaymentInfo = () => {
    if (Vue.$ga) Vue.$ga.event({ eventCategory: 'Checkout', eventAction: 'Add Payment Info' })
    // @ts-ignore
    if (window.fbq) window.fbq('track', 'AddPaymentInfo')
}

export const analyticsOrderCompleted = (order: IOrderDataSimple) => {
    // send google e-commerce data
    if (Vue.$ga) {
        order.bought_beats.forEach((itemBought: any) => {
            Vue.$ga.ecommerce.addItem({
                id: order.id, // Transaction ID. Required.
                name: itemBought.beat_name, // Product name. Required.
                sku: itemBought.sku, // SKU/code.
                category: itemBought.category, // Category or variation.
                price: itemBought.price, // Unit price.
                quantity: '1' // Quantity.
            })
        })

        Vue.$ga.ecommerce.addTransaction({
            id: order.id, // Transaction ID. Required.
            revenue: order.total_payed // Grand Total.
        })
        Vue.$ga.ecommerce.send()

        // google adwords
        // @ts-ignore
        gtag('event', 'conversion', {
            send_to: 'AW-869676803/LA-iCP6y3m4Qg-7YngM',
            value: order.total_payed,
            currency: 'USD',
            transaction_id: order.id
        })
    }

    // facebook Pixel
    // @ts-ignore
    if (window.fbq) {
        // @ts-ignore
        window.fbq('track', 'Purchase', {
            value: order.total_payed,
            currency: 'USD',
            num_items: order.bought_beats.length,
            contents: order.bought_beats.map((itemBought: any) => ({
                id: itemBought.sku,
                quantity: 1,
                item_price: itemBought.price
            })),
            content_type: 'product'
        })
    }
}
