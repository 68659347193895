/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'arrow-down': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" id="svgicon_arrow-down_a" d="M21.547 6.645a1.78 1.78 0 00-1.36-.644c-.442 0-.868.168-1.199.474l-7.033 6.504L5.02 6.484A1.76 1.76 0 003.813 6c-.517 0-1.01.233-1.355.64A1.96 1.96 0 002 7.904c0 .544.222 1.062.607 1.422l8.133 7.618a1.76 1.76 0 001.208.484 1.76 1.76 0 001.198-.476l8.238-7.617a1.93 1.93 0 00.612-1.309 1.957 1.957 0 00-.45-1.382z"/></defs><use xlink:href="#svgicon_arrow-down_a"/>'
  }
})
