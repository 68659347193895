import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component
export class Dropdowns extends Vue {
    menuDropDown = false

    toggleMenuDropdown(): void {
        this.menuDropDown = !this.menuDropDown
    }

    closeMenuDropdown(): void {
        this.menuDropDown = false
    }
}
