import { Vue, Component } from 'vue-property-decorator'

import { CartStore } from '~/store/cart'

@Component
export class BeatAlreadyInCart extends Vue {
    @CartStore.State('cart') cart: ICartItem[]

    get beatAlreadyInCart() {
        // @ts-ignore
        if (this.cart) return this.cart.some(item => item.beat?.id === this.beat.id)
    }
}
