export const youTubeGetVideoID = (url: string) => {
    const [a, , b] = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
    if (b !== undefined) {
        return b.split(/[^0-9a-z_-]/i)[0]
    } else {
        return a
    }
}

export const youTubeGetChannelFromUrl = (url: string) => {
    const pattern = new RegExp('^(https?://)?(www.)?youtube.com/(user/)?([a-z-_0-9]+)/?([?#]?.*)', 'i')
    const matches = url.match(pattern)
    if (matches) {
        return matches[4]
    }

    return url
}
