/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'profile': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" id="svgicon_profile_a" d="M23 12c0-6.065-4.935-11-11-11S1 5.935 1 12c0 3.204 1.378 6.091 3.57 8.103l-.01.01.357.3c.023.02.048.036.071.055.19.157.386.306.586.45a11.202 11.202 0 00.852.558l.148.086c.245.14.496.271.752.392l.057.026a10.901 10.901 0 003.613.972l.108.008c.295.024.594.04.896.04.3 0 .595-.016.889-.04.037-.002.074-.004.111-.008.294-.026.585-.065.872-.114l.074-.014a10.9 10.9 0 002.623-.822l.092-.042a11.056 11.056 0 001.532-.875c.074-.05.147-.104.22-.157.175-.126.348-.256.515-.393.038-.03.078-.056.114-.087l.366-.305-.01-.01A10.972 10.972 0 0023 12zM1.8 12C1.8 6.376 6.376 1.8 12 1.8S22.2 6.376 22.2 12c0 3.03-1.33 5.756-3.436 7.625a2.998 2.998 0 00-.357-.215l-3.387-1.693a.887.887 0 01-.492-.797v-1.183a8.152 8.152 0 001.293-2.376 1.45 1.45 0 00.834-1.315v-1.418c0-.347-.127-.684-.355-.948V7.813c.02-.208.094-1.379-.753-2.345C14.81 4.626 13.617 4.2 12 4.2c-1.616 0-2.81.426-3.547 1.267-.847.967-.774 2.139-.753 2.346V9.68c-.227.264-.355.6-.355.947v1.418c0 .44.198.851.536 1.129a7.516 7.516 0 001.237 2.555v1.158a.892.892 0 01-.464.783L5.49 19.395a3.005 3.005 0 00-.301.19A10.176 10.176 0 011.8 12z"/></defs><use xlink:href="#svgicon_profile_a"/>'
  }
})
