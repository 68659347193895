/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'next': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" id="svgicon_next_a" d="M22 23h-5V1h5v22zm-5-11.69v1.31L2 23V1l15 10.31z"/></defs><use xlink:href="#svgicon_next_a"/>'
  }
})
