import { get, remove, update, post } from '~/services/http-requests'

const _BS_CART_ITEMS = 'cart_items'

function fetchCartItems(): Promise<ICartItem[]> {
    return get({ baseProperty: _BS_CART_ITEMS })
}

function createCartItem(cartItem: ICartItem) {
    const dispatchParams = {
        beat_id: cartItem.beat?.id ?? null,
        selected_license_option_id: cartItem.selected_license_option?.id  ?? null,
        sound_kit_id: cartItem.sound_kit?.id ?? null
    }
    return post({ baseProperty: _BS_CART_ITEMS, dispatchParams })
}

function updateCartItemLicense(cartItemToUpdate: ICartItem) {
    return update({
        baseProperty: _BS_CART_ITEMS,
        fullUrl: `${_BS_CART_ITEMS}/${cartItemToUpdate.id}/`,
        dispatchParams: { selected_license_option_id: cartItemToUpdate.selected_license_option.id }
    })
}

function removeCartItem(cartItemId: ICartItem['id']) {
    return remove({ baseProperty: _BS_CART_ITEMS, fullUrl: `${_BS_CART_ITEMS}/${cartItemId}/` })
}

export { fetchCartItems, createCartItem, updateCartItemLicense, removeCartItem }
