import { createCartItem, fetchCartItems, removeCartItem, updateCartItemLicense } from '@/api/cart-items'
import cloneDeep from 'lodash.clonedeep'

import { namespace } from 'vuex-class'
import { VuexActionData } from '~/interfaces/interface-utils'
import { analyticsAddToCart } from '~/services/analytics'

export const CartStore = namespace('cart')

export enum PaymentType {
    PayPal,
    CreditCard
}

interface CreditCardInfo {
    cardholder_name: string
    last_4: string
    card_type: string
}

export enum CartStep {
    Review = 1,
    PaymentOptions = 2,
    PaymentForm = 3
}

interface CartState {
    cartStep: CartStep
    cart: ICartItem[]
    selectedPaymentType: PaymentType
    savedCreditCard: CreditCardInfo
    coupons: ICouponArtist[]
}

export default {
    namespaced: true,
    state: {
        cartStep: 1,
        cart: [],
        selectedPaymentType: null,
        savedCreditCard: null,
        coupons: [],
        orderResponseData: null
    } as CartState,
    getters: {
        cartItemsWithDeals: (state: CartState, getters: any, rootState: any): ICartItem[] => {
            if (state.cart.length === 0) return []
            const clonedCart: ICartItem[] = cloneDeep(state.cart)
            const beatCartItems = clonedCart.filter(it => it.selected_license_option)
            const soundKitCartItems = clonedCart.filter(it => it.sound_kit)
            const productCartItems = clonedCart.filter(it => it.product)

            // === update license prices ===
            beatCartItems.map(cartItem => {
                // if beat has a different price for exlusive licenses
                if (cartItem.selected_license_option.is_exclusive && cartItem.beat.price_for_exclusive)
                    cartItem.selected_license_option.price = cartItem.beat.price_for_exclusive

                // find if the beat was already bought before
                let alreadyBoughtBeat
                const boughtBeats: IBoughtBeat[] = rootState.user.boughtBeats
                if (boughtBeats) alreadyBoughtBeat = boughtBeats.find(item => item.beat.id === cartItem.beat.id)
                // if the beat was already bought
                if (alreadyBoughtBeat) {
                    // we substract the already paid amount
                    // Math.max because the amount can't be below zero (why even zero?)
                    cartItem.selected_license_option.price = Math.max(cartItem.selected_license_option.price - alreadyBoughtBeat.license.price, 0)
                }
                return cartItem
            })
            // coupons discount (WORKING ON)
            if (state.coupons) {
                beatCartItems.map(cartItem => {
                    // find if the license has a coupon the user attached
                    const validCoupon = state.coupons.find(item => item.licenses.includes(cartItem.selected_license_option.id))
                    // if the license has a valid coupon to be applied
                    if (validCoupon) {
                        // discount the license
                        // Math.max because the amount can't be below zero (why even zero?)
                        cartItem.selected_license_option.price = Math.max(
                            (cartItem.selected_license_option.price * (100 - validCoupon.discount_percentage)) / 100,
                            0
                        )
                    }
                    return cartItem
                })
            }
            // === sort by price and license value ===
            beatCartItems.sort((a, b) => {
                // if they have the same license value we compare license price
                if (a.selected_license_option.value === b.selected_license_option.value) {
                    return b.selected_license_option.price - a.selected_license_option.price
                }
                // else we compare license value
                return b.selected_license_option.value - a.selected_license_option.value
            })
            beatCartItems.map(cartItem => {
                // check for deals
                const currentLicense = cartItem.selected_license_option
                const dealBuy = currentLicense.deal_buy
                const dealGet = currentLicense.deal_get
                const beatsPerBundle = dealBuy + dealGet
                // if there are deals with that license
                if (dealBuy > 0) {
                    const beatsWithThatLicense = beatCartItems.filter(item => item.selected_license_option.id === currentLicense.id)
                    const beatsWithThatLicenseLength = beatsWithThatLicense.length
                    const beatsToBuyInBundle = Math.floor(beatsWithThatLicenseLength / beatsPerBundle) * dealBuy
                    const beatsToBuyOutsideBundle = beatsWithThatLicenseLength % beatsPerBundle
                    const beatsToBuy = beatsToBuyInBundle + beatsToBuyOutsideBundle
                    const indexInLicenseGroup = beatsWithThatLicense.findIndex(item => item.beat.id === cartItem.beat.id)
                    const isFree = indexInLicenseGroup + 1 > beatsToBuy
                    cartItem.selected_license_option.isFree = isFree
                } else {
                    // if no deals, no beat is free
                    cartItem.selected_license_option.isFree = false
                }
                return cartItem
            })

            return [...beatCartItems, ...soundKitCartItems, ...productCartItems]
        },
        beatCartItems: (state: CartState, getters: any): ICartItem[] => {
            return getters.cartItemsWithDeals.filter((it: ICartItem) => it.beat)
        },
        soundKitCartItems: (state: CartState, getters: any): ICartItem[] => {
            return getters.cartItemsWithDeals.filter((it: ICartItem) => it.sound_kit)
        },
        cartTotalAmount: (state: CartState, getters: any) => {
            if (!getters.cartItemsWithDeals) return 0
            // accumulate licenses price
            return getters.cartItemsWithDeals.reduce((accumulator: number, cartItem: ICartItem) => {
                if (cartItem.selected_license_option) {
                    // if it's free we add 0
                    const licensePrice = cartItem.selected_license_option.isFree ? 0 : cartItem.selected_license_option.price
                    return accumulator + licensePrice
                } else if (cartItem.sound_kit) {
                    return accumulator + cartItem.sound_kit.price
                } else if (cartItem.product) {
                    return accumulator + cartItem.product.price
                }
            }, 0)
        }
    },
    mutations: {
        SET_PAYMENT_METHOD: (state: CartState, payload: PaymentType) => (state.selectedPaymentType = payload),
        SET_SAVED_CREDIT_CARD: (state: CartState, payload: CreditCardInfo) => (state.savedCreditCard = payload),
        SET_CART_STEP: (state: CartState, payload: CartStep) => (state.cartStep = payload),
        // cart crud
        ADD_TO_CART: (state: CartState, payload: ICartItem): any => state.cart.push(payload),
        UPDATE_CART_ITEM_LICENSE: (state: CartState, cartItem: ICartItem) => {
            const indexOfElementToUpdate = state.cart.findIndex(item => item.beat.id === cartItem.beat.id)
            // change the license option obj to the one from the current selected cartItem
            state.cart[indexOfElementToUpdate].selected_license_option = cartItem.selected_license_option
        },
        REMOVE_ITEM_FROM_CART: (state: CartState, payload: ICartItem) => {
            let indexToRemove
            if (payload.beat) indexToRemove = state.cart.findIndex(item => item.beat && item.beat?.id === payload.beat?.id)
            else indexToRemove = state.cart.findIndex(item => item.sound_kit && item.sound_kit?.id === payload.sound_kit?.id)
            state.cart.splice(indexToRemove, 1)
        },
        SET_CART_ITEMS: (state: CartState, payload: ICartItem[]) => (state.cart = payload),
        RESET_CART_ITEMS: (state: CartState): any => (state.cart = []),
        // coupons
        ADD_COUPON: (state: CartState, payload: ICouponArtist): any => state.coupons.push(payload),
        RESET_COUPONS: (state: CartState): any => (state.coupons = null)
    },
    actions: {
        async fetchCartItems({ commit, rootGetters }: VuexActionData<CartState>) {
            if (rootGetters['auth/isAuthenticated']) commit('SET_CART_ITEMS', await fetchCartItems())
        },

        async addToCart({ commit, dispatch, state, rootGetters }: VuexActionData<CartState>, newCartItem: ICartItem) {
            // BUG, sometimes it's null, I don't know why.. so let's just set it to be an empty array.
            if (state.cart === null) commit('RESET_CART_ITEMS')

            const oldBeatCartItem = state.cart.find((item: ICartItem) => item.beat && item.beat.id === newCartItem.beat?.id)
            const oldSoundKit = state.cart.find(it => it.sound_kit && it.sound_kit.id === newCartItem.sound_kit?.id)
            const oldProduct = state.cart.find(it => it.product && it.product.id === newCartItem.product?.id)
            // if the beat is in the cart already, update license
            if (oldBeatCartItem) {
                dispatch('updateCartItemLicense', {
                    ...newCartItem,
                    id: oldBeatCartItem.id || undefined
                })
            } else if (!oldSoundKit || !oldProduct) {
                // else if its not a sound kit already added
                // we add to the cart and update the online cart items
                commit('ADD_TO_CART', newCartItem)
                // products are not synced
                if (rootGetters['auth/isAuthenticated'] && !newCartItem.product) createCartItem(newCartItem)
            }
            // send data to google analytics & facebook pixel
            analyticsAddToCart(newCartItem)
        },

        async updateCartItemLicense({ commit, dispatch, rootGetters }: VuexActionData<CartState>, newCartItem: ICartItem) {
            commit('UPDATE_CART_ITEM_LICENSE', newCartItem)
            if (rootGetters['auth/isAuthenticated']) {
                await updateCartItemLicense(newCartItem)
                dispatch('fetchCartItems')
            }
        },

        removeItemFromCart({ commit, rootGetters, state }: VuexActionData<CartState>, cartItem: ICartItem) {
            // remove the beat from the cart (which will also update caches)
            commit('REMOVE_ITEM_FROM_CART', cartItem)
            // if user IS authenticated also make delete request to remove item from DB
            if (rootGetters['auth/isAuthenticated'] && cartItem) removeCartItem(cartItem.id)
        },

        async createCartAfterLogin({ state, dispatch }: VuexActionData<CartState>) {
            if (state.cart.length === 0) return
            // for each item in the cached cart we make a POST request to the server
            await state.cart.forEach(cartItem => createCartItem(cartItem))
            // then we fetch them
            dispatch('fetchCartItems')
        }
    }
}
