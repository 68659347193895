/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'logo-icon': {
    width: 50,
    height: 46,
    viewBox: '0 0 50 46',
    data: '<g _fill="none" fill-rule="evenodd"><path pid="0" d="M37.68 27.184a12.67 12.67 0 01-5.988 5.26c-.427.182-3.756 1.193-3.756 1.193l-9.191 2.58 1.38-4.495c-3.838-2.24-6.422-6.403-6.422-11.158 0-3.424 1.343-6.658 3.783-9.105 2.446-2.455 5.688-3.806 9.128-3.806 4.37 0 8.425 2.225 10.706 5.841h.003c3.09-1.5 6.842-3.472 6.842-3.472a20.686 20.686 0 00-7.018-7.09A20.29 20.29 0 0026.614.003c-5.481 0-10.645 2.154-14.54 6.064-3.884 3.898-6.022 9.047-6.022 14.498a20.428 20.428 0 006.02 14.54l.015.014-3.331 10.847 11.568-3.247c1.873-.477 4.66-.964 7.074-1.39 1.022-.18 4.032-.726 5.22-1.08a19.934 19.934 0 004.787-2.153 20.719 20.719 0 007.104-7.42v-.002c-2.048-.94-5.698-2.903-6.828-3.489" _fill="#0269FF"/><path pid="1" d="M32.838 15.007a1.365 1.365 0 110 2.73H20.992a1.365 1.365 0 110-2.73h11.846zm-3.548 4.329a1.365 1.365 0 110 2.73h-8.298a1.365 1.365 0 110-2.73h8.298zm-3.548 4.279a1.365 1.365 0 110 2.73h-4.75a1.365 1.365 0 110-2.73h4.75z" _fill="#0F0"/></g>'
  }
})
