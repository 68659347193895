import { get } from '~/services/http-requests'

const _BS_PDF = 'pdf'

/*
 **** GET ****
 */

function fetchContractPdf(email: string, orderItemId: number): Promise<Blob> {
    return get({ fullUrl: `${_BS_PDF}/generate_contract_pdf/${email}/${orderItemId}/`, isFreeRequest: true })
}

function fetchLicenseSummary(producerSlug: IProducer['slug']): Promise<Blob> {
    return get({ fullUrl: `${_BS_PDF}/show_license_summary_pdf/${producerSlug}/`, isFreeRequest: true })
}

export { fetchContractPdf, fetchLicenseSummary }
