/* ============
 * axios
 * ============
 *
 * Promise based HTTP client for the browser and node.js.
 * Because Vue Resource has been retired, axios will now been used
 * to perform AJAX-requests.
 *
 * https://github.com/mzabriskie/axios
 */

import Vue from 'vue'
import axios from 'axios'
import { BASE_URL } from '~/const/base-url'

const axiosInstance = axios.create({
    headers: { Accept: 'application/json' },
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFToken',
    baseURL: BASE_URL
})

Vue.$axios = axiosInstance
Object.defineProperty(Vue.prototype, 'axios', { get: () => axiosInstance })
