import { Route } from 'vue-router'
import { Position } from 'vue-router/types/router'

/**
 * Forget scroll position on route change
 */
export const scrollBehavior = (to: Route, from: Route, savedPosition: void | Position) => {
    if (to.hash) {
        if (to.name === from.name) {
            // if the page is the same
            // and only the hash has changes
            window.scrollTo({
                // @ts-ignore
                // navbar in elitepage is 70px
                top: document.querySelector(to.hash).offsetTop + -70,
                behavior: 'smooth'
            })
        } else {
            // give some time to render
            setTimeout(() => {
                return window.scrollTo({
                    // @ts-ignore
                    // navbar in elitepage is 70px
                    top: document.querySelector(to.hash).offsetTop + -70,
                    behavior: 'smooth'
                })
            }, 800)
        }
    } else {
        return { x: 0, y: 0 }
    }
}
