/* ============
 * axios
 * ============
 *
 * Promise based HTTP client for the browser and node.js.
 * Because Vue Resource has been retired, axios will now been used
 * to perform AJAX-requests.
 *
 * https://github.com/mzabriskie/axios
 */

import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'
import { ENV_IS_PRODUCTION } from '~/const/environment'

if (ENV_IS_PRODUCTION)
    Sentry.init({
        // @ts-ignore
        Vue,
        dsn: 'https://c393c28313a940ad9d798306105b660f@o498496.ingest.sentry.io/5576092',
        autoSessionTracking: true,
        integrations: [new Integrations.BrowserTracing()],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0
    })
