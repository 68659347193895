import Vue from 'vue'
import Vuex from 'vuex'

import auth from '~/store/auth'
import base from '@elitepage/store/base'
import cart from '~/store/cart'
import elitepage from '@elitepage/store/elitepage'
import mailinglistpage from '@elitepage/store/mailing-list-page'
import salepage from '@elitepage/store/product-page'
import modal from '~/store/modal'
import player from '~/store/player'
import upload from '~/store/upload'
import user from '@elitepage/store/user'
import { vuexToken, vuexCart, vuexUser } from '~/store/vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [vuexToken, vuexCart, vuexUser],
    modules: { auth, base, cart, elitepage, mailinglistpage, salepage, modal, player, upload, user }
})
