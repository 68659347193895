/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'ServiceMusicFile': {
    width: 25,
    height: 25,
    viewBox: '0 0 25 25',
    data: '<defs><path pid="0" d="M13.733 19.233c0 .734.147 1.467.44 2.054.074.22-.073.513-.366.513H2.367c-.22 0-.367-.147-.367-.367V7.5v-.147c0-.073.073-.146.073-.146l5.134-5.134c.073 0 .073-.073.146-.073h10.414c.22 0 .366.147.366.367v11.146c0 .22-.146.294-.293.367-2.347.66-4.107 2.787-4.107 5.353zm-6.966-12.1c.22 0 .366-.146.366-.366v-3.52L3.247 7.133h3.52zm5.133 2.2c.22 0 .367-.146.367-.366S12.12 8.6 11.9 8.6h-1.467c-.22 0-.366.147-.366.367v4.693c-.294-.147-.66-.293-1.1-.293-1.027 0-1.834.66-1.834 1.466 0 .807.807 1.467 1.834 1.467 1.026 0 1.833-.66 1.833-1.467v-5.5h1.1zM21.067 20.7c0 .733-.147 1.1-1.467 1.1v-2.053c.953.22 1.467.513 1.467.953zm-1.834-6.233A4.757 4.757 0 0124 19.233 4.757 4.757 0 0119.233 24a4.757 4.757 0 01-4.766-4.767 4.757 4.757 0 014.766-4.766zM21.8 20.7c0-.807-.733-1.393-2.2-1.76v-2.273c1.467.073 1.467.733 1.467 1.1 0 .22.146.366.366.366s.367-.146.367-.366c0-1.467-1.173-1.834-2.2-1.834 0-.22-.147-.366-.367-.366s-.366.146-.366.366c-1.467.147-2.2.734-2.2 1.834 0 .953.806 1.613 2.2 1.833v2.2c-1.467-.147-1.467-.807-1.467-1.1 0-.22-.147-.367-.367-.367s-.366.147-.366.367c0 1.1.733 1.687 2.2 1.833v.074c0 .22.146.366.366.366s.367-.146.367-.366v-.074c1.32 0 2.2-.366 2.2-1.833zm-4.4-2.933c0-.294 0-.954 1.467-1.1v2.2c-.587-.147-1.467-.44-1.467-1.1z" id="svgicon_ServiceMusicFile_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon_ServiceMusicFile_b" _fill="#fff"><use xlink:href="#svgicon_ServiceMusicFile_a"/></mask><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_ServiceMusicFile_a"/><g mask="url(#svgicon_ServiceMusicFile_b)" _fill="#FFF"><path pid="1" d="M0 0h25v25H0z"/></g></g>'
  }
})
