/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'star': {
    width: 15,
    height: 15,
    viewBox: '0 0 15 15',
    data: '<path pid="0" d="M7.5 0A7.49 7.49 0 000 7.5C0 11.65 3.35 15 7.5 15S15 11.65 15 7.5 11.65 0 7.5 0zm4.9 5.95l-2.8 2.2.9 3.3c.05.1 0 .2-.1.25-.05.05-.1.05-.15.05-.05 0-.1 0-.15-.05l-2.6-1.65-2.6 1.65c-.1.05-.2.05-.3 0-.1-.05-.1-.15-.1-.25l.8-3.3-2.7-2.2c-.1-.05-.1-.2-.1-.3 0-.1.15-.15.25-.15H6.1l1.2-2.85c.1-.2.4-.2.45 0l1.2 2.85h3.35c.1 0 .2.05.25.15-.05.1-.05.25-.15.3z" _fill="#FFF" fill-rule="evenodd"/>'
  }
})
