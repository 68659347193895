







import { Component, Prop, Vue } from 'vue-property-decorator'
// @ts-ignore
import Verte from 'verte'
import 'verte/dist/verte.css'
import { IElitepagePatch, ElitepageStore } from '@elitepage/store/elitepage'
import { updateCssVariable } from '~/utils/updateCssVariable'
import { ColorPickerParentStateType } from '~/const/parent-state-type'
import { IMailingListPagePatch, MailingListPageStore } from '@elitepage/store/mailing-list-page'
import { IProductPagePatch, ProductPageStore } from '@elitepage/store/product-page'

@Component({ components: { Verte } })
export default class PColorPicker extends Vue {
    @Prop({ type: String }) colorKey!: keyof IElitepage | keyof IMailingListPage
    @Prop({ type: String }) displayText!: string
    @Prop({ default: ColorPickerParentStateType.Elitepage }) parentState: ColorPickerParentStateType

    @ElitepageStore.Mutation('UPDATE_ELITEPAGE') UPDATE_ELITEPAGE: (payload: IElitepagePatch) => void
    @MailingListPageStore.Mutation('UPDATE_MAILING_LIST_PAGE') UPDATE_MAILING_LIST_PAGE: (payload: IMailingListPagePatch) => void
    @ProductPageStore.Mutation('UPDATE_SALE_PAGE') UPDATE_SALE_PAGE: (payload: IProductPagePatch) => void

    get color() {
        if (this.parentState === ColorPickerParentStateType.Elitepage) return this.$store.state.elitepage.elitepage[this.colorKey] || '#000000'
        else if (this.parentState === ColorPickerParentStateType.MailingListPage)
            return this.$store.state.mailinglistpage.mailingListPage[this.colorKey] || '#000000'
        else if (this.parentState === ColorPickerParentStateType.ProductPage) return this.$store.state.salepage.productPage[this.colorKey] || '#000000'
    }

    set color(newValue: string) {
        updateCssVariable(this.colorKey, newValue)
        if (this.parentState === ColorPickerParentStateType.Elitepage)
            this.UPDATE_ELITEPAGE({
                key: this.colorKey as keyof IElitepage,
                value: newValue
            })
        else if (this.parentState === ColorPickerParentStateType.MailingListPage)
            this.UPDATE_MAILING_LIST_PAGE({
                key: this.colorKey as keyof IMailingListPage,
                value: newValue
            })
        else if (this.parentState === ColorPickerParentStateType.ProductPage)
            this.UPDATE_SALE_PAGE({
                key: this.colorKey as keyof IProductPage,
                value: newValue
            })
    }
}
